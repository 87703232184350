import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UploadFileRequestBody, UploadFileResponse } from '../../models/upload-file.model';
@Injectable({
  providedIn: 'root'
})
export class UploadFileService {

  constructor(private http: HttpClient){
   }
   uploadFile(body:FormData): Observable<HttpResponse<UploadFileResponse>>{
    return this.http.post<UploadFileResponse>(
      environment.baseUrl + `base/import-file/`,
      body,
      {  observe: 'response' }
    );
  }
  getFileStatus(id:number): Observable<HttpResponse<UploadFileResponse>>{
    return this.http.get<UploadFileResponse>(
      environment.baseUrl + `base/import-file/?id=${id}`,
      {  observe: 'response' }
    );
  }
  getActiveFile(fileType:string): Observable<HttpResponse<UploadFileResponse>>{
    return this.http.get<UploadFileResponse>(
      environment.baseUrl + `base/import-file/?type=${fileType}`,
      {  observe: 'response' }
    );

  }
  cancelFileProcessing(id:number): Observable<HttpResponse<UploadFileResponse>>{
    let body ={
      "new_status":"canceled"
    }
    return this.http.patch<UploadFileResponse>(
      environment.baseUrl + `base/import-file/${id}/`,
      body,
      {  observe: 'response' }
    );
  }

   
}
