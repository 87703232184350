import { State, Action, StateContext } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ProductsService } from 'src/app/shared/services/products/products.service';
import { patch, updateItem } from '@ngxs/store/operators';
import {
  Product,
  ProductResponse,
  Variant,
} from 'src/app/shared/models/product.model';
import {
  ClearProductsState,
  FavoriteProduct,
  GetFavoriteProducts,
  GetProducts,
  GetSellerLockedVariants,
  GetVariants,
  ProductRequest,
  UnfavoriteProduct,
} from './products.actions';
import { VariantResponse } from 'src/app/shared/models/product.model';
import { CoreService } from 'src/app/shared/services/core/core.service';
import { showToast } from 'src/app/core/services/snackbar/show-snackbar';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
export class ProductsStateModel {
  variants: Variant[];
  products: Product[];
  favoriteProducts: Variant[];
  sellerHasLockedVariants: boolean;
  lockedVariants: Variant[];
}

@State<ProductsStateModel>({
  name: 'Products',
  defaults: ProductState.defaultState,
})
@Injectable()
export class ProductState {
  constructor(
    private coreService: CoreService,
    private productService: ProductsService,
    private snackbar: MatSnackBar,
  ) {}

  static defaultState: ProductsStateModel = {
    variants: [],
    products: [],

    // A list of each product's default variant of type `Variant`
    favoriteProducts: [],
    sellerHasLockedVariants: false,
    lockedVariants: [],
  };

  @Action(GetVariants)
  getVariants(
    { patchState }: StateContext<ProductsStateModel>,
    { header, filterValues }: GetVariants
  ) {
    return this.productService.getProductsVariants(filterValues, header).pipe(
      tap((res) => {
        const requestBody = res.body as VariantResponse;

        if (res.status === 200) {
          patchState({
            variants: requestBody.variants,
            sellerHasLockedVariants: requestBody.seller_has_locked_variants,
          });
        }
      })
    );
  }

  @Action(GetProducts)
  getProducts(
    { patchState }: StateContext<ProductsStateModel>,
    { header, filterValues }: GetVariants
  ) {
    return this.productService.getProducts(filterValues, header).pipe(
      tap((res) => {
        const requestBody = res.body as ProductResponse;
        patchState({
          products: requestBody.products,
        });
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(ClearProductsState)
  clearProductsState({ setState }: StateContext<ProductsStateModel>) {
    setState(ProductState.defaultState);
  }

  @Action(GetFavoriteProducts)
  getFavoriteProducts(
    { patchState }: StateContext<ProductsStateModel>,
    { header, filterValues }: GetFavoriteProducts
  ) {
    return this.productService.getFavoriteVariants(header, filterValues).pipe(
      tap((res) => {
        const requestBody = res.body as VariantResponse;
        patchState({
          favoriteProducts: requestBody.variants
        })
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(FavoriteProduct)
  favoriteProduct(
    { setState }: StateContext<ProductsStateModel>,
    { defaultVariantId }: FavoriteProduct
  ) {
    return this.productService.favoriteProduct(defaultVariantId).pipe(
      tap((res) => {
        if(res.status == 200 || res.status < 202) {
          const variant: Variant = res.body;
          setState(
            patch({
              variants: updateItem(
                item => item.id == variant.id,
                variant
              ),
              favoriteProducts: updateItem(
                item => item.id == variant.id,
                variant
              )
            })
          )
        }
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        }
        throw err;
      })
    );
  }

  @Action(UnfavoriteProduct)
  unfavoriteProduct(
    { setState }: StateContext<ProductsStateModel>,
    { defaultVariantId }: UnfavoriteProduct
  ) {
    return this.productService.unfavoriteProduct(defaultVariantId).pipe(
      tap((res) => {
        if(res.status == 200 || res.status < 202) {
          const variant: Variant = res.body;
          setState(
            patch({
              variants: updateItem(
                item => item.id == variant.id,
                variant
              ),
              favoriteProducts: updateItem(
                item => item.id == variant.id,
                variant
              )
            })
          )
        }
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        }
        throw err;
      })
    );
  }

  @Action(ProductRequest)
  productRequset({},{ productRequest }: any){
    return this.productService.productRequest(productRequest).pipe(
      tap((res) => {
        if(res.status == 200 || res.status < 202) { 
          showToast(
            this.snackbar,
            "تم الاقتراح بنجاح",
            true,
            5000
          );
        }
      }),
      catchError((err) => {
        if (err.status === 500) {
          this.coreService.openAnyErrorMessageModal();
        }
        throw err;
      })
    );
  }

  @Action(GetSellerLockedVariants)
  getLockedVariants(
    { patchState }: StateContext<ProductsStateModel>,
    { header, filterValues }: GetSellerLockedVariants
  ) {
    return this.productService.getLockedProductsVariants(filterValues, header).pipe(
      tap((res) => {
        const requestBody = res.body as VariantResponse;

        if (res.status === 200) {
          patchState({
            lockedVariants: requestBody.variants,
            sellerHasLockedVariants: requestBody.seller_has_locked_variants,
          });
        }
      })
    );
  }


 
}

