<div class="flex flex-col justify-between">
  <div class="success-logo items-center flex justify-center">
    <mat-icon>check</mat-icon>
  </div>
  <h1 class="text-2xl text-black text-center mt-3">تمت بنجاح</h1>

  <p class="text-center">{{ successMessage }}</p>
</div>
<div class="flex review">
  <button mat-stroked-button (click)="closeModal()">إغلاق</button>
</div>
