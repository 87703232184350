import { AddressTemplateValidatorAbstract } from "../address-template-validator.abstract";
import { IAddressTemplateValidator } from "../interfaces/address-template-validator.interface";


export class UAEAddressTemplateValidator extends AddressTemplateValidatorAbstract implements IAddressTemplateValidator {
  addressCategory: string = "";
  addressLine: string = "";

  constructor() {
    super();
  }

  isValid(): boolean {
    let addressContent = this.getAddressContentInArr();

    if (this.addressCategory == "قرية") {
      if (addressContent.length < 2) {
        return false;
      } else {
        return true;
      }
    } else if (this.addressCategory == "مدينة") {
      if (addressContent.length < 4) {
        return false;
      } else {
        return true;
      }
    } else if (this.addressCategory == "كمبوند") {
      if (addressContent.length < 2) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
}
