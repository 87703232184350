<div class="onboarding-container">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
  <ngx-slick-carousel
    class="onboarding-banner grab"
    [config]="config"
    (afterChange)="onSlideChange($event)"
    #slickModal
  >
    <div class="slide1" *ngFor="let data of slidesData">
      <img *ngIf="data.imgPath" [src]="data.imgPath" alt="on-boarding-img" />
      
      <div class="category-tabs-component mb-6" *ngIf="categories?.length > 0">
        <div class="category-tabs">
          <mat-tab-group
            class="remove-border-bottom mr-2"
            mat-align-tabs="center"
            (selectedIndexChange)="categoryTabAction($event)"
            [selectedIndex]="categoryTabSelectedIndex"
          >
            <mat-tab label="كل المنتجات"></mat-tab>
            <mat-tab *ngFor="let category of categories" [label]="category?.name"></mat-tab>
          </mat-tab-group>
        </div>
  
        <div class="category-dropdown">
          <mat-select
            name="categories"
            [(value)]="categoryTabSelectedIndex"
            (selectionChange)="categoryDropdownAction($event.value)"
            class="bg-white rounded-lg p-2 border-2 border-slate-400"
          >
            <mat-option [value]="0" class="font-semibold">كل المنتجات</mat-option>
            <mat-option *ngFor="let category of categories; let i = index" [value]="i + 1" class="font-semibold">{{ category?.name }}</mat-option>
          </mat-select>
        </div>
      </div>
      
      <div class="text">هتلاقى أشهر و أكتر المنتجات المطلوبة</div>
    </div>
  
    <div class="slide2" *ngFor="let data of slidesData">
      <img *ngIf="isDesktop; else mobileGif" [src]="data.imgPath" alt="on-boarding-img" />
      <ng-template #mobileGif>
        <img src="../../assets/images/on-boarding-img-2-mobile.gif" alt="on-boarding-img-2-mobile" />
      </ng-template>
      <div class="text">انشاء الطلابات بسهولة</div>
    </div>
  
    <div class="slide3" *ngFor="let data of slidesData">
      <img src="../../assets/images/on-boarding-img-3.svg" alt="on-boarding-img-3"/>
      <div class="text">
        <div class="mx-auto">
          <span>
            <a target="_blank" href="https://www.facebook.com/groups/alkasebacommunity/?ref=share" (click)="onSocialMediaIconClick('Facebook')">
              <mat-icon class="mx-2" svgIcon="facebook-logo"></mat-icon>
            </a>
          </span>
          <span>
            <a target="_blank" href="https://t.me/alkaseba" (click)="onSocialMediaIconClick('Telegram')">
              <mat-icon svgIcon="telegram" class="m-auto"></mat-icon>
            </a>
          </span>
        </div>
        <span>اتعلم وسط مجتمع كبير من الكسيبة</span>
      </div>
    </div>
  
  </ngx-slick-carousel>
  
  <div class="buttons-container">
    <button mat-flat-button class="primary-application-color previous-buttton-class button-style" (click)="onPreviousButtonClick()" *ngIf="activeSlideIndex !== 1">السابق</button>
    <button mat-flat-button class="primary-application-background-color secondary-application-color next-buttton-class button-style" (click)="onNextButtonClick()" *ngIf="activeSlideIndex !== numberOfSlides">التالي</button>
  </div>
</div>
