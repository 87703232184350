import { State, Action, StateContext, Selector } from '@ngxs/store';
import { catchError, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  ClearsOrdersState,
  GetOrderDetailedData,
  GetOrders,
  GetOrdersStats,
} from './orders.actions';
import {
  GetOrdersResponse,
  Order,
  OrdersStats,
} from 'src/app/shared/models/orders.model';
import { OrdersService } from 'src/app/shared/services/orders/orders.service';
import { CoreService } from 'src/app/shared/services/core/core.service';
import { Meta } from 'src/app/shared/service-helpers/filter-helper';
export class OrdersStateModel {
  orders: Order[];
  meta: Meta;
  stats: OrdersStats;
  detailedOrder: Order;
}

@State<OrdersStateModel>({
  name: 'Orders',
  defaults: OrderState.defaultState,
})
@Injectable()
export class OrderState {
  constructor(
    private ordersService: OrdersService,
    private coreService: CoreService
  ) {}

  static defaultState: OrdersStateModel = {
    orders: [],
    stats: {
      rto: 0,
      delivered: 0,
      undelivered: 0,
      total_requested: 0,
      canceled: 0,
    },
    detailedOrder: null,
    meta: {
      total_results: 0,
      total_pages: 0,
      page: 0,
      per_page: 0,
    },
  };

  @Action(GetOrders)
  getProducts(
    { patchState }: StateContext<OrdersStateModel>,
    { header, filterValues }: GetOrders
  ) {
    return this.ordersService.getOrders(filterValues, header).pipe(
      tap((res) => {
        const requestBody = res.body as GetOrdersResponse;

        if (res.status === 200) {
          patchState({
            orders: requestBody.orders,
            meta: requestBody.meta,
          });
        }
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(GetOrdersStats)
  getOrdersStats({ patchState }: StateContext<OrdersStateModel>) {
    return this.ordersService.getOrdersStats().pipe(
      tap((res) => {
        patchState({
          stats: res.body,
        });
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(ClearsOrdersState)
  clearOrdersState({ setState }: StateContext<OrdersStateModel>) {
    setState(OrderState.defaultState);
  }
  @Action(GetOrderDetailedData)
  getDetailedOrder(
    { patchState }: StateContext<OrdersStateModel>,
    { orderId }: GetOrderDetailedData
  ) {
    return this.ordersService.getOrderDetailedData(orderId).pipe(
      tap((response) => {
        const requestBody = response.body;
        patchState({
          detailedOrder: requestBody,
        });
      })
    );
  }

  @Selector()
  public static getTotalOrders(state: OrdersStateModel) {
    return state.stats.total_requested;
  }
}
