<div class="flex items-center">
  <button
    [ngClass]="
      enableLargeButton
        ? 'large-increment-button-style'
        : 'increment-button-style'
    "
    type="button"
    (click)="onIncrementClick()"
  >
    <mat-icon svgIcon="add" class="m-auto"></mat-icon>
  </button>

  <div
    [ngClass]="enableLargeButton ? 'text-3xl' : 'text-xl'"
    class="#F2F2F7 font-bold quantity-width"
  >
    {{ this.CartQuantity }}
  </div>

  <button
    [ngClass]="
      enableLargeButton
        ? 'large-increment-button-style'
        : 'increment-button-style'
    "
    type="button"
    (click)="onDecrementClick()"
  >
    <mat-icon svgIcon="remove" class="m-auto"></mat-icon>
  </button>
</div>
