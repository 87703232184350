import { Component, Input, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription } from 'rxjs';
import { ConstantsFactory, MarketplaceConstants } from 'src/app/common/global-constants';
import { showToast } from 'src/app/core/services/snackbar/show-snackbar';
import { MixpanelService } from 'src/app/mixpanel.service';
import { CartItemModel } from 'src/app/shared/models/cart.model';
import { MarketplaceModel } from 'src/app/shared/models/marketplace.models';
import { Variant } from 'src/app/shared/models/product.model';
import { CoreService } from 'src/app/shared/services/core/core.service';
import { AddCartItem, UpdateCartItem } from 'src/app/store/cart/cart.actions';
import {
  FavoriteProduct,
  UnfavoriteProduct,
} from 'src/app/store/products/products.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-card[variant]',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
})
export class ProductCardComponent implements OnInit {
  @Input() variant: Variant;
  @Input() eventTrackingName : string;


  icons = ['filled-heart', 'empty-heart'];
  loading: boolean;
  width: number = window.innerWidth;
  toggleFavoriteProductCallsSubject: Subject<Variant> = new Subject();
  quantityDict = new Map<string, any>();
  cartQuantity :number = 1;
  cartVariantIds: string[] = [];
  variantIDToCartItem = new Map<string, CartItemModel>();

  @Select((state: any) => state.Cart.cartItems)
  cartObservable: Observable<CartItemModel[]>;

  marketplaceConstants: MarketplaceConstants;
  @Select((state: any) => state.MarketplaceState.selectedMarketplace)
  selectedMarketplaceObservable: Observable<MarketplaceModel>;

  @Select((state: any) => state.Products.variants)
  variantsObservable: Observable<Variant[]>;

  subscriptionArray: Subscription[] = [];

  constructor(
    private store: Store,
    private coreService: CoreService,
    private mixpanelService: MixpanelService,
    private snackbar: MatSnackBar,
    private router: Router,
    private sanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry
  ) {}
  ngOnChanges(): void {
    if(this.variant){
      this.subscriptionArray.push(
        this.selectedMarketplaceObservable.subscribe((marketplace) =>
          marketplace.id !== ''
            ? (this.marketplaceConstants = ConstantsFactory.createConstants(
                marketplace.id.toString()
              ))
            : (this.marketplaceConstants = null)
        )
      );
      this.subscriptionArray.push(
        this.cartObservable.subscribe((cartItems) => {
          this.cartVariantIds = cartItems.map((item) => item.variant.id);
          this.variantIDToCartItem = new Map<string, CartItemModel>();
          cartItems.forEach((item) => {
            this.variantIDToCartItem.set(item.variant.id.toString(), item);
          });
        })
      );
      this.subscriptionArray.push(
        this.variantsObservable.subscribe((variants) => {
          let changedVariant = variants.find(
            (variant) => variant.id === this.variant.id
          );
          if(changedVariant)
          {
            this.variant = changedVariant;
          }
        })
      );
    }

  }
  ngOnInit(): void {
    this.toggleFavoriteProductCallsSubject.pipe().subscribe((variant) => {
      this.toggleVariantFavorance(variant);
    });
    this.subscribeUserInfo()
    this.sanitizeIcons();
  }

  @Select((state: any) => state.UserInfo)
  userInfoObservable: Observable<any>;
  customizeAlkaseba = false;

  subscribeUserInfo() {
    this.userInfoObservable.subscribe((userInfo) => {
      if (environment.customizedAlkasebaPhoneNumber.includes(userInfo.phone_number))
      {
        this.customizeAlkaseba = true;
      }

    })
  }


  sanitizeIcons() {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `../../../assets/icons/${icon}.svg`
        )
      );
    }
  }

  getQueryParams(variant: Variant) {
    let queryParams: any = {};

    if (variant.attribute1_value) {
      queryParams['att1'] = variant.attribute1_value;
    }
    if (variant.attribute2_value) {
      queryParams['att2'] = variant.attribute2_value;
    }
    if (variant.attribute3_value) {
      queryParams['att3'] = variant.attribute3_value;
    }

    return queryParams;
  }

  onClickProduct(variant: Variant) {
    this.mixpanelService.track(this.eventTrackingName || 'HP-Click Product', {
      'Product Title': variant.product.name,
      'SKU Code': variant.sku_code,
      Engagement: true,
    });
  }

  favProduct(defaultVariantId: string) {
    this.mixpanelService.track('Favorite - Click', {
      'SKU Code': this.variant.sku_code,
      Engagement: true,
    });
    return this.store.dispatch(new FavoriteProduct(defaultVariantId));

  }

  unFavProduct(defaultVariantId: string) {
    this.mixpanelService.track('UnFavorite - Click', {
      'SKU Code': this.variant.sku_code,
      Engagement: true,
    });
    return this.store.dispatch(new UnfavoriteProduct(defaultVariantId));
  }

  toggleVariantFavorance(variant: Variant) {
    if (variant.is_favorited) {
      this.unFavProduct(variant.id).subscribe();
    } else {
      this.favProduct(variant.id).subscribe();
    }
  }

  onFavIconClick($event: any, defaultVariant: Variant) {
    $event.preventDefault();
    $event.stopPropagation();
    this.toggleFavoriteProductCallsSubject.next(defaultVariant);
  }

  getVariantImageThumbnailUrl(url: string) {
    let compressed_url = url.replace(
      '/variants_images/',
      '/variants_images/thumbnails/'
    );
    return compressed_url;
  }

  quantityChangeListener($event: any) {
    this.cartQuantity= $event;
  }

  addToCart(variantId: any) {
    this.mixpanelService.track(this.eventTrackingName || 'HP-Click Add to Cart', {
      Engagement: true,
      variantId: variantId,
      "SKU Code": this.variant.sku_code
    });
    const quantity = this.cartQuantity;
    this.loading = true;
    this.store
      .dispatch(new AddCartItem(variantId.toString(), quantity))
      .subscribe(
        () => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.coreService.openAnyErrorMessageModal(
            error.status,
            error.error.detail
          );
        }
      );
  }

  updateCartIem(cartItemID: any, body: any) {
    this.store.dispatch(new UpdateCartItem(cartItemID, body)).subscribe(
      (_) => {
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        showToast(
          this.snackbar,
          'حدث خطأ لم نتمكن من حفظ تعديلاتك',
          false,
          2000
        );
      }
    );
  }

  checkout(variantId: any) {
    this.mixpanelService.track(this.eventTrackingName || 'HP-Click Checkout Now', {
      'SKU Code': this.variant.sku_code,
      variantId: variantId,
    });
    if (this.cartVariantIds.includes(variantId)) {
      const variantID = variantId.toString();
      const quantity = this.cartQuantity;
      let CartItemID = this.variantIDToCartItem.get(variantID).id;
      const body = {
        quantity: quantity,
      };
      if (quantity != this.variantIDToCartItem.get(variantID).quantity) {
        this.updateCartIem(CartItemID, body);
      }
    } else {
      this.addToCart(variantId);
    }
    this.router.navigate(['/app', 'cart']);
  }
}
