<div class="help-container">
  <div class="header">
    <button mat-icon-button class="close-button" (click)="closeModal()">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
    <div class="title">ازاي تبيع علي الكسيبة</div>
  </div>
  <div class="card-content">
    <div class="video">
      <app-loading [loading]="loading" id = "loader"></app-loading>
      <video (click)="play()" id="video"  playsinline autoplay [muted]="'muted'" loop>
        <source src="https://storage.googleapis.com/cosmic-tenure-290110-media/seller_journey.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <mat-icon (click)="play()" id ="play-icon" svgIcon="play" class="mx-2"></mat-icon>
    </div>
    <div class="steps">
      <div class="step1" id="step1" (click)="clickOnstep1()">
        <div class="step-number">
          <span>خطوة 1</span>
        </div>
        <div class="step-text">
          <span>في الكسيبة عندنا تشكيلة منتجات كتير هتناسب كل عملائك...<b>إختار وطير</b></span>
        </div>
      </div>
      <div class="step2" id="step2" (click)="clickOnstep2()">
        <div class="step-number">
          <span>خطوة 2</span>
        </div>
        <div class="step-text">
          <span>اعمل اعلانات للمنتجات على السوشيال ميديا... <b>سوَق لعملائك وجمَع أورداراتك</b></span>
        </div>
      </div>
      <div class="step3" id="step3" (click)="clickOnstep3()">
        <div class="step-number">
          <span>خطوة 3</span>
        </div>
        <div class="step-text">
          <span>ارجع تاني للكسيبة عشان تطلب المنتجات لعملائك... <b>خلصت رحلتك جمَع مكسبك</b></span>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom">
    <button class="end" (click)="endModal()">انهاء</button>
    <button class="show-later" (click)="closeModal()">المشاهده لاحقا</button>
  </div>
</div>