import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SuccessSnackbarComponent } from 'src/app/common-components-module/success-snackbar/success-snackbar.component';

// Shows toast message
export function showToast(
  snackbar: MatSnackBar,
  data: string,
  success = true,
  duration = 2000
): void {
  snackbar.openFromComponent(SuccessSnackbarComponent, {
    duration,
    data,
    panelClass: [`${success ? 'success' : 'fail'}-snackbar`],
  });
}
