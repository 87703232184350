export enum FileActionTypes {
    GetActiveFile = '[File] Get Active File',
    CancelFileProcessing = '[File] Cancel File Processing',
    UploadFile = '[File] Uplading File',
    GetFileState = '[File] Get File State',
    DeleteOrderFileState = '[File] Delete Order State'

}

export class GetActiveFile {
    public static readonly type = FileActionTypes.GetActiveFile;
    constructor(public fileType: string) { }
}

export class CancelFileProcessing {
    public static readonly type = FileActionTypes.CancelFileProcessing
    constructor(public id: number, public fileType: string) { }
}

export class GetFileState {
    public static readonly type = FileActionTypes.GetFileState
    constructor(public id: number, public fileType: string) { }

}
export class UploadFile {
    public static readonly type = FileActionTypes.UploadFile
    constructor(public formData: FormData) { }
}

export class DeleteOrderFileState {
    public static readonly type = FileActionTypes.DeleteOrderFileState
    constructor() { }
}