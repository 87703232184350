import {
  CreatePaymentRequestHttpRequest,
} from 'src/app/shared/models/billing.model';
import {
  FilterValue,
  ListHeader,
} from 'src/app/shared/service-helpers/filter-helper';

export enum BillingActionTypes {
  GetPaymentRequests = '[Billing] Get Payment Requests',
  CreatePaymentRequest = '[Billing] Create Payment Request',
  DeletePaymentRequest = '[Billing] Delete Payment Request',
  GetVouchers = '[Billing] Get Vouchers',
  GetDeductions = '[Billing] Get Deductions',
  GetBillingStats = '[Billing] Get Billing Stats',
  ClearState = '[Billing] Clear State',
  GetConversionRate = '[Billing] Get Conversion Rate'
}

export namespace BillingActions {
  export class GetAllPayments {
    static readonly type = BillingActionTypes.GetPaymentRequests;
    constructor(
      public header: ListHeader,
      public filterValues: FilterValue[]
    ) {}
  }

  export class CreatePaymentRequest {
    static readonly type = BillingActionTypes.CreatePaymentRequest;
    constructor(public paymentRequest: CreatePaymentRequestHttpRequest) {}
  }

  export class GetBillingStats {
    static readonly type = BillingActionTypes.GetBillingStats;
    constructor() {}
  }

  export class DeletePaymentRequest {
    static readonly type = BillingActionTypes.DeletePaymentRequest;
    constructor(public id: string | number) {}
  }

  export class GetVouchers {
    static readonly type = BillingActionTypes.GetVouchers;
    constructor(
      public header: ListHeader,
      public filterValues: FilterValue[]
    ) {}
  }

  export class GetDeductions {
    static readonly type = BillingActionTypes.GetDeductions;
    constructor(
      public header: ListHeader,
      public filterValues: FilterValue[]
    ) {}
  }
  export class ClearBillingState {
    static readonly type = BillingActionTypes.ClearState;
  }

  export class GetConversionRate {
    static readonly type = BillingActionTypes.GetConversionRate;
    constructor(public fromCurrency: string, public toCurrency?: string) {}
  }
}
