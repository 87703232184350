import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  Category,
  ProductRequestModel,
} from 'src/app/shared/models/product.model';
import { ProductsService } from 'src/app/shared/services/products/products.service';
import { Store } from '@ngxs/store';
import { ProductRequest } from 'src/app/store/products/products.actions';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MixpanelService } from 'src/app/mixpanel.service';

@Component({
  selector: 'app-product-request-dialog',
  templateUrl: './product-request-dialog.component.html',
  styleUrls: ['./product-request-dialog.component.scss'],
})
export class ProductRequestDialogComponent implements OnInit {
  categories: Category[] = [];
  productImageSrc: string;
  productRequestForm: UntypedFormGroup;
  categoryFieldMixpanel: boolean = false;
  productNameMixpanel: boolean = false;
  linkMixpanel: boolean = false;
  expectedVolumeMixpanel: boolean = false;
  notesMixpanel: boolean = false;
  requestProductButtonClicked: boolean = false;
  loading: boolean = true;

  productRequestData: ProductRequestModel = {
    name: '',
    link: '',
    image: null,
    notes: null,
    price: 0,
    expected_volume: 0,
    category_id: 0,
  };
  constructor(
    private store: Store,
    private dialogRef: MatDialogRef<ProductRequestDialogComponent>,
    private productsService: ProductsService,
    private mixpanelService: MixpanelService
  ) {}

  ngOnInit(): void {
    this.getCategories();
    this.productRequestForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      category_id: new UntypedFormControl(null, Validators.required),
      link: new UntypedFormControl(null, Validators.required),
      expectedVolume: new UntypedFormControl(null, Validators.required),
      notes: new UntypedFormControl(null),
      image: new UntypedFormControl(null),
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getCategories() {
    this.productsService.getCategories().subscribe((res) => {
      this.categories = res.body.categories;
    });
  }

  filesHandler(e: Event): void {
    let file = (e.target as HTMLInputElement).files[0];
    if (!file) return;
    if (file.size > 20 * 1024 * 1024) {
      alert('Category Image is too big!');
    }
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.productRequestForm
        .get('image')
        .setValue(reader.result, { emitModelToViewChange: true });
      this.productRequestData.image =
        this.productRequestForm.get('image').value;
      this.productImageSrc = this.productRequestForm.get('image').value;
    };
  }

  requestProduct() {
    this.mixpanelService.track('Product Request- Submit', {
      Engagement: true,
    });
    this.productRequestData.expected_volume =
      this.productRequestForm.get('expectedVolume').value;
    this.productRequestData.name = this.productRequestForm.get('name').value;
    this.productRequestData.category_id =
      this.productRequestForm.get('category_id').value;
    this.productRequestData.link = this.productRequestForm.get('link').value;
    this.productRequestData.notes = this.productRequestForm.get('notes').value;

    if (this.productRequestForm.valid) {
      this.requestProductButtonClicked = true;
      this.store
        .dispatch(new ProductRequest(this.productRequestData))
        .subscribe(() => {
          this.closeDialog();
          this.requestProductButtonClicked = false;
        });
    }
    this.productRequestForm.markAllAsTouched();
  }

  categoryFieldChanged() {
    if (
      !this.categoryFieldMixpanel &&
      this.productRequestForm.get('category_id').value
    ) {
      this.mixpanelService.track('Product Request - “Category”', {
        Engagement: true,
      });
      this.categoryFieldMixpanel = true;
    }
  }

  productNameChanged() {
    if (
      !this.productNameMixpanel &&
      this.productRequestForm.get('name').value
    ) {
      this.mixpanelService.track('Product Request - “Name”', {
        Engagement: true,
      });
      this.productNameMixpanel = true;
    }
  }

  linkChanged() {
    if (!this.linkMixpanel && this.productRequestForm.get('link').value) {
      this.mixpanelService.track('Product Request - “Link”', {
        Engagement: true,
      });
      this.linkMixpanel = true;
    }
  }

  expectedVolumeChanged() {
    if (
      !this.expectedVolumeMixpanel &&
      this.productRequestForm.get('expectedVolume').value
    ) {
      this.mixpanelService.track('Product Request - “Expected Volume”', {
        Engagement: true,
      });
      this.expectedVolumeMixpanel = true;
    }
  }

  notesChanged() {
    if (!this.notesMixpanel && this.productRequestForm.get('notes').value) {
      this.mixpanelService.track('Product Request - “Notes”', {
        Engagement: true,
      });
      this.notesMixpanel = true;
    }
  }
}
