import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.scss'],
})
export class SuccessMessageComponent implements OnInit {
  successMessage: string;
  constructor(
    private dialogRef: MatDialogRef<SuccessMessageComponent>,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public key: any
  ) {}

  ngOnInit(): void {
    this.successMessage = this.key.successMessage;
  }

  closeModal() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  closureListenter() {
    this.dialogRef.afterClosed().subscribe((_) => {
      this.dialogRef = null;
    });
  }
}
