import { IAddressTemplateValidator } from "../shared/validators/address-template-validator/interfaces/address-template-validator.interface"
import { EgyptAddressTemplateValidator } from "../shared/validators/address-template-validator/marketplace-address-template-validator/egypt-address-template-validator"
import { SaudiAddressTemplateValidator } from "../shared/validators/address-template-validator/marketplace-address-template-validator/saudi-address-template-validator"
import { UAEAddressTemplateValidator } from "../shared/validators/address-template-validator/marketplace-address-template-validator/uae-adress-template-validator";

export interface MarketplaceConstants {
  currency: string;
  currencyInArabic: string;
  countryName: string;
  countryNameInArabic: string;
  phoneRegex: string;
  deliveryTime: Map<string, string>;
  deliveryFees: string[];
  addressCategories: string[];
  addressCategoriesHint: { [index: string]: any };
  addressTemplateValidator: IAddressTemplateValidator;
  timeOffset: string;
}
export class EgyptConstants implements MarketplaceConstants {
  public currency: string = 'EGP';
  public currencyInArabic: string = 'جنيه';
  public countryName: string = 'Egypt';
  public countryNameInArabic: string = 'مصر';
  public phoneRegex: string = '^(\\+2|002)?01[0125][0-9]{8}$';
  public timeOffset: string = "+02";
  public deliveryTime = new Map<string, string>([
    ['القاهرة و الجيزة', '24 ساعة (يوم واحد)'],
    ['الاسكندرية', '36 ساعة (1-2 يوم)'],
    ['الدلتا', '48 ساعة (2 أيام)'],
    ['صعيد مصر', '72 ساعة (3-4 أيام)'],
    ['سيناء، خط الساحل و مطروح', '(4 أيام)'],
    ['الواحات، الأقصر و أسوان', '(5 أيام)'],
  ]);

  public deliveryFees = [
    'محافظات القاهرة و الجيزة و الاسكندرية 30 جنيه',
    'محافظات الدلتا 35 جنيه',
    'محافظات الصعيد و سيناء 40 جنيه'
  ];

  addressCategories: string[] = [
    "كمبوند",
    "مدينة",
    "قرية"
  ];
  addressCategoriesHint: { [index: string]: any } = {
    "كمبوند": "( اسم الكمبوند - رقم المبنى - رقم الدور ( ان وجد ) - رقم الشقة ( ان وجد ) )",
    "مدينة": "( اسم أو رقم الشارع - رقم المبنى - رقم الدور - رقم الشقة )",
    "قرية": "( إسم القرية - البيت - علامة مميزة ( إن وجد ) )"
  };
  addressTemplateValidator: IAddressTemplateValidator = new EgyptAddressTemplateValidator();
}

export class SaudiArabiaConstants implements MarketplaceConstants {
  public currency: string = 'SAR';
  public currencyInArabic: string = 'ريال';
  public countryName: string = 'Saudi Arabia';
  public countryNameInArabic: string = 'السعودية';
  public phoneRegex: string =
    '^(009665|9665|\\+9665|05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$';
  public timeOffset: string = "+03";
  public deliveryTime = new Map<string, string>([
    ['الرياض', '48 ساعة'],
    ['جدة و الدمام', '72 ساعة'],
    ['باقى مدن المملكة', '96 ساعة '],
  ]);

  public deliveryFees = [
    'مصاريف الشحن ثابتة في جميع انحاء المملكة و هي بتكلفة 30 ريال !'
  ]

  addressCategories: string[] = [
    "كمبوند",
    "مدينة",
    "قرية"
  ];
  addressCategoriesHint: { [index: string]: any } = {
    "كمبوند": "( اسم الكمبوند - رقم المبنى - رقم الدور ( ان وجد ) - رقم الشقة ( ان وجد ) - علامة مميزة ( ان وجد ) )",
    "مدينة": "( اسم و رقم الشارع - رقم المبنى - رقم الدور - رقم الشقة - علامة مميزة ( ان وجد ) )",
    "قرية": "( اسم القرية - البيت - علامة مميزة ( ان وجد ) )"
  };
  addressTemplateValidator: IAddressTemplateValidator = new SaudiAddressTemplateValidator();
}

export class MoroccoConstants implements MarketplaceConstants {
  public currency: string = 'MAD';
  public currencyInArabic: string = 'درهم مغربي';
  public countryName: string = 'Morocco';
  public countryNameInArabic: string = 'المغرب';
  public phoneRegex: string =
    '^(\+212|00212|0)?(6|7)[0-9]{8}$';
  public timeOffset: string = "+01";
  public deliveryTime = new Map<string, string>([
  ]);
  public deliveryFees = [
    ''
  ]
  addressCategories: string[] = [
    "كمبوند",
    "مدينة",
    "قرية"
  ];
  addressCategoriesHint: { [index: string]: any } = {
    "كمبوند": "( اسم الكمبوند - رقم المبنى - رقم الدور ( ان وجد ) - رقم الشقة ( ان وجد ) - علامة مميزة ( ان وجد ) )",
    "مدينة": "( اسم و رقم الشارع - رقم المبنى - رقم الدور - رقم الشقة - علامة مميزة ( ان وجد ) )",
    "قرية": "( اسم القرية - البيت - علامة مميزة ( ان وجد ) )"
  };
  addressTemplateValidator: IAddressTemplateValidator = new SaudiAddressTemplateValidator();
}

export class UAEConstants implements MarketplaceConstants {
  public currency: string = 'AED';
  public currencyInArabic: string = 'درهم اماراتي';
  public countryName: string = 'Emirates';
  public countryNameInArabic: string = 'الامارات';
  public phoneRegex: string = '^((\\+|00)(971)|0)?[1-9][0-9]{8}$';
  public timeOffset: string = "+04";
  public deliveryTime = new Map<string, string>([
    ['جميع انحاء الامارات', '24 ساعة (يوم واحد)'],
  ]);

  public deliveryFees = [
    'مصاريف الشحن ثابتة في جميع انحاء الامارات و هي بتكلفة 30 درهم اماراتي !'
  ];

  addressCategories: string[] = ['كمبوند', 'مدينة', 'قرية'];
  addressCategoriesHint: { [index: string]: any } = {
    كمبوند:
      '( اسم الكمبوند - رقم المبنى - رقم الدور ( ان وجد ) - رقم الشقة ( ان وجد ) )',
    مدينة: '( اسم أو رقم الشارع - رقم المبنى - رقم الدور - رقم الشقة )',
    قرية: '( إسم القرية - البيت - علامة مميزة ( إن وجد ) )',
  };
  addressTemplateValidator: IAddressTemplateValidator =
    new UAEAddressTemplateValidator();
}

export class EmptyConstants implements MarketplaceConstants {
  public currency: string = '';
  public currencyInArabic: string = '';
  public countryName: string = '';
  public countryNameInArabic: string = '';
  public phoneRegex: string = '';
  public timeOffset: string = '';
  public deliveryTime = new Map<string, string>([]);
  public deliveryFees = [''];
  addressCategories: string[] = [];
  addressCategoriesHint: { [index: string]: any } = {
    كمبوند:
      '',
    مدينة: '',
    قرية: '',
  };
  addressTemplateValidator: IAddressTemplateValidator =
    new UAEAddressTemplateValidator();
}

export class ConstantsFactory {
  public static createConstants(id: any): MarketplaceConstants {
    if (id === null || id === undefined) {
      return new EmptyConstants();
    }
    if (typeof id !== 'string') {
      id = id.toString()
    }
    if (id === '1') {
      return new EgyptConstants();
    } else if (id === '2') {
      return new SaudiArabiaConstants();
    } else if (id === '3') {
      return new MoroccoConstants();
    } else if (id === '4') {
      return new UAEConstants();
    }
    return new EmptyConstants();
  }
}

export enum Currencies {
  EGP = 'جنيه مصري',
  SAR = 'ريال سعودي',
  MAD = 'درهم مغربي',
  AED = 'درهم اماراتي',
  USD = 'دولار امريكي',
  EUR = 'يورو',


}