import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  MarketplaceCurrencyConfigurationListResponse,
  MarketplaceResponse,
  SwitchMarketplaceRequest,
} from '../../models/marketplace.models';
import { LoginResponse } from '../../models/login.model';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngxs/store';
import { LoginState } from 'src/app/store/login/login.state';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceService {
  constructor(private http: HttpClient, private store: Store) {}

  getMarketplaces(): Observable<HttpResponse<MarketplaceResponse>> {
    return this.http.get<MarketplaceResponse>(
      environment.baseUrl + 'base/marketplace/',
      { observe: 'response' }
    );
  }

  getSelectedMarketplaceId(): string {
    if (  sessionStorage.getItem('selectedMarketplaceId') === null ||
      isNaN(+sessionStorage.getItem('selectedMarketplaceId'))) {
      var tokenBody = this.store.selectSnapshot(LoginState.getTokenBody);
      if (tokenBody != null) {
        var selectedMarketplace = tokenBody.selected_marketplace;
        if(selectedMarketplace === null || isNaN(+selectedMarketplace)) {
          sessionStorage.setItem('selectedMarketplaceId', "1");
        } else {
          sessionStorage.setItem('selectedMarketplaceId', selectedMarketplace);
        }
        return selectedMarketplace;
      }
      return null;
    }
    return sessionStorage.getItem('selectedMarketplaceId');
  }

  setSelectedMarketplaceId(id: string): void {
    sessionStorage.setItem('selectedMarketplaceId', id);
  }

  switchMarketplace(
    payload: SwitchMarketplaceRequest
  ): Observable<HttpResponse<LoginResponse>> {
    return this.http.post<LoginResponse>(
      environment.baseUrl + 'base/switch-marketplace/',
      payload,
      { observe: 'response' }
    );
  }

  getShipmentFeesValues(): Observable<HttpResponse<{"shipment_fees_values": number[]}>> {
    return this.http.get<{"shipment_fees_values": number[]}>(
      environment.baseUrl + 'base/get-shipment-fees-marketplace/',
      { observe: 'response' }
    );
  }

  getMarketplaceCurrencies(marketplace_id: string) {
    const params = new HttpParams().set('marketplace_id', marketplace_id);
    return this.http.get<MarketplaceCurrencyConfigurationListResponse>(
      environment.baseUrl + `base/marketplace-currency-configuration`,
      { params, observe: 'response' }
    );
  }
}
