import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import {
  SwitchMarketplaceAction,
  GetMarketplacesAction,
} from './marketplace.actions';
import { MarketplaceService } from 'src/app/shared/services/marketplace/marketplace.service';
import { tap, catchError } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { LoginResponse } from 'src/app/shared/models/login.model';
import { MarketplaceModel } from 'src/app/shared/models/marketplace.models';
import { MixpanelService } from 'src/app/mixpanel.service';
export interface MarketplaceStateModel {
  marketplaces: MarketplaceModel[];
  selectedMarketplace: MarketplaceModel;
}

@State<MarketplaceStateModel>({
  name: 'MarketplaceState',
  defaults: {
    marketplaces: [],
    selectedMarketplace: {id: "", name: "", currency: "", conversion_rate: 0.0, vat_in_percentage: 0.0},
  },
})
@Injectable()
export class MarketplaceState {
  constructor(
    private mixpanelService: MixpanelService,
    private marketplaceService: MarketplaceService,
  ) {}

  @Selector()
  public static getSelectedMarketplace(state: MarketplaceStateModel) {
    return state.selectedMarketplace;
  }

  @Selector()
  public static getMarketplaces(state: MarketplaceStateModel) {
    return state.marketplaces;
  }

  @Action(GetMarketplacesAction)
  public getMarketplacesAction(ctx: StateContext<MarketplaceStateModel>,
    {}: GetMarketplacesAction) {
    var selectedMarkeplaceId: string =
      this.marketplaceService.getSelectedMarketplaceId();
    return this.marketplaceService.getMarketplaces().subscribe((res) => {
      ctx.setState({
        marketplaces: res.body.marketplaces,
        selectedMarketplace: res.body.marketplaces.filter(
          (m) => m.id == selectedMarkeplaceId
        )[0]
      });
    });
  }

  @Action(SwitchMarketplaceAction)
  public switchMarketplace(
    ctx: StateContext<MarketplaceStateModel>,
    { payload }: SwitchMarketplaceAction
  ) {
    return this.marketplaceService.switchMarketplace(payload).pipe(
      tap((res: HttpResponse<LoginResponse>) => {
        if (res.status === 200) {
          this.mixpanelService.track(
            'MARKETPLACE-Switch marketplace Successfully'
          );
          ctx.patchState({
            selectedMarketplace: ctx
              .getState()
              .marketplaces.filter((m) => m.id == payload.marketplace_id)[0],
          });
          this.marketplaceService.setSelectedMarketplaceId(payload.marketplace_id)
          location.reload();
        }
      }),
      catchError((err) => {
        this.mixpanelService.track('LOGIN-Login Failed');
        throw err;
      })
    );
  }
}
