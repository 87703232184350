import { SortingInterface } from "src/app/home/home.component";

export class FilterField {
  name: string;
  type: FilterType;
  placeholder: string;
  dropDownArray?: any[];
  fieldDropDownName?: string;
  enableAutoComplete?: boolean;
  suggestions?: any[];
  autoCompleteLoading?: boolean;

  constructor(
    name: string,
    type: FilterType,
    placeholder: string,
    dropDownArray?: any[],
    fieldDropDownName?: string,
    enableAutoComplete?: boolean,
    suggestions?: any[],
    autoCompleteLoading?: boolean
  ) {
    this.name = name;
    this.type = type;
    this.placeholder = placeholder;
    this.dropDownArray = dropDownArray;
    this.fieldDropDownName = fieldDropDownName;
    this.enableAutoComplete = enableAutoComplete;
    this.suggestions = suggestions;
    this.autoCompleteLoading = autoCompleteLoading;
  }
}

export enum FilterType {
  text = 'text',
  number = 'number',
  date = 'date',
  dropdown = 'dropdown',
}

export function dateToString(date: Date): string {
  if (!date) return '';
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}


export const sortingTabs : string[] = [
  'كل المنتجات',
  'الأكثر مبيعاً',
  'الأرخص سعراً',
  'المنتجات الجديدة',
  'الأكثر ربحاً',
];

export function initializeSortingMap(sortTabMap :Map<number, SortingInterface> ) {
  sortTabMap.set(0, {
    ordering: 'product.rank',
    direction: 'desc',
  });
  sortTabMap.set(1, {
    ordering: 'quantity_ordered',
    direction: 'desc',
  });
  sortTabMap.set(2, {
    ordering: 'minimum_selling_price',
    direction: 'asc',
  });
  sortTabMap.set(3, {
    ordering: 'created_at',
    direction: 'desc',
  });
  sortTabMap.set(4, {
    ordering: 'min_profit',
    direction: 'desc',
  });
}
