import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { merge, interval, Observable } from 'rxjs';
import { first, filter } from 'rxjs/operators';
import { UpdateModalService } from 'src/app/components/update-modal/update-modal.service';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  appStable$: Observable<any> = new Observable<any>();
  everyHour$: Observable<number> = new Observable<number>();
  everyHourStable$: Observable<any> = new Observable<any>();
  oldVersion: string;
  availableVersion: string;

  constructor(
    private updates: SwUpdate,
    appRef: ApplicationRef,
    private updateModal: UpdateModalService
  ) {
    this.appStable$ = appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );
    this.everyHour$ = interval(60 * 1000);
    this.everyHourStable$ = merge(this.appStable$, this.everyHour$);
    this.checkAfterHour();
  }

  checkAfterHour() {
    this.everyHour$.subscribe(() => {
      this.updates.checkForUpdate();
    });
  }

  applyUpdate() {
    this.updates.versionUpdates.pipe(
      filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
    ).subscribe((event) => {
      this.oldVersion = event.currentVersion.hash;
      this.availableVersion = event.latestVersion.hash;
      this.updates.activateUpdate().then(() => document.location.reload());
    });
  }

  openModal() {
    const dialofRef$ = this.updateModal.openUpdateDialog();
    dialofRef$.afterClosed().subscribe((result: boolean) => {
      this.updates.activateUpdate().then(() => document.location.reload());
    });
  }
}
