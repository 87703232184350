import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { showToast } from 'src/app/core/services/snackbar/show-snackbar';
import { CartItemModel } from 'src/app/shared/models/cart.model';
import { UpdateCartItem } from 'src/app/store/cart/cart.actions';
@Component({
  selector: 'app-quantity-incrementor',
  templateUrl: './quantity-incrementor.component.html',
  styleUrls: ['./quantity-incrementor.component.scss'],
})
export class QuantityIncrementorComponent implements OnInit {
  @Input() quantity: number;
  @Input() enableLargeButton: boolean = false;
  @Input() variantID: string;
  @Output() quantityUpdated = new EventEmitter<number>();
  @Select((state: any) => state.Cart.cartItems)
  cartObservable: Observable<CartItemModel[]>;

  CartQuantity: number = 1;
  CartItemID: string;
  emittedMap = new Map<string, number>();
  tempQuantityDict: Map<string, number>;
  icons: string[] = ['add', 'remove'];

  constructor(
    private sanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private snackbar: MatSnackBar,
    private store: Store
  ) {
    this.addicons();
  }
  ngOnInit(): void {
    this.cartObservable.subscribe((cartItems) => {
      cartItems.forEach((cartItem) => {
        if (cartItem.variant.id == this.variantID) {
          this.CartQuantity = cartItem.quantity;
          this.CartItemID = cartItem.id;
        }
      });
    });
  }

  onIncrementClick() {
    if (this.CartItemID) {
      this.updateCartItem(this.CartItemID, this.CartQuantity + 1);
    } else {
      this.CartQuantity += 1;
      this.quantityUpdated.emit(this.CartQuantity);
    }
  }

  onDecrementClick() {
    var quantity = this.CartQuantity;
    if (quantity > 1) {
      quantity--;
      if (this.CartItemID) {
        this.updateCartItem(this.CartItemID, quantity);
      } else {
        this.CartQuantity = quantity;
        this.quantityUpdated.emit(this.CartQuantity);
      }
    }
  }

  addicons() {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `../../../../assets/icons/${icon}.svg`
        )
      );
    }
  }

  updateCartItem(key: any, quantity: number) {
    var tempCartQuantity = this.CartQuantity;
    this.CartQuantity = quantity;
    const body = {
      quantity: quantity,
    };
    this.store.dispatch(new UpdateCartItem(key, body)).subscribe(
      (_) => {
        this.quantityUpdated.emit(this.CartQuantity);
      },
      (error) => {
        showToast(
          this.snackbar,
          'حدث خطأ لم نتمكن من حفظ تعديلاتك',
          false,
          2000
        );
        this.CartQuantity = tempCartQuantity;
      }
    );
  }
}
