import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { showToast } from 'src/app/core/services/snackbar/show-snackbar';
import { LoginRequest, OTPRequest } from 'src/app/shared/models/login.model';
import { ArabicToEnglishNumbersService } from 'src/app/shared/services/arabic-to-english-numbers.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { CoreService } from 'src/app/shared/services/core/core.service';
import {
  AuthorizeOTPSubmissionRequest,
  LoginRegisteredUser,
  loginAction,
} from 'src/app/store/login/login.action';
import { MixpanelService } from 'src/app/mixpanel.service';
import { OTPProps } from './utils';
import { Router } from '@angular/router';
import { RegisterService } from 'src/app/shared/services/register/register.service';
import { RegisterPayload } from 'src/app/shared/models/register.model';
import { FireBaseResolvedAuthTypeEnum } from 'src/app/shared/service-helpers/firebase-auth-helper';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss'],
})
export class OtpComponent implements OnInit {
  icons: string[] = ['otp-illustration', 'arrow-left'];
  props: OTPProps;

  OTP_LENGTH = 5;

  otpCells = ['input1', 'input2', 'input3', 'input4', 'input5'];

  invalidCodeEntered: boolean = false;
  otpSubmittedSuccessfully: boolean = false;

  otpForm: UntypedFormGroup;

  otpCode: string;

  @ViewChildren('formRow') rows: any;

  loadingOTPProcessing: boolean = false;

  constructor(
    // private dialogRef: MatDialogRef<OtpComponent>,
    private sanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private authService: AuthService,
    private registerService: RegisterService,
    private snackbar: MatSnackBar,
    private store: Store,
    private coreService: CoreService,
    private arToEnNumTranslation: ArabicToEnglishNumbersService,
    private mixpanelService: MixpanelService,
    private router: Router // @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.addIcons();
    this.otpForm = this.toFormGroup(this.otpCells);
    this.handleIOSPasteFromMessagesEvent();
    this.props = this.authService.props;
  }

  handleIOSPasteFromMessagesEvent() {
    this.otpForm.valueChanges.subscribe((event) => {
      const isIOS = navigator.userAgent.match(/ipad|ipod|iphone/i);
      if (isIOS) {
        for (let key of Object.keys(event)) {
          let currentForm = this.otpForm.get(key);
          if (currentForm.value.length > 1 && currentForm.value.length < 6) {
            this.validateAndSubmitOTPAutoInputData(currentForm.value);
            break;
          }
        }
      }
    });
  }

  translatePhoneNumberIfPossible(phone_number: string) {
    return this.arToEnNumTranslation.convertArabicNumStrToEnglishNumStr(
      phone_number
    );
  }

  onSubmit() {
    this.mixpanelService.track('OTP - Submit');
    if (this.otpForm.invalid) {
      this.invalidCodeEntered = true;
    } else {
      this.otpCode = this.getOTPFormValue();
      let request: OTPRequest = {
        code: this.otpCode,
        scope: this.props.scope,
        phone_number: this.translatePhoneNumberIfPossible(
          this.props.phoneNumber
        ),
      };
      if (this.props.scope == 'sign up firebase')
      {
        request.scope = 'sign up'
      }
      this.loadingOTPProcessing = true;
      this.authService.submitOTP(request).subscribe(
        (res) => {
          this.loadingOTPProcessing = false;
          this.store.dispatch(new AuthorizeOTPSubmissionRequest(this.otpCode));
          
          if (this.props.scope == 'sign up') {
            this.mixpanelService.track('OTP - Success');
            let payload: RegisterPayload = {
              username: this.props.username,
              password: this.props.password,
              name: this.props.name,
              email: this.props.email,
              country_code: this.props.country_code,
              code: this.otpCode,
            };
            this.registerService.registerSeller(payload).subscribe(
              (res) => {
                if (res.status == 200) {
                  this.mixpanelService.track(
                    'REGISTER-Sign Up Successfully using Normal registration'
                  );
                  let loginRequest: LoginRequest = {
                    username: payload.username,
                    password: payload.password,
                  };
                  this.store.dispatch(new loginAction(loginRequest));
                  this.router.navigate(['/welcome-questions']);
                }
              },
              (error) => {
                let errorMsg = '';
                if (error.error.error_msg) {
                  errorMsg = error.error.error_msg;
                } else {
                  errorMsg = error.error.detail;
                }
                if (errorMsg.includes('تم بالفعل ارسال كود لرقمك')) {
                  let data = {
                    phoneNumber: this.props.phoneNumber,
                    scope: 'sign up',
                    username: this.props.username,
                    password: this.props.password,
                    name: this.props.name,
                    email: this.props.email,
                    code: this.otpCode,
                    country_code: this.props.country_code
                  };
                  this.authService.props = data;

                  this.router.navigate(['/otp']);
                  return;
                }
                this.mixpanelService.track('REGISTER-Sign Up Failed', {
                  'Sign Up Error': errorMsg,
                });

                this.coreService.openAnyErrorMessageModal(
                  error.status,
                  errorMsg
                );
              }
            );
          } else if (this.props.scope == 'reset password') {
            this.mixpanelService.track('OTP - Success');
            this.authService.props.otpVerified = true;
            this.authService.props.phoneNumber = this.props.phoneNumber;
            this.authService.props.otpCode = this.otpCode;
            this.router.navigate(['/forgot-password']);
          } else if (this.props.scope == 'sign up firebase') {
            this.mixpanelService.track('OTP - Success');
            let firebase_request = this.props.firebase_request;
            firebase_request.code = this.otpCode;

            this.authService
              .registerFirebaseAuthedUser(firebase_request)
              .subscribe(
                (response) => {
                  if (response.body.access) {
                    const authBody = response.body;
                    this.store.dispatch(
                      new LoginRegisteredUser(authBody.access, authBody.refresh)
                    );
                    this.router.navigate(['/welcome-questions']);
                  }
                },
                (err) => {
                  const authType = localStorage.getItem('auth_type');
                  localStorage.clear();
                  if (authType == 'facebook' || authType == 'google') {
                    if (authType == FireBaseResolvedAuthTypeEnum.GOOGLE) {
                      this.mixpanelService.track('LOGIN-Google Login Failed', {
                        'Google Login Error': err.message,
                      });
                    } else if (
                      authType == FireBaseResolvedAuthTypeEnum.FACEBOOK
                    ) {
                      this.mixpanelService.track(
                        'LOGIN-Facebook Login Failed',
                        {
                          'Facebook Login Error': err.message,
                        }
                      );
                    }
                  }

                  if (
                    err.code == 'auth/account-exists-with-different-credential'
                  ) {
                    this.coreService.openAnyErrorMessageModal(
                      400,
                      `هذا الايميل مسجل بالفعل بطريقة دخول أخري غير ${authType}, تأكد من استخدام تلك الطريقة لتتمكن من الدخول الي حسابك`
                    );
                  }
                }
              );
          }
        },
        (err) => {
          this.mixpanelService.track('OTP - Failed');
          this.loadingOTPProcessing = false;
          if (err instanceof HttpErrorResponse) {
            if (err.status == 401) {
              this.invalidCodeEntered = true;
            } else {
              this.coreService.openAnyErrorMessageModal(
                err.status,
                err.error.detail ?? 'فشلت المحاولة.. حاول مرة أخري'
              );
            }
          }
        }
      );
    }
  }

  handleOTPInput($event: any) {
    $event.stopImmediatePropagation();
    $event.preventDefault();
    if ($event.data?.length == this.OTP_LENGTH) {
      this.validateAndSubmitOTPAutoInputData($event.data);
    }
  }

  resendOTP() {
    this.mixpanelService.track('OTP - Send Again');
    this.loadingOTPProcessing = true;
    this.authService
      .resendOTP(this.props.phoneNumber, this.props.scope)
      .subscribe(
        (res) => {
          showToast(this.snackbar, 'تم ارسال الكود مرة  أخري', true, 2000);
          this.loadingOTPProcessing = false;
        },
        (err) => {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail ?? 'فشلت المحاولة.. حاول مرة أخري'
          );
          this.loadingOTPProcessing = false;
        }
      );
  }

  getOTPFormValue() {
    let value = '';
    for (let key of this.otpCells) {
      value += this.otpForm.controls[key].value;
    }
    return this.translatePhoneNumberIfPossible(value);
  }

  toFormGroup(elements: string[]) {
    const group: any = {};
    elements.forEach((key) => {
      group[key] = new UntypedFormControl('', Validators.required);
    });
    return new UntypedFormGroup(group);
  }

  ngOnInit(): void {
    if(this.props.scope.startsWith('sign up') && (this.props.country_code !== '+20' || this.props.phoneNumber.startsWith('012')) )
   {
    this.otpForm.controls.input1.setValue(0);
    this.otpForm.controls.input2.setValue(0);
    this.otpForm.controls.input3.setValue(0);
    this.otpForm.controls.input4.setValue(0);
    this.otpForm.controls.input5.setValue(0);
    this.onSubmit();
  }
    this.mixpanelService.track('OTP - Opened');
    
  }

  addIcons(): void {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `../../../assets/icons/${icon}.svg`
        )
      );
    }
  }

  pasteOTP($event: ClipboardEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    let clipboardData = $event.clipboardData;
    const data = clipboardData.getData('text');
    this.validateAndSubmitOTPAutoInputData(data);
  }

  validateAndSubmitOTPAutoInputData(data: string) {
    if (this.OTPInputDataIsValid(data)) {
      for (let i = 0; i < data.length; i++) {
        this.otpForm.controls[this.otpCells[i]].setValue(data[i]);
        this.rows._results[i].nativeElement.focus();
      }
      this.onSubmit();
    }
  }

  // Validates that the clipboard data is a valid OTP to enter
  OTPInputDataIsValid(otp: string) {
    if (otp.length == this.OTP_LENGTH) {
      for (let c of otp) {
        if (isNaN(parseInt(c))) return false;
      }
      return true;
    }
    return false;
  }

  /**
   * Accepted `KeyboardEvent.Key` values for the otp form.
   */
  acceptedKeys = [
    // english numbers
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '0',

    // arabic number
    '\u0661',
    '\u0662',
    '\u0663',
    '\u0664',
    '\u0665',
    '\u0666',
    '\u0667',
    '\u0668',
    '\u0669',
    '\u0660',

    // backspace keycode
    'Backspace',
  ];

  /**
   * Accept `KeyboardEvent.Key` values for submitting the form and pasting to it only.
   */
  acceptedControlKeys = ['v', 'Control', 'Enter'];

  // Allow pasting and clicking enter only
  otpCellkeyDownEvent($event: KeyboardEvent) {
    this.mixpanelService.track('OTP - Field');
    if (!this.acceptedControlKeys.includes($event.key)) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  keyUpEvent($event: KeyboardEvent, index: number, cell: string) {
    let pos = index;
    if (!this.acceptedKeys.includes($event.key)) return;
    this.invalidCodeEntered = false;
    if ($event.key === 'Backspace') {
      this.otpForm.controls[cell].setValue('');
      pos = index - 1;
    } else {
      this.otpForm.controls[cell].setValue($event.key);
      pos = index + 1;
    }

    if (pos > -1 && pos < this.otpCells.length) {
      this.rows._results[pos].nativeElement.focus();
    }
  }

  goToRegisterPage(): void {
    this.mixpanelService.track('OTP - Go Back to Registration Page');
    this.router.navigate(['/register']);
  }
}
