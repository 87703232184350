import { UserInfoModel } from "src/app/shared/models/user.model";


export enum UserInfoActionTypes {
  OpenedOnboardingCards = "[User] Opened onboarding cards",
  GetUserInfo = "[User] Get user info",
  UpdateUserInfo = "[User] Update user info",
  ClearUserInfo = "[User] Clear user info",
  UpdateVisitedPageStatus = "[User] Update Visited Page Status"
}


export class SetOpenedOnboardingCards {
  public static readonly type = UserInfoActionTypes.OpenedOnboardingCards;
  constructor() { }
}

export class GetUserInfo {
  public static readonly type = UserInfoActionTypes.GetUserInfo;
  constructor() { }

}
export class UpdateUserInfo {
  public static readonly type = UserInfoActionTypes.UpdateUserInfo;
  constructor(public userInfo: UserInfoModel) { }
}
export class ClearUserInfoState {
  public static readonly type = UserInfoActionTypes.ClearUserInfo;
  constructor() { }
}

export class UpdateVisitedPageStatus {
  public static readonly type = UserInfoActionTypes.UpdateVisitedPageStatus;
  constructor(public page_name: string) { }
}