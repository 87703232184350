import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { BillingActions } from '../../store/billing/billing.actions';
import { ClearCartState } from '../../store/cart/cart.actions';
import { ClearsOrdersState } from '../../store/orders/orders.actions';
import { ClearProductsState } from '../../store/products/products.actions';
import { ProfileActions } from '../../store/profile/profile.actions';
import { ClearUserInfoState } from 'src/app/store/user-info/user-info.action';
@Injectable({
  providedIn: 'root',
})
export class StateClearerService {
  constructor(private store: Store) {}

  public clearAllState() {
    this.store.dispatch(new ClearProductsState()).subscribe();
    this.store.dispatch(new BillingActions.ClearBillingState()).subscribe();
    this.store.dispatch(new ClearCartState()).subscribe();
    this.store.dispatch(new ClearsOrdersState()).subscribe();
    this.store.dispatch(new ProfileActions.ClearProfileState()).subscribe();
    this.store.dispatch(new ClearUserInfoState()).subscribe();
  }
}
