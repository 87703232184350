import { SwitchMarketplaceRequest } from "../../shared/models/marketplace.models";

export class GetMarketplacesAction {
    public static readonly type = '[Marketplace] Get available marketplaces';
    constructor() {}
}

export class SwitchMarketplaceAction {
    public static readonly type = '[Marketplace] Switch Marketplace Action';
    constructor(public payload: SwitchMarketplaceRequest) {}
}