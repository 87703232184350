export enum FireBaseAuthProviderIDEnum {
    GOOGLE = 'google.com',
    FACEBOOK = 'facebook.com'
}

export enum FireBaseResolvedAuthTypeEnum {
    GOOGLE = 'google',
    FACEBOOK = 'facebook'
}



export function resolveFirebaseProvider(firebaseAuthProviderId: string) {
    switch(firebaseAuthProviderId) {
        case FireBaseAuthProviderIDEnum.GOOGLE:
            return FireBaseResolvedAuthTypeEnum.GOOGLE;
        case FireBaseAuthProviderIDEnum.FACEBOOK:
            return FireBaseResolvedAuthTypeEnum.FACEBOOK;
        default:
            return null;
    }
}
