import { ListHeader } from 'src/app/shared/service-helpers/filter-helper';
import { FilterValue } from 'src/app/shared/service-helpers/filter-helper';

export enum OrderActionTypes {
  GetOrders = '[Orders] Get Orders',
  GetOrdersStats = '[Orders] Get Orders Stats',
  ClearState = '[Orders] Clear State',
  GetOrderDetailedData = '[Orders] Get Order Detailed Data',
  ExportOrdersCSV = '[Orders] Export Orders CSV'
}

export class GetOrders {
  public static readonly type = OrderActionTypes.GetOrders;
  constructor(public header: ListHeader, public filterValues: FilterValue[]) {}
}

export class GetOrdersStats {
  public static readonly type = OrderActionTypes.GetOrdersStats;
  constructor() {}
}

export class ClearsOrdersState {
  public static readonly type = OrderActionTypes.ClearState;
  constructor() {}
}

export class GetOrderDetailedData{
  public static readonly type = OrderActionTypes.GetOrderDetailedData;
  constructor(public orderId:string) {}
}


export class ExportOrdersCSV{
  public static readonly type = OrderActionTypes.ExportOrdersCSV;
  constructor() {}
}