import { State, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UploadFileResponse } from 'src/app/shared/models/upload-file.model';
import { UploadFileService } from 'src/app/shared/services/upload-file/upload-file.service';
import { GetActiveFile, CancelFileProcessing, GetFileState, UploadFile, DeleteOrderFileState } from './file.action';

export class FileStateModel {
    orderFile: UploadFileResponse;
}

@State<FileStateModel>({
    name: 'File',
    defaults: FileState.defaultState,
})
@Injectable()
export class FileState {
    constructor(private fileService: UploadFileService) { }

    static defaultState: FileStateModel = {
        orderFile: {
            id: null,
            file_type: null,
            status: null,
            total_number_of_fields: null,
            total_number_of_proccessed_fields: null,
            total_number_of_successful_fields: null,
            message: null
        }

    };

    @Action(GetActiveFile)
    getCart(
        { patchState }: StateContext<FileStateModel>,
        { fileType }: GetActiveFile
    ) {
        return this.fileService.getActiveFile(fileType).subscribe((resp) => {
            if (fileType === "order creation") {
                patchState({
                    orderFile: resp.body,
                });
            }
            else {
                throw Error("File Type Not Supported")
            }

        })

    }
    @Action(CancelFileProcessing)
    cancelFileProcessing(
        { patchState }: StateContext<FileStateModel>,
        { id, fileType }: CancelFileProcessing
    ) {
        return this.fileService.cancelFileProcessing(id).subscribe((resp) => {
            if (fileType === "order creation") {
                patchState({
                    orderFile: resp.body,
                });
            }
            else {
                throw Error("File Type Not Supported")
            }

        })

    }
    @Action(UploadFile)
    uploadFile(
        { patchState }: StateContext<FileStateModel>,
        { formData }: UploadFile
    ) {
        let fileType = formData.get("file_type")
        return this.fileService.uploadFile(formData).subscribe((resp) => {
            if (fileType === "order creation") {
                patchState({
                    orderFile: resp.body,
                });
            }
            else {
                throw Error("File Type Not Supported")
            }

        })

    }

    @Action(GetFileState)
    getFileState(
        { patchState }: StateContext<FileStateModel>,
        { id, fileType }: CancelFileProcessing
    ) {
        return this.fileService.getFileStatus(id).subscribe((resp) => {
            if (fileType === "order creation") {
                patchState({
                    orderFile: resp.body,
                });
            }
            else {
                throw Error("File Type Not Supported")
            }

        })
    }

    @Action(DeleteOrderFileState)
    deleteOrderFileState(
        { patchState }: StateContext<FileStateModel>,
    ) {
        patchState({
            orderFile: FileState.defaultState.orderFile
        })
    }


}