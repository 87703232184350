<div class="main-component-div">
    <div class="right-div"></div>
    <div class="left-div">
        <div>
            <p class="text"> 
                لو في منتجات معينه مش لاقيها في الموقع ارسل لنا اقتراحك
            </p>
        </div>
        <div>
            <button class="btn" (click)="openDialog()">
                اقتراح منتج جديد
            </button>
        </div>
    </div>

</div>
