import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UpdateModalComponent } from './update-modal.component';

@Injectable({
  providedIn: 'root',
})
export class UpdateModalService {
  constructor(public dialog: MatDialog) {}

  public openUpdateDialog(): MatDialogRef<UpdateModalComponent> {
    const dialogRef$ = this.dialog.open(UpdateModalComponent);
    return dialogRef$;
  }

  public closeUpdateDialog() {
    this.dialog.closeAll();
  }
}
