import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ConstantsFactory } from 'src/app/common/global-constants';
import { MarketplaceModel } from 'src/app/shared/models/marketplace.models';
import { AchievementBonus } from 'src/app/shared/models/popups.model';

@Component({
  selector: 'app-achievement-popup',
  templateUrl: './achievement-popup.component.html',
  styleUrls: ['./achievement-popup.component.scss'],
})
export class AchievementPopupComponent implements OnInit {
  icons: string[] = ['gift'];
  currencyInArabic: string;

  @Select((state: any) => state.MarketplaceState.selectedMarketplace)
  selectedMarketplaceObservable: Observable<MarketplaceModel>;

  constructor(
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private matIconRegistry: MatIconRegistry,
    private dialogRef: MatDialogRef<AchievementPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AchievementBonus
  ) {
    this.addIcons();
  }

  ngOnInit(): void {
    this.subscribeObservables();
  }

  subscribeObservables() {
    this.selectedMarketplaceObservable.subscribe((marketplace) => {
      this.currencyInArabic = ConstantsFactory.createConstants(
        marketplace.id
      ).currencyInArabic;
    });
  }

  closeDialog() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  addIcons(): void {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `../../../../assets/icons/${icon}.svg`
        )
      );
    }
  }
}
