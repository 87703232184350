<app-loading [loading]="loading"></app-loading>

<div class="banner-container">
  <app-banner [sliderMedia]="sliderMedia"></app-banner>
</div>

<app-products-grid
  id="products"
  [variants]="sectionVariants"
  [headline]="sectionHeadline"
  [callingComponentName] = "componentName"
></app-products-grid>
