import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { RegisterPayload,RegistrationResponse } from '../../models/register.model';

import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) {
   }

  registerSeller(payload: RegisterPayload): Observable<HttpResponse<RegistrationResponse>>{
    let header: HttpHeaders = new HttpHeaders().append("noauth", "true");
    return this.http.post<RegistrationResponse>(
      environment.baseUrl + 'base/sign-up/',
      payload,
      { observe: 'response',headers: header }
    );
  }
}
