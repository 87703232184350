import { ResetPasswordPayload } from "src/app/shared/models/auth.models";
import { LoginRequest } from "src/app/shared/models/login.model";

export enum LoginActionTypes{
  Authenticate="[Auth] Authenticate Seller",
  Refresh="[Refresh] Refresh Seller",
  AuthenticateFireBaseUser="[Auth] Authenticate firebase user",
  Logout="[Logout] Clear User Data on logout",
  ResetPassword = "[Auth] Reset Password Action"
}


export class loginAction {
  public static readonly type = LoginActionTypes.Authenticate;
  constructor(public payload: LoginRequest) {}
}

export class refreshTokenAction {
  public static readonly type = LoginActionTypes.Refresh;
  constructor(public access: string) {}
}

export class LoginRegisteredUser {
  public static readonly type = LoginActionTypes.AuthenticateFireBaseUser;
  constructor(public access: string, public refresh: string) {}
}
export class LogoutAction{
  public static readonly type = LoginActionTypes.Logout;
  constructor() {}

}

export class AuthorizeOTPSubmissionRequest {
  public static readonly type = LoginActionTypes.ResetPassword;
  constructor(public code: string) {}
}

