import { TranslateService } from '@ngx-translate/core';
import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ProgressBonus } from 'src/app/shared/models/popups.model';
import { MarketplaceModel } from 'src/app/shared/models/marketplace.models';
import { ConstantsFactory } from 'src/app/common/global-constants';


@Component({
  selector: 'app-progress-popup',
  templateUrl: './progress-popup.component.html',
  styleUrls: ['./progress-popup.component.scss'],
})
export class ProgressPopupComponent implements OnInit {
  icons: string[] = ['smile', 'making-progress'];
  confirmedOrdersText: string;
  confirmedOrderText: string;

  currencyInArabic: string;

  @Select((state: any) => state.MarketplaceState.selectedMarketplace)
  selectedMarketplaceObservable: Observable<MarketplaceModel>;

  constructor(
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private matIconRegistry: MatIconRegistry,
    private dialogRef: MatDialogRef<ProgressPopupComponent>,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: ProgressBonus
  ) {
    this.translate
      .stream(['common.orders', 'common.order'])
      .subscribe((translations) => {
        this.confirmedOrdersText = `${translations['common.orders']} تم تسليمها`;
        this.confirmedOrderText = `${translations['common.order']} تم تسليمه`;
      });
    this.addIcons();
  }

  ngOnInit(): void {
    this.subscribeObservables();
  }

  closeDialog() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  addIcons(): void {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `../../../../assets/icons/${icon}.svg`
        )
      );
    }
  }

  subscribeObservables() {
    this.selectedMarketplaceObservable.subscribe((marketplace) => {
      this.currencyInArabic = ConstantsFactory.createConstants(
        marketplace.id
      ).currencyInArabic;
    });
  }
}