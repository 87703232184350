<div class="banner-container">
    <ngx-slick-carousel 
      class="carousel" 
      [config]="slideConfig" 
      dir="rtl" 
      [ngClass]="{ grab: sliderMedia.length > 1 }">
      
      <div ngxSlickItem *ngFor="let row of sliderMedia" class="slick-slide-div">
        <div *ngIf="row.url; else elseBlock">
          <a href="{{ row.url }}" class="slick-slide-media">
            <img *ngIf="row.type === 'image'" src="{{ row.image_file }}" class="slick-slide-media" />
          </a>
        </div>
        <ng-template #elseBlock>
          <img *ngIf="row.type === 'image'" src="{{ row.image_file }}" class="slick-slide-media" />
        </ng-template>
        
        <video *ngIf="row.type === 'video'" class="slick-slide-media" autoplay muted loop playsinline>
          <source src="{{ row.video_file }}" type="video/mp4" />
        </video>
      </div>
    </ngx-slick-carousel>
  </div>
  