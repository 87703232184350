import { Subject } from "rxjs";

export class ScreenSizeTracker {

    private static _instance: ScreenSizeTracker;
    public readonly  desktopBreakpoint = 770;
    public readonly  tableteBreakpoint = 1110;
    public screenSize: number;
    public isDesktop: boolean;
    public isTablete :boolean;
    public screenSizeSubject: Subject<boolean> = new Subject<boolean>();
    
    

    private constructor() {

    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    public updateScreenParams(screenSize:number){
        this.screenSize = screenSize
        this.isDesktop = false;
        this.isTablete = false;
        if (this.screenSize > this.desktopBreakpoint) {
          this.isDesktop = true;
        }
        if (this.screenSize > this.tableteBreakpoint) {
            this.isTablete = true;
          }
        this.screenSizeSubject.next(this.isDesktop);
    }
}
