import { BillingActions } from 'src/app/store/billing/billing.actions';
import { BillingService } from './../../shared/services/billing/billing.service';
import {
  BillingStats,
  GetVouchersResponse,
  PaymentRequest,
  PaymentRequestResponse,
} from 'src/app/shared/models/billing.model';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { catchError, map, tap } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { showToast } from 'src/app/core/services/snackbar/show-snackbar';
import { CoreService } from 'src/app/shared/services/core/core.service';
import { of } from 'rxjs';

export interface ConversionRate {
  toCurrency: string;
  value: number;
}

export interface BillingStateModel {
  payments: PaymentRequestResponse;
  vouchers: GetVouchersResponse;
  deductions: GetVouchersResponse;
  stats: BillingStats;
  conversionRates: Map<string, ConversionRate[]>;
}

@State<BillingStateModel>({
  name: 'Billing',
  defaults: BillingState.defaultState,
})
@Injectable()
export class BillingState {
  constructor(
    private billingService: BillingService,
    private snackbar: MatSnackBar,
    private coreService: CoreService
  ) {}

  static defaultState: BillingStateModel = {
    payments: {
      meta: {
        total_results: 0,
        total_pages: 0,
        page: 0,
        per_page: 0,
      },
      payment_requests: [],
    },
    vouchers: {
      meta: {
        total_results: 0,
        total_pages: 0,
        page: 0,
        per_page: 0,
      },
      vouchers: [],
    },
    deductions: {
      meta: {
        total_results: 0,
        total_pages: 0,
        page: 0,
        per_page: 0,
      },
      vouchers: [],
    },
    stats: {
      orders_total_cost: 0,
      orders_total_profit: 0,
      current_balance: 0,
      total_in_progress: 0,
      total_transferred: 0,
    },
    conversionRates: new Map<string, ConversionRate[]>()
  };

  @Action(BillingActions.GetAllPayments)
  getAllPayments(
    { patchState }: StateContext<BillingStateModel>,
    { header, filterValues }: BillingActions.GetAllPayments
  ) {
    return this.billingService.getPayments(header, filterValues).pipe(
      tap((res) => {
        const payments = res.body;
        patchState({
          payments,
        });
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(BillingActions.CreatePaymentRequest)
  createPaymentRequest(
    { setState }: StateContext<BillingStateModel>,
    { paymentRequest }: BillingActions.CreatePaymentRequest
  ) {
    return this.billingService.createPaymentRequest(paymentRequest).pipe(
      tap((res) => {
        showToast(this.snackbar, 'تم الإنشاء بنجاح');
        const createPaymentResponse = res.body;
        // Patching state here is not the best decision, we should make a
        // call to the backend to fetch the new data from the get endpoint
        setState(
          patch({
            payments: patch({
              payment_requests: insertItem(
                createPaymentResponse.payment_request
              ),
            }),
          })
        );
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(BillingActions.GetBillingStats)
  getBillingStats({ patchState }: StateContext<BillingStateModel>) {
    return this.billingService.getBillingStats().pipe(
      tap((res) => {
        patchState({
          stats: res.body,
        });
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(BillingActions.DeletePaymentRequest)
  deletePaymentRequest(
    { setState, getState }: StateContext<BillingStateModel>,
    { id }: BillingActions.DeletePaymentRequest
  ) {
    return this.billingService.deletePaymentRequest(id).pipe(
      tap((res) => {
        showToast(this.snackbar, 'تم الحذف بنجاح');
        let state = getState();
        setState(
          patch({
            payments: patch({
              payment_requests: removeItem<PaymentRequest>(
                (item) => item.id == id
              ),
              meta: patch({
                total_results: state.payments.meta.total_results - 1,
              }),
            }),
          })
        );
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(BillingActions.GetVouchers)
  getVouchers(
    { patchState }: StateContext<BillingStateModel>,
    { header, filterValues }: BillingActions.GetAllPayments
  ) {
    return this.billingService.getVouchers(header, filterValues).pipe(
      tap((res) => {
        const vouchers = res.body;
        patchState({
          vouchers,
        });
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(BillingActions.GetDeductions)
  getDeductions(
    { patchState }: StateContext<BillingStateModel>,
    { header, filterValues }: BillingActions.GetDeductions
  ) {
    return this.billingService.getDeductions(header, filterValues).pipe(
      tap((res) => {
        const deductions = res.body;
        patchState({
          deductions,
        });
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(BillingActions.ClearBillingState)
  clearBillingState({ setState }: StateContext<BillingStateModel>) {
    setState(BillingState.defaultState);
  }

  @Action(BillingActions.GetConversionRate)
  getConversionRate(
    { patchState, getState }: StateContext<BillingStateModel>,
    { fromCurrency, toCurrency }: BillingActions.GetConversionRate
  ) {
    let currentState = getState();
    let indexedConversionRatesForFromCurrency = currentState.conversionRates.get(fromCurrency);
    if(indexedConversionRatesForFromCurrency) {
      for(let item of indexedConversionRatesForFromCurrency) {
        if (item.toCurrency === toCurrency) {
          return of(item.value);
        }
      }
    }

    // Get from HTTP request
    // And set in the state
    return this.billingService.getConversionRate(
      fromCurrency, toCurrency
    ).pipe(
      tap(currencyConversion => {
        let currentStoredConversionRates = currentState.conversionRates;
        let itemToInsertInConversionArray = {
          toCurrency: currencyConversion.to_currency,
          value: currencyConversion.conversion_rate
        }
        if(indexedConversionRatesForFromCurrency) {
          // insert into the array
          indexedConversionRatesForFromCurrency.push(itemToInsertInConversionArray);
          // update map
          currentStoredConversionRates.set(fromCurrency, indexedConversionRatesForFromCurrency);
        } else {
          // Insert a new record in the map
          currentStoredConversionRates.set(fromCurrency, [itemToInsertInConversionArray]);
        }

        // Update state
        patchState({
          conversionRates: currentStoredConversionRates
        });
      }),
      // return the value only
      map(item => item.conversion_rate),
    );
  }
}
