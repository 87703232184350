import { UpdateUserInfo } from './user-info.action';
import { UserInfoModel } from './../../shared/models/user.model';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  SetOpenedOnboardingCards,
  GetUserInfo,
  ClearUserInfoState,
  UpdateVisitedPageStatus,
} from './user-info.action';
import { UserService } from 'src/app/shared/services/user/user.service';
import { MixpanelService } from 'src/app/mixpanel.service';
import { tap } from 'rxjs/operators';
import { showToast } from 'src/app/core/services/snackbar/show-snackbar';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@State<UserInfoModel>({
  name: 'UserInfo',
  defaults: UserInfoState.defaultState,
})
@Injectable()
export class UserInfoState {
  static defaultState: UserInfoModel = {
    opened_onboarding_cards: null,
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    image: '',
    can_upload_files: false,
    request_upload_files: false,
    marketplace_id: '1',
    seller_id: '',
  };
  constructor(
    private userService: UserService,
    private mixpanelService: MixpanelService,
    private snackbar: MatSnackBar
  ) {}

  @Selector()
  public static getUserInfo(state: UserInfoModel) {
    return state;
  }

  @Selector()
  public static getOpenedOnboardingCards(state: UserInfoModel): boolean {
    return state.opened_onboarding_cards;
  }

  @Action(SetOpenedOnboardingCards)
  public setOpenedOnboardingCards(
    { patchState }: StateContext<UserInfoModel>,
    {}: UserInfoModel
  ) {
    return this.userService.setOpenedOnboardingCards().pipe(
      tap((resp) => {
        patchState(resp.body);
      })
    );
  }

  @Action(GetUserInfo)
  public getUserInfo(
    { patchState, getState }: StateContext<UserInfoModel>,
    {}: UserInfoModel
  ) {
    this.userService.getUserInfo().subscribe((resp) => {
      this.mixpanelService.identify(resp.body.phone_number);
      this.mixpanelService.set({
        phone_number: resp.body.phone_number,
        name: resp.body.first_name + ' ' + resp.body.last_name,
        email: resp.body.email,
      });
      patchState(resp.body);
    });
  }

  @Action(UpdateUserInfo)
  updateUserInfo(
    { patchState }: StateContext<UserInfoModel>,
    { userInfo }: UpdateUserInfo
  ) {
    return this.userService.updateUserInfo(userInfo).pipe(
      tap((res) => {
        showToast(this.snackbar, 'تم تغيير البيانات الشخصية بنجاح', true, 6000);
        let seller = res.body.seller;
        let userInfo: UserInfoModel = {
          first_name: seller.name,
          seller_id: seller.id,
          last_name: '',
          phone_number: seller.user.username,
          email: seller.user.email,
          image: '',
          opened_onboarding_cards: seller.opened_onboarding_cards,
          can_upload_files: seller.can_upload_files,
          request_upload_files: seller.request_upload_files,
          marketplace_id: seller.seller_marketplace,
        };
        patchState(userInfo);
      })
    );
  }
  @Action(ClearUserInfoState)
  clearUserInfoState({ setState }: StateContext<UserInfoModel>) {
    setState(UserInfoState.defaultState);
  }
  @Action(UpdateVisitedPageStatus)
  public updateVisitedPageStatus(
    { patchState, getState }: StateContext<UserInfoModel>,
    { page_name }: UpdateVisitedPageStatus
  ) {
    this.userService.updateVisitedPageStatus(page_name).subscribe((resp) => {
      patchState(resp.body);
    });
  }
}
