import { Component, Input, OnInit, NgZone, ViewChild } from '@angular/core';
import { Validators, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import {
  ConstantsFactory,
  MarketplaceConstants,
} from 'src/app/common/global-constants';
import { showToast } from 'src/app/core/services/snackbar/show-snackbar';
import {
  OrderCapture,
  OrderCaptureSection,
  OrderCaptureTokenBody,
  Variant,
} from 'src/app/shared/models/product.model';
import { ProductsService } from 'src/app/shared/services/products/products.service';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MarketplaceService } from 'src/app/shared/services/marketplace/marketplace.service';
import { CoreService } from 'src/app/shared/services/core/core.service';
import { MixpanelService } from 'src/app/mixpanel.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
@Component({
  selector: 'app-landing-page-generator',
  templateUrl: './landing-page-generator.component.html',
  styleUrls: ['./landing-page-generator.component.scss'],
})
export class LandingPageGeneratorComponent implements OnInit {
  @Input() variant: Variant;
  @Input() marketplaceID: string;
  @Input() orderCaptureData: OrderCapture;
  @Input() showBanner: boolean;
  loading: boolean = false
  orderCaptureSuccessLink: string = environment.baseOrderCaptureUrl + 'success'
  marketplaceConstants :MarketplaceConstants;
  shipmentFees : number;
  sections: UntypedFormArray;
  sectionsMediaType: string[] = [];
  sectionsMedia: string[] = [];
  mainImagesMedia: string[] = [];
  logoImageMedia: string = '';
  eventNameToMixpanel: string[] = [
    'OC-Add new Section',
    'OC-Click on create landing page',
  ];
  landingPageForm = this.builder.group({
    mainImage: this.builder.control('', Validators.required),
    subImage1: this.builder.control(''),
    subImage2: this.builder.control(''),
    subImage3: this.builder.control(''),
    subImage4: this.builder.control(''),
    logoImage: this.builder.control(''),
    productNameControl: this.builder.control('', Validators.required),
    sellingPriceControl: this.builder.control('', Validators.required),
    priceBeforeOfferControl: this.builder.control('', Validators.required),
    productDescriptionControl: this.builder.control(''),
    sectionsArray: this.builder.array([]),
    googleTagManagerControl: this.builder.control(''),
    FacebookPixelControl: this.builder.control(''),
    TiktokPixelControl: this.builder.control(''),
    pageName: this.builder.control("",[Validators.required, Validators.maxLength(200)]),
  });
  orderCaptureLink: string = '';
  editingMode: boolean = false;
  constructor(
    private productService: ProductsService,
    private coreService: CoreService,
    private marketplaceService: MarketplaceService,
    private snackbar: MatSnackBar,
    private builder: UntypedFormBuilder,
    private _ngZone: NgZone,
    private mixpanelService: MixpanelService,
    private storage: AngularFireStorage
  ) {}

  ngOnInit(): void {
    if(this.orderCaptureData)
    {
      this.editingMode = true;
      this.fillOrderCaptureData();
    }
    this.initFormValues();
    this.marketplaceConstants = ConstantsFactory.createConstants(
      this.marketplaceID
    );
    this.landingPageForm.valueChanges.subscribe(() => {
      document.getElementById('orderCaptureBTN').style.opacity = '1';
      document.getElementById('orderCaptureBTN').style.display = 'block';
      document.getElementById('orderCaptureLinkSection').style.display = 'none';
    });
  }
  
  ngAfterViewInit() {
    setTimeout(() => {
      document.getElementById("orderCapture").scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    },500);
  }

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }
  fillOrderCaptureData()
  {
    this.sections = this.landingPageForm.get('sectionsArray') as UntypedFormArray; // Initialize sections
    let s =  this.orderCaptureData.sections_data.length
    for (let i = 0; i< s; i++) {
      this.sections.push(
        this.builder.group({
          image: this.builder.control(this.orderCaptureData.sections_data[i].media_link),
          title: this.builder.control(this.orderCaptureData.sections_data[i].title),
          description: this.builder.control(this.orderCaptureData.sections_data[i].description),
          media_type: this.builder.control(this.orderCaptureData.sections_data[i].media_type),
          media_link: this.builder.control(this.orderCaptureData.sections_data[i].media_link),
        }))

      this.sectionsMediaType.push(this.orderCaptureData.sections_data[i].media_type)
      this.sectionsMedia.push(this.orderCaptureData.sections_data[i].media_link)
    }

      this.mainImagesMedia.push(this.orderCaptureData.main_image_link)
      this.mainImagesMedia.push(this.orderCaptureData.sub_image1_link)
      this.mainImagesMedia.push(this.orderCaptureData.sub_image2_link)
      this.mainImagesMedia.push(this.orderCaptureData.sub_image3_link)
      this.mainImagesMedia.push(this.orderCaptureData.sub_image4_link)
      this.logoImageMedia = this.orderCaptureData.logo_image_link;

      this.landingPageForm.get('mainImage').setValue(this.orderCaptureData.main_image_link);
      this.landingPageForm.get('subImage1').setValue(this.orderCaptureData.sub_image1_link);
      this.landingPageForm.get('subImage2').setValue(this.orderCaptureData.sub_image2_link);
      this.landingPageForm.get('subImage3').setValue(this.orderCaptureData.sub_image3_link);
      this.landingPageForm.get('subImage4').setValue(this.orderCaptureData.sub_image4_link);
      this.landingPageForm.get('logoImage').setValue(this.orderCaptureData.logo_image_link);
      this.landingPageForm.get('productNameControl').setValue(this.orderCaptureData.product_name);
      this.landingPageForm.get('sellingPriceControl').setValue(this.orderCaptureData.selling_price);
      this.landingPageForm.get('priceBeforeOfferControl').setValue(this.orderCaptureData.price_before_offer);
      this.landingPageForm.get('productDescriptionControl').setValue(this.orderCaptureData.product_description);   
      this.landingPageForm.get('googleTagManagerControl').setValue(this.orderCaptureData.google_tag_manager);
      this.landingPageForm.get('FacebookPixelControl').setValue(this.orderCaptureData.facebook_pixel);
      this.landingPageForm.get('TiktokPixelControl').setValue(this.orderCaptureData.tiktok_pixel);
      this.landingPageForm.get('pageName').setValue(this.orderCaptureData.order_capture_name);

      this.orderCaptureLink = this.orderCaptureData.order_capture_link;
  }

  initFormValues() {
    this.loading = true;
    this.marketplaceService.getShipmentFeesValues().subscribe((res)=>{
      this.shipmentFees = res.body.shipment_fees_values.reduce((a, b) => Math.max(a, b), -Infinity);
      if(!this.editingMode)
      {
        this.landingPageForm
          .get('productNameControl')
          .setValue(this.variant.product.name + ' ' + this.variant.name);
        this.landingPageForm
          .get('priceBeforeOfferControl')
          .setValue(
            this.variant.minimum_selling_price + this.shipmentFees +
              this.variant.minimum_selling_price * 0.25
          );
        this.landingPageForm
          .get('sellingPriceControl')
          .setValue(this.variant.minimum_selling_price+this.shipmentFees);
      }
      this.loading = false;
    });
  }
  sectionsFilesHandler(e: any, formGroup: any, mediaIndex: any): void {
    let image = e.target.files[0];
    if (image.size > 25 * 1024 * 1024) {
      if (image.type.indexOf('image') > -1) {
        alert('Image is too big! maximum size is 25 MB');
      } else if (image.type.indexOf('video') > -1) {
        alert('Video is too big! maximum size is 25 MB');
      }
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      formGroup
        .get('image')
        .setValue(reader.result, { emitModelToViewChange: true });
      if (image.type.indexOf('image') > -1) {
        this.sectionsMedia[mediaIndex] = image;
        this.sectionsMediaType[mediaIndex] = 'image';
        formGroup.get('media_type').setValue('image');
      } else if(image.type.indexOf('video')> -1){
        this.sectionsMedia[mediaIndex] = image;
        this.sectionsMediaType[mediaIndex] = 'video';
        formGroup.get('media_type').setValue('video');
      }
    };
  }
  FilesHandler(e: any, control: any, imageIndex: any): void {
    let image = e.target.files[0];
    if (image.size > 25 * 1024 * 1024) {
      alert('Image is too big! maximum size is 25 MB');
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      control.setValue(reader.result, { emitModelToViewChange: true });
      this.mainImagesMedia[imageIndex] = image;
    };
  }

  logoFileHandler(e: any, control: any) {
    let image = e.target.files[0];
    if (image.size > 25 * 1024 * 1024) {
      alert('Image is too big! maximum size is 25 MB');
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      control.setValue(reader.result, { emitModelToViewChange: true });
      this.logoImageMedia = image;
    };
  }

  clear(control: any, fileID: string): void {
    (<HTMLInputElement>document.getElementById(fileID)).value = '';
    control.setValue('', { emitModelToViewChange: true });
  }

  returnSectionsArray(): UntypedFormArray {
    return this.landingPageForm.get('sectionsArray') as UntypedFormArray;
  }

  createSection() {
    let sections = this.returnSectionsArray();
    this.notifyMixPanel(0);
    sections.push(
      this.builder.group({
        image: this.builder.control(''),
        title: this.builder.control(''),
        description: this.builder.control(''),
        media_type: this.builder.control(''),
        media_link: this.builder.control(''),
      })
    );
  }

  deleteSection(index :number){
    this.getSectionsArray().removeAt(index);
    this.sectionsMediaType.splice(index);
    this.sectionsMedia.splice(index);
  }

  getSectionsArray(): UntypedFormArray {
    return this.landingPageForm.get('sectionsArray') as UntypedFormArray;
  }

  validateFileType(e: Event, index: number, resource: string) {
    let file = (e.target as HTMLInputElement).files[0];
    if (!file) return;
    if (file.type.match('image.*'))
      alert('Only jpg/jpeg and png files are allowed!');
  }
  checkErrors(){
    let errors = ""
    if(!this.landingPageForm.get('pageName').valid){
      errors += " يجب وضع اسم صفحة الهبوط لا يزيد عن 200 حرف"
      errors += '\n'
    }
    if(!this.landingPageForm.get('productNameControl').valid){
      errors += "يجب وضع اسم المنتج"
      errors += '\n'
    }
    if (!this.landingPageForm.get('mainImage').valid) {
      errors += 'يجب وضع صورة رئيسية للمنتج';
      errors += '\n';
    }
    if (!this.landingPageForm.get('sellingPriceControl').valid) {
      errors += 'يجب وضع سعر المنتج بعد الخصم + مصاريف الشحن';
      errors += '\n';
    }
    if (!this.landingPageForm.get('priceBeforeOfferControl').valid) {
      errors += 'يجب وضع سعر المنتج قبل الخصم';
      errors += '\n';
    }
    if (
      this.landingPageForm.get('priceBeforeOfferControl').value <=
      this.landingPageForm.get('sellingPriceControl').value
    ) {
      errors += 'سعر البيع يجب ان يكون اقل من السعر قبل الخصم';
      errors += '\n';
    }
    if (
      this.landingPageForm.get('sellingPriceControl').value <
      this.variant.minimum_selling_price + this.shipmentFees
    ) {
      errors +=
        'سعر البيع يجب ان يكون اعلى من او يساوى ' +
        (this.variant.minimum_selling_price + this.shipmentFees);
      errors += '\n';
    }
    return errors;
  }
  async generateOrderCaptureLink() {
    this.notifyMixPanel(1);
    document.getElementById('orderCaptureBTN').style.opacity = '0.5';
    let errors = this.checkErrors();
    if (errors != '') {
      this.coreService.openAnyErrorMessageModal(errors, errors);
      return;
    }
    this.loading = true;
    let marketplace = null;
    if (this.marketplaceID == '1') {
      marketplace = {
        id: '1',
        name: 'Egypt',
        currency: 'EGP',
      };
    } else if (this.marketplaceID == '2') {
      marketplace = {
        id: '2',
        name: 'Saudi Arabia',
        currency: 'SAR',
      };
    }
    let resources = [];
    let mainLink = await this.uploadMediaFiles(this.mainImagesMedia[0]);
    resources.push(mainLink);
    let subImage1Link = await this.uploadMediaFiles(this.mainImagesMedia[1]);
    resources.push(subImage1Link);
    let subImage2Link = await this.uploadMediaFiles(this.mainImagesMedia[2]);
    resources.push(subImage2Link);
    let subImage3Link = await this.uploadMediaFiles(this.mainImagesMedia[3]);
    resources.push(subImage3Link);
    let subImage4ink = await this.uploadMediaFiles(this.mainImagesMedia[4]);
    resources.push(subImage4ink);
    let logoImageLink = await this.uploadMediaFiles(this.logoImageMedia);

    let sections = this.returnSectionsArray();
    let sectionsData = [];
    let s = sections.length;
    for (let i = 0; i < s; i++) {
      let section = sections.at(i);
      let sectionImage = await this.uploadMediaFiles(this.sectionsMedia[i]);
      section.get('media_link').setValue(sectionImage);
  
      let sectionData: OrderCaptureSection={
        title: section.get('title').value,
        description:section.get('description').value,
        media_link:section.get('media_link').value,
        media_type:section.get('media_type').value,
      }
      sectionsData.push(sectionData)
    }

    let tokenBody: OrderCaptureTokenBody = {
      product_name: this.landingPageForm.get('productNameControl').value,
      product_description: this.landingPageForm.get('productDescriptionControl')
        .value,
      price_before_offer: this.landingPageForm.get('priceBeforeOfferControl')
        .value,
      selling_price: this.landingPageForm.get('sellingPriceControl').value,
      resources: resources,
      sections: sectionsData,
      variant_sku_code: this.variant?.sku_code,
      google_tag_manager: this.landingPageForm
        .get('googleTagManagerControl')
        .value.split(' ')
        .join(''),
      facebook_pixel: this.landingPageForm
        .get('FacebookPixelControl')
        .value.split(' ')
        .join(''),
      tiktok_pixel: this.landingPageForm
        .get('TiktokPixelControl')
        .value.split(' ')
        .join(''),
      marketplace: marketplace,
      logo_image: logoImageLink,
      page_name: this.landingPageForm.get('pageName').value,
      is_active: true
    };
    if (this.editingMode)
    {
      tokenBody.token_key = this.orderCaptureData.token_key;
      this.productService.updateOrderCapture(tokenBody, 'edit_page').subscribe((res) => {
        this.orderCaptureLink = res.body.link;
        document.getElementById('orderCaptureBTN').style.display = 'none';
        document.getElementById('orderCaptureLinkSection').style.display =
          'flex';
        this.loading = false;
        showToast(
          this.snackbar,
          'تم استخراج الرابط الخاص بيك بنجاح',
          true,
          1000
        );
      },
      (err) => {
        showToast(this.snackbar, 'حدثت مشكلة  حاول مرة اخرى', false, 1000);
        this.loading = false;
      });
    }
    else
    {
      this.productService.generateOrderCaptureLink(tokenBody).subscribe((res) => {
        this.orderCaptureLink = res.body.link;
        document.getElementById('orderCaptureBTN').style.display = 'none';
        document.getElementById('orderCaptureLinkSection').style.display = 'flex';
        this.loading = false;
        showToast(this.snackbar, 'تم استخراج الرابط الخاص بيك بنجاح', true, 1000);
      },(err)=>{
        showToast(this.snackbar, 'حدثت مشكلة  حاول مرة اخرى', false, 1000);
        this.loading = false;
      });
    }
  }

  notifyMixPanel(eventId: number) {
    this.mixpanelService.track(this.eventNameToMixpanel[eventId], {
      Engagement: true,
    });
  }

  copy(value: string) {
    navigator.clipboard.writeText(value);
    showToast(this.snackbar, 'تم نسخ الرابط بنجاح', true, 1000);
  }

  
  async uploadMediaFiles(file: string){
    if(!file || file === '')
    {
      return ''
    }
    const isURL = typeof file === 'string' && (file.startsWith('http') || file.startsWith('https'));

  if (isURL) {
    return file;
  }
    const now = new Date();
    const filePath = '/' + this.variant?.sku_code + '_' + now;
    const task = this.storage.upload(filePath, file);

    const snapshot: any = await task;
    const downloadURL: string = await snapshot.ref.getDownloadURL();

    return downloadURL;
  }
}
