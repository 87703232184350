import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ErrorMessageComponent } from 'src/app/messages/error-message/error-message.component';
import { SuccessMessageComponent } from 'src/app/messages/success-message/success-message.component';
import { OnboardingCardsComponent } from 'src/app/common-components-module/onboarding-cards/onboarding-cards.component';
import { ProgressPopupComponent } from 'src/app/popup/progress-popup/progress-popup.component';
import { AchievementPopupComponent } from 'src/app/popup/achievement-popup/achievement-popup.component';
import { AchievementBonus, ProgressBonus } from '../../models/popups.model';
import { DropShippingCardComponent } from 'src/app/common-components-module/drop-shipping-card/drop-shipping-card.component';

@Injectable({
  providedIn: 'root',
})
export class CoreService {
  constructor(public dialog: MatDialog) { }

  openAnyErrorMessageModal(
    errorCode: number | string = 0,
    errorMessage: string = 'يرجى التحقق من الشبكة'
  ): void {
    if (errorCode == 401) return;

    this.dialog.open(ErrorMessageComponent, {
      panelClass: 'error-panel',
      width: '500px',
      data: {
        errorCode,
        request: errorMessage,
      },
      disableClose: false,
    });
  }

  openSuccessMessage(successMessage: any): void {
    this.dialog.open(SuccessMessageComponent, {
      panelClass: 'success-panel',
      width: '500px',
      data: {
        successMessage: successMessage,
      },
    });
  }

  b64DecodeUnicode(str: any) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(atob(str).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  openOnBoardingCards(): void {
    this.dialog.open(OnboardingCardsComponent, )
  }

  openProgressPopupDialog(data: ProgressBonus): void {
    this.dialog.open(ProgressPopupComponent, {
      data,
    });
  }

  openAchievementPopupDialog(data: AchievementBonus): void {
    this.dialog.open(AchievementPopupComponent, {
      data,
    });
  }

  openDropShippingCardsDialog() {
    this.dialog.open(DropShippingCardComponent, {
      data: {},
      panelClass: 'drop-shipping-card-container'
    })
  }
}
