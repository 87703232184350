import { ListHeader } from 'src/app/shared/service-helpers/filter-helper';
import { FilterValue } from 'src/app/shared/service-helpers/filter-helper';

export enum ProductActionTypes {
  GetVariants = '[Product] Get Variants',
  GetProducts = '[Product] Get Products',
  ClearState = '[Product] Clear State',
  GetFavoriteProducts = '[Products] Get Favorite Products',
  FavoriteProduct = '[Products] Favorite A Product',
  UnfavoriteProduct = '[Products] Unfavorite a product',
  ProductRequest = '[Products] seller requset a product',
  GetOrderCapturePages = '[Products] Get Seller Order Capture Pages',
  GetSellerLockedVariants = '[Products] Get Seller Locked Variants',
}
export class GetVariants {
  public static readonly type = ProductActionTypes.GetVariants;
  constructor(public header: ListHeader, public filterValues: FilterValue[]) {}
}

export class GetProducts {
  public static readonly type = ProductActionTypes.GetProducts;
  constructor(public header: ListHeader, public filterValues: FilterValue[]) {}
}

export class ClearProductsState {
  public static readonly type = ProductActionTypes.ClearState;
}

export class GetFavoriteProducts {
  public static readonly type = ProductActionTypes.GetFavoriteProducts;
  constructor(public header: ListHeader, public filterValues: FilterValue[]) {}
}

export class FavoriteProduct {
  public static readonly type = ProductActionTypes.FavoriteProduct;
  constructor(public defaultVariantId: string) {}
}

export class UnfavoriteProduct {
  public static readonly type = ProductActionTypes.UnfavoriteProduct;
  constructor(public defaultVariantId: string) {}
}

export class ProductRequest {
  public static readonly type = ProductActionTypes.ProductRequest;
  constructor(public productRequest: any) {}
}

export class GetOrderCapturePages{
  public static readonly type = ProductActionTypes.GetOrderCapturePages;
  constructor(public header: ListHeader, public filterValues: FilterValue[]) {}
}

export class GetSellerLockedVariants {
  public static readonly type = ProductActionTypes.GetSellerLockedVariants;
  constructor(public header: ListHeader, public filterValues: FilterValue[]) {}
}
