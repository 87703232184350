<div class="flex justify-between items-center">
  <!-- Left Side -->
  <div class="flex items-center">
    <mat-icon>done</mat-icon>
    <span class="mx-2 text-black">{{ data | translate }}</span>
  </div>
  <div>
    <button mat-icon-button (click)="dismiss()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
