<section class="update-modal flex flex-col justify-between">
  <h2 mat-dialog-title>يوجد تحديث جديد للتطبيق</h2>
  <mat-dialog-content class="mat-typography">
    <p>التطبيق يحتاج الي التحديث لكي تحصل علي أخر مميزات و الأصلاحات.</p>
    <p class="text-danger">
      تحذير! أي عمليه تقوم بيها حاليا قد تزول, التطبيق سوف يعيد التحميل من جديد.
    </p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-flat-button
      [mat-dialog-close]="true"
      cdkFocusInitial
      class="confirm-btn"
    >
      تحديث
    </button>
  </mat-dialog-actions>
</section>
