import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import {
  NavigationStart,
  Router,
  Event,
  ActivatedRoute,
} from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { TokenBody } from 'src/app/shared/models/login.model';
import { LoginState } from 'src/app/store/login/login.state';
import { LogoutAction } from 'src/app/store/login/login.action';
import { GetMarketplacesAction, SwitchMarketplaceAction } from 'src/app/store/marketplace/marketplace.actions';
import { GetCartItems, UpdateCartItem } from 'src/app/store/cart/cart.actions';
import { Observable, Subscription } from 'rxjs';
import { CartItemModel } from 'src/app/shared/models/cart.model';
import { MarketplaceModel } from 'src/app/shared/models/marketplace.models';
import { UntypedFormControl } from '@angular/forms';
import { Category, Variant } from 'src/app/shared/models/product.model';
import { MarketplaceConstants, ConstantsFactory } from 'src/app/common/global-constants';
import { MarketplaceService } from 'src/app/shared/services/marketplace/marketplace.service';
import { MixpanelService } from 'src/app/mixpanel.service';
import { initializeSortingMap, sortingTabs } from 'src/app/shared/helpers/filter.field.model';
import { ProductsService } from 'src/app/shared/services/products/products.service';
import { FilterValue, ListHeader } from 'src/app/shared/service-helpers/filter-helper';
import { SortingInterface } from 'src/app/home/home.component';
import { GetVariants } from 'src/app/store/products/products.actions';
import { environment } from 'src/environments/environment';


declare var webengage: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit {

  @Select((state: any) => state.UserInfo)
  userInfoObservable: Observable<any>;
  customizeAlkaseba = false;

  announcementBanner: boolean = false;
  announcementBannerText: string = "سيتم توقف توصيل الطلبات من يوم ١٨ حتى ٢٢ ابريل ليكون أول يوم توصيل ٢٣ ابريل";

  @ViewChild('sideNav') sideNav: MatSidenav;
  public firstName: string;
  public lastName: string;
  public image: string;
  public companyName: string;
  public picture: string;
  public tokenBody: TokenBody;
  public width: number = screen.width;
  // View States
  public viewWidth: number;
  public isDesktop: boolean;
  // Badge states
  public hideBadge: boolean;
  public badgeNumber: number;
  //Languager
  public lang: string = 'ar';

  //cart items count
  @Select((state: any) => state.Cart)
  cartItemsObservable: Observable<any>;
  public cartItemsCount: number;

  @Select((state: any) => state.Products.sellerHasLockedVariants)
  sellerHasLockedVariantsObservable: Observable<any>;

  public cartItems: CartItemModel[] = [];

  public active = true;

  @Select((state: any) => state.MarketplaceState)
  marketplaceObservable: Observable<any>;
  marketplaceObservableSubscription: Subscription;
  public marketplaces: MarketplaceModel[] = [];
  public selectedMarketplace: MarketplaceModel;
  public selectedMarketplaceMap = new Map<String, String>([
    ["Egypt", "مصر"],
    ["Saudi Arabia", "السعوديه"],
    ["Emirates", "الامارات"]
  ]);

  quantityDict = new Map<string, any>();


  sortingTabs = sortingTabs
  categories: Category[] = [];
  chosenCategoryID: string;
  categoryTabSelectedIndex: number = 0;
  sortTabSelectedIndex: number = 0;
  filterValues: FilterValue[];
  sortTabMap = new Map<number, SortingInterface>();
  listHeader: ListHeader = {
    per_page: 9999999,
    page: 1,
    ordering: 'product.rank',
    direction: 'desc',
  };
  loading: boolean = false;

  cartItemsObservableSubscription: Subscription;
  sellerHasLockedVariantsObservableSubscription: Subscription;
  public sellerHasLockedVariants: boolean;  

  //product name form control
  productNameControl: UntypedFormControl = new UntypedFormControl('');
  marketplaceConstants: MarketplaceConstants;
  isPageVisited: boolean;
  constructor(
    public router: Router,
    private store: Store,
    private route: ActivatedRoute,
    private mixpanelService: MixpanelService,
    private marketplaceService: MarketplaceService,
    private productsService: ProductsService,
  ) {

  }

  toggle() {
    this.active = !this.active;
  }

  ngOnInit(): void {
    this.subscribeUserInfo();
    this.getTokenBody();
    this.badgeNumber = 10;
    this.hideBadge = true;
    this.getCategories();
    initializeSortingMap(this.sortTabMap)
    this.subscribeCart();
    this.store.dispatch(new GetCartItems());
    this.subscribeQueryParams();
    this.subscribeMarketplace();
    this.getMarketplaces();
    this.getConstants();
    this.subscribeSellerHasLockedVariants();

    this.subscribeQueryParams();
    // Getting view width while init
    this.viewWidth = window.innerWidth;
    if (this.viewWidth > 770) {
      this.isDesktop = true;
    } else {
      this.isDesktop = false;
    }

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart && !this.isDesktop) {
        this.sideNav.close();
      }
    });
  }

  subscribeUserInfo() {
    this.userInfoObservable.subscribe((userInfo) => {
      if (environment.customizedAlkasebaPhoneNumber.includes(userInfo.phone_number)) {
        this.customizeAlkaseba = true;
      }
      this.firstName = userInfo.first_name
    })
  }

  logout(): void {
    this.mixpanelService.track('Click Logout');
    this.store.dispatch(new LogoutAction());
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.viewWidth = event.target.innerWidth;
    this.isDesktop = false;
    if (this.viewWidth > 770) {
      this.isDesktop = true;
    }
  }

  resetPosition() {
    const elm: any = document.getElementById('page-container');
    if (elm) {
      elm.scrollTop = 0;
      this.active = true;
    }
  }

  getTokenBody(): void {
    this.tokenBody = this.store.selectSnapshot(LoginState.getTokenBody);
    if (this.tokenBody) {
      this.firstName = this.tokenBody.first_name
        ? this.tokenBody.first_name
        : 'first name';
      this.lastName = this.tokenBody.last_name ? this.tokenBody.last_name : '';

      this.image =
        this.tokenBody.image !== ''
          ? this.tokenBody.image
          : '../../../assets/images/no-user-img.jpg';


      webengage.user.login(this.tokenBody.phone_number);
      webengage.user.setAttribute('we_first_name', this.tokenBody.first_name);
      webengage.user.setAttribute('we_phone', this.tokenBody.phone_number);
      webengage.user.setAttribute('we_email', this.tokenBody.email);

    }
  }

  getCategories() {
    this.productsService.getCategories().subscribe((res) => {
      this.categories = res.body.categories;
      this.selectCategoryTab();
    });
  }

  selectCategoryTab() {
    let chosenCategoryIndex = this.categories.findIndex(
      (category) => category.id == this.chosenCategoryID
    );
    //adding one to chosen category index to skip the first tab (كل المنتجات)
    this.categoryTabSelectedIndex = chosenCategoryIndex + 1;
  }

  categoryTabAction($event: any) {
    let categoryID: number = 0;
    let selectedCategory = this.categories[$event.value - 1];
    let categoryTitle = "All";
    if ($event.value) {
      categoryID = parseInt(this.categories[$event.value - 1].id);
    }
    if (selectedCategory) {
      categoryTitle = selectedCategory.name;
    }
    this.router.navigate(['/app', 'home'], {
      queryParams: {
        category: categoryID,
      },
      queryParamsHandling: 'merge',
    });
    this.mixpanelService.track('Category - Click - Nav', {
      'Category Name': this.categories[$event.value - 1].name,
      "Engagement": true
    });
  }

  tabListener($event: any) {
    this.router.navigate(['/app', 'home'], {
      queryParams: {
        sort: $event.value,
      },
      queryParamsHandling: 'merge',
    });
    this.mixpanelService.track('HP-Click Sort Button - Nav', {
      'Sort Title': this.sortingTabs[$event.value],
      Engagement: true,
    });
  }

  subscribeQueryParams() {
    this.route.queryParamMap.subscribe((params) => {
      let productName = params.get('productName');
      let category = parseInt(params.get('category'));
      let sort = parseInt(params.get('sort'));
      this.categoryTabSelectedIndex = 0;
      this.sortTabSelectedIndex = 0;
      this.filterValues = [
        {
          attribute: 'is_default_variant',
          value: true,
        },
      ];
      if (productName) {
        this.filterValues.push({
          attribute: 'product.name.icontains',
          value: productName,
        });
      }
      if (category) {
        this.filterValues.push({
          attribute: 'product.sub_category.category.id',
          value: category,
        });
        //setting chosenCategoryID with the id of category in the url
        //In order to choose it's corresponding tab once the categories are retrevied
        this.chosenCategoryID = category.toString();
        if (this.categories.length) {
          this.selectCategoryTab()
        }
      }
      if (this.sortTabMap.get(sort)) {
        this.listHeader.ordering = this.sortTabMap.get(sort).ordering;
        this.listHeader.direction = this.sortTabMap.get(sort).direction;
        //Selecting the sort tab index passed in the url
        this.sortTabSelectedIndex = sort;
      }
    });
  }

  getVariants() {
    this.loading = true;
    this.store
      .dispatch(new GetVariants(this.listHeader, this.filterValues))
      .subscribe((_) => {
        this.loading = false;
      });
  }

  subscribeCart() {
    this.cartItemsObservableSubscription = this.cartItemsObservable.subscribe(
      (cart) => {
        if (cart.cartItemsCount !== null) {
          this.cartItemsCount = 0
          cart.cartItems.forEach((cartItem: CartItemModel) => {
            this.cartItemsCount += cartItem.quantity
          })
          this.cartItems = cart.cartItems;
          this.generateQuantityDict();
        }
      }
    );
  }

  subscribeSellerHasLockedVariants() {
    this.sellerHasLockedVariantsObservableSubscription = this.sellerHasLockedVariantsObservable.subscribe(
      (sellerHasLockedVariants) => {
        if (sellerHasLockedVariants) {
          this.sellerHasLockedVariants = sellerHasLockedVariants;
        }
      }
    );
  }

  subscribeMarketplace() {
    this.marketplaceObservableSubscription = this.marketplaceObservable.subscribe(
      (state) => {
        this.marketplaces = state.marketplaces
        this.selectedMarketplace = state.selectedMarketplace
        if (this.marketplaces.length) {
          this.marketplaces = this.marketplaces.filter(marketplace => marketplace.name !== 'Emirates')
        }
      }
    );
  }

  quantityChangeListener($event: any) {
    let key = Array.from($event.keys())[0];
    const body = {
      quantity: $event.get(key),
    };
    this.store.dispatch(new UpdateCartItem(key, body)).subscribe();
  }

  generateQuantityDict(): any {
    for (let cartItem of this.cartItems) {
      this.quantityDict.set(cartItem.id.toString(), cartItem.quantity);
    }
  }

  ngOnDestroy() {
    this.cartItemsObservableSubscription.unsubscribe();
    this.sellerHasLockedVariantsObservableSubscription.unsubscribe();
  }

  onSearchProductsButtonClick($event: any) {
    this.mixpanelService.track('HEADER-Click Search', {
      'Searched Value': this.productNameControl.value,
      "Engagement": true
    })
    this.router.navigate(['/app', 'home'], {
      queryParams: {
        productName: this.productNameControl.value,
      },
      queryParamsHandling: 'merge',
    });
  }

  onClickCartIcon() {
    this.mixpanelService.track('HEADER-Click Cart', {
      "Engagement": true
    });
  }

  onNavHome() {
    this.mixpanelService.track('NAV-Click Home', {
      "Engagement": true
    });
  }

  onNavMyCart() {
    this.mixpanelService.track('NAV-Click Cart', {
      "Engagement": true
    });
  }

  onNavMyOrders() {
    this.mixpanelService.track('NAV- Click Orders', {
      "Engagement": true
    });
  }

  onNavMyMoney() {
    this.mixpanelService.track('NAV-Click Money', {
      "Engagement": true
    });
  }

  onNavMyProfile() {
    this.mixpanelService.track('NAV-Click My Profile', {
      "Engagement": true
    });
  }

  getQueryParams(variant: Variant) {
    let queryParams: any = {};

    if (variant.attribute1_value) {
      queryParams['att1'] = variant.attribute1_value;
    }
    if (variant.attribute2_value) {
      queryParams['att2'] = variant.attribute2_value;
    }
    if (variant.attribute3_value) {
      queryParams['att3'] = variant.attribute3_value;
    }

    return queryParams;
  }

  getMarketplaces() {
    this.store.dispatch(new GetMarketplacesAction());
  }

  onMarketplaceChange(value: string) {
    this.store.dispatch(new SwitchMarketplaceAction({ marketplace_id: value }));
  }

  getConstants() {
    var selectedMarkeplaceId: string = this.marketplaceService.getSelectedMarketplaceId()
    this.marketplaceConstants = ConstantsFactory.createConstants(selectedMarkeplaceId);
  }
}
