<app-loading [loading]="loading"> </app-loading>
<form class="page-details" [formGroup]="landingPageForm">
  <div
    class="discount-section"
    *ngIf="variant?.prepaid_discount && (editingMode || showBanner)"
  >
    <div class="discount-background">
      <div class="discount-content w-full flex">
        <div class="flex flex-col items-center justify-center w-11/12">
          <span class="flex-wrap flex w-11/12 items-center">
            سيتم تطبيق خصم
            {{ variant?.prepaid_discount }}
            {{
              marketplaceConstants ? marketplaceConstants.currencyInArabic : ""
            }}
            علي الطلب الذي قام العميل بدفعه الكترونيا من خلال صفحة الهبوط
          </span>
          <span class="seller-discount items-center w-10/12"
            >(مع العلم بان هذا الخصم لن يتم اقتطاعه من ارباحكم)</span
          >
        </div>
        <div class="w-2/12 flex items-center">
          <img src="../../../assets/images/discount.svg" alt="Debit_Card" />
        </div>
      </div>
    </div>
  </div>
  <p style="font-size: 16px; color: #000; font-weight: 600; text-align: center">
    هنا سوف تتمكن من عمل صفحة لاندنج بيدج احترافيه من خلال ادخال البيانات
    المطلوبه لكل سكشن لتصبح مطابقه للشكل.
  </p>
  <section class="main-section">
    <p style="color: #fb1b1c; font-size: 16px; font-weight: 700">
      -القسم الاول-
    </p>

    <div class="main-form-and-preview">
      <div class="main-form-section">
        <p
          style="
            font-size: 16px;
            color: #000;
            font-weight: 700;
            margin-bottom: 16px;
          "
        >
          البيانات الذي يجب ادخالها لتحصل علي صفحة احترافيه لهذا المنتج:
        </p>
        <div class="page-name-div">
          <label for="" style="padding-bottom: 5px"> اسم صفحة الهبوط </label>
          <label style="color: red; font-size: 13px">
            (يجب ان يكون الاسم غير مستخدم من قبل)
          </label>
          <mat-form-field appearance="fill">
            <input type="text" matInput formControlName="pageName" />
            <mat-error *ngIf="false"> يجب ادخال هذه الخانة </mat-error>
          </mat-form-field>
        </div>
        <div class="upload-pic" style="min-height: 100px">
          <div class="flex flex-row items-start w-11/12">
            <button
              (click)="
                clear(this.landingPageForm.get('logoImage'), 'fileLogoImage')
              "
            >
              X
            </button>
          </div>
          <button class="upload-btn" style="min-height: 100px">
            <input
              id="fileLogoImage"
              type="file"
              accept="image/*"
              (change)="
                logoFileHandler($event, this.landingPageForm.get('logoImage'))
              "
            />
            <div
              *ngIf="!this.landingPageForm.get('logoImage').value"
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100px;
                justify-content: space-evenly;
              "
            >
              <p class="number">1</p>
              <img
                src
                src="../../../assets/icons/Upload.svg"
                alt=""
                style="width: 35%"
              />
              <p>رفع اللوجو</p>
            </div>
            <img
              *ngIf="this.landingPageForm.get('logoImage').value"
              [src]="this.landingPageForm.get('logoImage').value"
              alt=""
              style="height: 100%; width: 100%; border-radius: 21px"
            />
          </button>
        </div>
        <div class="upload-pic">
          <div
            class="flex flex-row items-start w-11/12"
            style="margin-top: 5px"
          >
            <button
              (click)="
                clear(this.landingPageForm.get('mainImage'), 'fileMainImage')
              "
            >
              X
            </button>
          </div>
          <button class="upload-btn">
            <input
              id="fileMainImage"
              type="file"
              accept="image/*"
              (change)="
                FilesHandler($event, this.landingPageForm.get('mainImage'), 0)
              "
            />
            <div
              *ngIf="!this.landingPageForm.get('mainImage').value"
              style="
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 150px;
                justify-content: space-evenly;
              "
            >
              <p class="number">2</p>
              <img
                src
                src="../../../assets/icons/Upload.svg"
                alt=""
                style="width: 35%"
              />
              <p>رفع صورة</p>
            </div>
            <img
              *ngIf="this.landingPageForm.get('mainImage').value"
              [src]="this.landingPageForm.get('mainImage').value"
              alt=""
              style="height: 100%; width: 100%; border-radius: 21px"
            />
          </button>
        </div>
        <div class="flex gap-4">
          <div class="upload-pic">
            <div
              class="flex flex-row items-start w-11/12"
              style="margin-top: 5px"
            >
              <button
                (click)="
                  clear(this.landingPageForm.get('subImage1'), 'fileSubImage1')
                "
              >
                X
              </button>
            </div>
            <button class="upload-btn">
              <input
                id="fileSubImage1"
                type="file"
                accept="image/*"
                (change)="
                  FilesHandler($event, this.landingPageForm.get('subImage1'), 1)
                "
              />
              <div
                *ngIf="!this.landingPageForm.get('subImage1').value"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  height: 150px;
                  justify-content: space-evenly;
                "
              >
                <img
                  src
                  src="../../../assets/icons/Upload.svg"
                  alt=""
                  style="width: 35%"
                />
                <p>رفع صورة</p>
              </div>
              <img
                *ngIf="this.landingPageForm.get('subImage1').value"
                [src]="this.landingPageForm.get('subImage1').value"
                alt=""
                style="height: 100%; width: 100%; border-radius: 21px"
              />
            </button>
          </div>

          <div class="upload-pic">
            <div
              class="flex flex-row items-start w-11/12"
              style="margin-top: 5px"
            >
              <button
                (click)="
                  clear(this.landingPageForm.get('subImage2'), 'fileSubImage2')
                "
              >
                X
              </button>
            </div>
            <button class="upload-btn">
              <input
                id="fileSubImage2"
                type="file"
                accept="image/*"
                (change)="
                  FilesHandler($event, this.landingPageForm.get('subImage2'), 2)
                "
              />
              <div
                *ngIf="!this.landingPageForm.get('subImage2').value"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  height: 150px;
                  justify-content: space-evenly;
                "
              >
                <img
                  src
                  src="../../../assets/icons/Upload.svg"
                  alt=""
                  style="width: 35%"
                />
                <p>رفع صورة</p>
              </div>
              <img
                *ngIf="this.landingPageForm.get('subImage2').value"
                [src]="this.landingPageForm.get('subImage2').value"
                alt=""
                style="height: 100%; width: 100%; border-radius: 21px"
              />
            </button>
          </div>

          <div class="upload-pic">
            <div
              class="flex flex-row items-start w-11/12"
              style="margin-top: 5px"
            >
              <button
                (click)="
                  clear(this.landingPageForm.get('subImage3'), 'fileSubImage3')
                "
              >
                X
              </button>
            </div>
            <button class="upload-btn">
              <input
                id="fileSubImage3"
                type="file"
                accept="image/*"
                (change)="
                  FilesHandler($event, this.landingPageForm.get('subImage3'), 3)
                "
              />
              <div
                *ngIf="!this.landingPageForm.get('subImage3').value"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  height: 150px;
                  justify-content: space-evenly;
                "
              >
                <img
                  src
                  src="../../../assets/icons/Upload.svg"
                  alt=""
                  style="width: 35%"
                />
                <p>رفع صورة</p>
              </div>
              <img
                *ngIf="this.landingPageForm.get('subImage3').value"
                [src]="this.landingPageForm.get('subImage3').value"
                alt=""
                style="height: 100%; width: 100%; border-radius: 21px"
              />
            </button>
          </div>

          <div class="upload-pic">
            <div
              class="flex flex-row items-start w-11/12"
              style="margin-top: 5px"
            >
              <button
                (click)="
                  clear(this.landingPageForm.get('subImage4'), 'fileSubImage4')
                "
              >
                X
              </button>
            </div>
            <button class="upload-btn">
              <input
                id="fileSubImage4"
                type="file"
                accept="image/*"
                (change)="
                  FilesHandler($event, this.landingPageForm.get('subImage4'), 4)
                "
              />
              <div
                *ngIf="!this.landingPageForm.get('subImage4').value"
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  height: 150px;
                  justify-content: space-evenly;
                "
              >
                <img
                  src
                  src="../../../assets/icons/Upload.svg"
                  alt=""
                  style="width: 35%"
                />
                <p>رفع صورة</p>
              </div>
              <img
                *ngIf="this.landingPageForm.get('subImage4').value"
                [src]="this.landingPageForm.get('subImage4').value"
                alt=""
                style="height: 100%; width: 100%; border-radius: 21px"
              />
            </button>
          </div>
        </div>
        <label for="">
          <p class="number">3</p>
          اسم المنتج
        </label>
        <mat-form-field appearance="fill">
          <input type="text" matInput formControlName="productNameControl" />
          <mat-error *ngIf="false"> يجب ادخال هذه الخانة </mat-error>
        </mat-form-field>

        <label for="">
          <p class="number">4</p>
          سعر المنتج قبل الخصم
        </label>

        <mat-form-field appearance="fill">
          <input
            type="number"
            matInput
            formControlName="priceBeforeOfferControl"
          />
          <mat-error *ngIf="false"> يجب ادخال هذه الخانة </mat-error>
          <mat-error *ngIf="false">
            لا يمكن إدخال سعر أقل من أدني سعر بيع
          </mat-error>
        </mat-form-field>

        <label for="">
          <p class="number">5</p>
          سعر المنتج بعد الخصم
          <label style="color: red; font-size: 13px">
            (يجب ان يكون شامل سعر الشحن وهو {{ shipmentFees }}
            {{ marketplaceConstants.currencyInArabic }})
          </label>
        </label>
        <mat-form-field appearance="fill">
          <input type="text" matInput formControlName="sellingPriceControl" />
          <mat-error *ngIf="false"> يجب ادخال هذه الخانة </mat-error>
          <mat-error *ngIf="false">
            لا يمكن إدخال سعر أقل من أدني سعر بيع
          </mat-error>
        </mat-form-field>

        <label for="">
          <p class="number">6</p>
          وصف عام للمنتج
        </label>
        <mat-form-field appearance="fill">
          <textarea
            matInput
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            formControlName="productDescriptionControl"
            cdkAutosizeMinRows="1"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="main-form-section-preview">
        <p style="font-size: 16px; color: #000; font-weight: 700">
          سوف تظهر البيانات التي سوف تقوم بادخالها كالشكل التالي
        </p>

        <div class="preview">
          <div
            class="images-section"
            style="
              flex-direction: row;
              gap: 1px;
              margin-top: 0px;
              padding: 8px 5px 24px 24px;
              justify-content: center;
            "
          >
            <p class="number">1</p>
            <img
              class="small-images"
              style="gap: 0; max-width: 150px"
              *ngIf="this.landingPageForm.get('logoImage').value"
              [src]="this.landingPageForm.get('logoImage').value"
              alt="image"
            />
          </div>
          <div class="title-section">
            <h1 class="title-preview">
              {{ landingPageForm.get("productNameControl").value }}
            </h1>
            <p class="dots">----</p>
            <p class="number">3</p>
          </div>
          <div class="price-section">
            <p style="font-size: 22px; font-weight: 700; color: #fb1b1c">
              {{ landingPageForm.get("sellingPriceControl").value }}
              {{
                marketplaceConstants
                  ? marketplaceConstants.currencyInArabic
                  : ""
              }}
            </p>

            <p class="dots">----</p>
            <p class="number">5</p>

            <p
              style="
                text-decoration: line-through;
                font-size: 20px;
                font-weight: 700;
                color: #777777;
                margin-right: 35px !important;
              "
            >
              {{ landingPageForm.get("priceBeforeOfferControl").value }}
              {{
                marketplaceConstants
                  ? marketplaceConstants.currencyInArabic
                  : ""
              }}
            </p>

            <p class="dots">----</p>
            <p class="number">4</p>
          </div>

          <div class="images-section">
            <p class="number">2</p>
            <img
              class="main-image"
              *ngIf="this.landingPageForm.get('mainImage').value"
              [src]="this.landingPageForm.get('mainImage').value"
              alt="image"
            />
            <div class="small-images">
              <img
                class="main-image"
                *ngIf="this.landingPageForm.get('subImage1').value"
                [src]="this.landingPageForm.get('subImage1').value"
                alt="image"
              />

              <img
                class="main-image"
                *ngIf="this.landingPageForm.get('subImage2').value"
                [src]="this.landingPageForm.get('subImage2').value"
                alt="image"
              />

              <img
                class="main-image"
                *ngIf="this.landingPageForm.get('subImage3').value"
                [src]="this.landingPageForm.get('subImage3').value"
                alt="image"
              />

              <img
                class="main-image"
                *ngIf="this.landingPageForm.get('subImage4').value"
                [src]="this.landingPageForm.get('subImage4').value"
                alt="image"
              />
            </div>
          </div>
          <br />
          <div class="flex items-center">
            <p class="number">6</p>
            <p class="dots">----</p>
            <textarea
              style="width: 65%"
              readonly
              matInput
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              [value]="landingPageForm.get('productDescriptionControl').value"
              cdkAutosizeMinRows="1"
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="sections" formArrayName="sectionsArray">
    <div
      *ngFor="let banner of getSectionsArray().controls; let i = index"
      [formGroupName]="i"
    >
      <section>
        <section class="main-section">
          <p style="color: #fb1b1c; font-size: 16px; font-weight: 700">
            -قسم الشرح-
          </p>
          <div class="main-form-and-preview">
            <div class="main-form-section">
              <div class="upload-pic">
                <button class="upload-btn">
                  <div
                    class="flex flex-row items-start w-11/12"
                    style="margin-top: 5px; z-index: 5"
                  >
                    <button
                      (click)="
                        clear(
                          this.returnSectionsArray().at(i).get('image'),
                          'sectionImage' + i
                        )
                      "
                    >
                      X
                    </button>
                  </div>
                  <input
                    id="{{ 'sectionImage' + i }}"
                    type="file"
                    accept="image/*, video/*"
                    (change)="
                      sectionsFilesHandler(
                        $event,
                        returnSectionsArray().at(i),
                        i
                      )
                    "
                  />
                  <div
                    *ngIf="!this.returnSectionsArray().at(i).get('image').value"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      height: 150px;
                      justify-content: space-evenly;
                    "
                  >
                    <img
                      src
                      src="../../../assets/icons/Upload.svg"
                      alt=""
                      style="width: 35%"
                    />
                    <p>رفع صورة أو فيديو</p>
                  </div>
                  <img
                    *ngIf="
                      this.returnSectionsArray().at(i).get('image').value &&
                      this.sectionsMediaType[i] == 'image'
                    "
                    [src]="this.returnSectionsArray().at(i).get('image').value"
                    alt=""
                  />
                  <video
                    *ngIf="
                      this.returnSectionsArray().at(i).get('image').value &&
                      this.sectionsMediaType[i] == 'video'
                    "
                    [src]="this.returnSectionsArray().at(i).get('image').value"
                    alt=""
                  ></video>
                </button>
              </div>

              <label for="">
                <p class="number">2</p>
                عنوان القسم
              </label>
              <mat-form-field appearance="fill">
                <input type="text" matInput formControlName="title" />
              </mat-form-field>

              <label for="">
                <p class="number">3</p>
                الوصف
              </label>
              <mat-form-field appearance="fill">
                <textarea
                  matInput
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  formControlName="description"
                  cdkAutosizeMinRows="1"
                ></textarea>
              </mat-form-field>
            </div>
            <div class="main-form-section-preview">
              <div class="preview center-preview">
                <p class="number">1</p>
                <img
                  *ngIf="
                    this.returnSectionsArray().at(i).get('image').value &&
                    this.sectionsMediaType[i] == 'image'
                  "
                  [src]="this.returnSectionsArray().at(i).get('image').value"
                  alt="image"
                  style="max-width: 200px"
                />
                <video
                  *ngIf="
                    this.returnSectionsArray().at(i).get('image').value &&
                    this.sectionsMediaType[i] == 'video'
                  "
                  [src]="this.returnSectionsArray().at(i).get('image').value"
                  alt="video"
                  style="max-width: 200px"
                ></video>
                <br />
                <div class="title-section">
                  <p class="number">2</p>
                  <p class="dots">----</p>
                  <h1 class="title-preview" style="text-align: center">
                    {{ getSectionsArray().at(i).get("title").value }}
                  </h1>
                </div>
                <div class="flex items-center">
                  <p class="number">3</p>
                  <p class="dots">----</p>
                  <textarea
                    style="width: 65%"
                    readonly
                    matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    [value]="getSectionsArray().at(i).get('description').value"
                    cdkAutosizeMinRows="1"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-end">
            <button
              mat-fab
              (click)="deleteSection(i)"
              type="button"
              class="delete-button"
              aria-label=""
            >
              <mat-icon class="primary-application-color">delete</mat-icon>
            </button>
          </div>
        </section>
      </section>
    </div>
    <button
      style="margin: 25px 0px"
      (click)="createSection()"
      mat-flat-button
      class="primary-application-background-color secondary-application-color add-product-class-button font-semibold"
    >
      اضافة قسم اخر
    </button>
  </div>
  <div class="link-generation-section">
    <p class="title">اذا كان لديك كود Google Tag Manager من فضلك قم بادخاله:</p>
    <mat-form-field appearance="fill" class="GTM-field">
      <mat-label>كود حساب google tag manager</mat-label>
      <input type="text" matInput formControlName="googleTagManagerControl" />
    </mat-form-field>
    <p class="title">اذا كان لديك كود Facebook pixel من فضلك قم بادخاله:</p>
    <mat-form-field appearance="fill" class="GTM-field">
      <mat-label>كود حساب Facebook pixel</mat-label>
      <input type="text" matInput formControlName="FacebookPixelControl" />
    </mat-form-field>
    <p class="title">اذا كان لديك كود TikTok pixel من فضلك قم بادخاله:</p>
    <mat-form-field appearance="fill" class="GTM-field">
      <mat-label>كود حساب TikTok pixel</mat-label>
      <input type="text" matInput formControlName="TiktokPixelControl" />
    </mat-form-field>
    <button
      id="orderCaptureBTN"
      mat-flat-button
      type="submit"
      class="primary-application-background-color secondary-application-color add-product-class-button font-semibold link-generator-btn"
      (click)="generateOrderCaptureLink()"
    >
      إنشاء صفحة
    </button>
    <div
      id="orderCaptureLinkSection"
      style="display: none"
      class="links-section"
    >
      <label for="orderCaptureLinkSection">الرابط الخاص بيك:</label>

      <div class="order-capture-section">
        <input
          id="orderCaptureLink"
          class="link-container"
          type="text"
          readonly
          [value]="orderCaptureLink"
        />
        <div class="flex">
          <a
            mat-flat-button
            [href]="orderCaptureLink"
            class="primary-application-background-color secondary-application-color add-product-class-button font-semibold link-generator-btn"
            target="_blank"
            >فتح الرابط</a
          >
          <button
            (click)="copy(orderCaptureLink)"
            mat-flat-button
            class="primary-application-background-color secondary-application-color add-product-class-button font-semibold link-generator-copy-btn"
          >
            نسخ الرابط
          </button>
        </div>
      </div>

      <label for="succeslinkSection">رابط ال success :</label>

      <div id="succeslinkSection" class="order-capture-section">
        <input
          id="orderCaptureLink"
          class="link-container"
          type="text"
          readonly
          [value]="orderCaptureSuccessLink"
        />
        <div class="flex">
          <a
            mat-flat-button
            [href]="orderCaptureSuccessLink"
            class="primary-application-background-color secondary-application-color add-product-class-button font-semibold link-generator-btn"
            target="_blank"
            >فتح الرابط</a
          >
          <button
            (click)="copy(orderCaptureSuccessLink)"
            mat-flat-button
            class="primary-application-background-color secondary-application-color add-product-class-button font-semibold link-generator-copy-btn"
          >
            نسخ الرابط
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
