import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ListHeader,
  FilterValue,
  filterValuesHeaderToFilterURL,
  listToHTTPParams,
} from '../../service-helpers/filter-helper';
import { environment } from '../../../../environments/environment';
import {
  VariantResponse,
  SingleVariantResponse,
  SingleProductResponse,
  CategoryResponse,
  ProductSuggestionsResponse,
  ProductResponse,
  Variant,
  ProductRequestModel,
  ProductRequestResponse,
  OrderCaptureTokenBody,
  OrderCaptureGeneratorResponse,
  GetOrderCapturePagesResponse,
  OrderCaptureDeleteResponse,
} from '../../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient) { }

  getProductsVariants(
    filterValues: FilterValue[],
    header: ListHeader
  ): Observable<HttpResponse<VariantResponse>> {
    let params = listToHTTPParams(header);
    let filters = filterValuesHeaderToFilterURL(filterValues, header);

    return this.http.get<VariantResponse>(
      environment.baseUrl + `product/variants/${filters}`,
      { params, observe: 'response' }
    );
  }

  getSingleVariant(
    variantID: string
  ): Observable<HttpResponse<SingleVariantResponse>> {
    return this.http.get<SingleVariantResponse>(
      environment.baseUrl + `product/variants/${variantID}`,
      { observe: 'response' }
    );
  }

  getProduct(
    ProductID: string
  ): Observable<HttpResponse<SingleProductResponse>> {
    return this.http.get<SingleProductResponse>(
      environment.baseUrl + `product/products/${ProductID}`,
      { observe: 'response' }
    );
  }

  getProducts(
    filterValues: FilterValue[],
    header: ListHeader
  ): Observable<HttpResponse<ProductResponse>> {
    let params = listToHTTPParams(header);
    let filters = filterValuesHeaderToFilterURL(filterValues, header);

    return this.http.get<ProductResponse>(
      environment.baseUrl + `product/products/${filters}`,
      { params, observe: 'response' }
    );
  }

  getCategories(): Observable<HttpResponse<CategoryResponse>> {
    let header: ListHeader = {
      per_page: 50,
      ordering: 'name',
      page: 1,
      direction: 'asc',
    };
    let params = listToHTTPParams(header);
    return this.http.get<CategoryResponse>(
      environment.baseUrl + `product/category`,
      { params, observe: 'response' }
    );
  }

  getProductSuggestions(
    productName: string,
    limit: number = 10
  ): Observable<HttpResponse<ProductSuggestionsResponse>> {
    return this.http.get<ProductSuggestionsResponse>(
      environment.baseUrl +
      `product/product-name-listing/${productName}/${limit}`,
      { observe: 'response' }
    );
  }

  getFavoriteVariants(header: ListHeader, filterValues: FilterValue[]): Observable<HttpResponse<VariantResponse>> {
    let params = listToHTTPParams(header);
    let filters = filterValuesHeaderToFilterURL(filterValues, header);
    return this.http.get<VariantResponse>(
      `${environment.baseUrl}product/favorite-products/${filters}`,
      { params, observe: 'response' }
    )
  }

  favoriteProduct(defaultVariantId: string): Observable<HttpResponse<Variant>> {
    return this.http.post<Variant>(
      `${environment.baseUrl}product/favorite-products/`,
      { variant_id: defaultVariantId },
      { observe: 'response' }
    );
  }

  unfavoriteProduct(defaultVariantId: string) {
    return this.http.delete<Variant>(
      `${environment.baseUrl}product/favorite-products/${defaultVariantId}`,
      { observe: 'response' }
    )
  }

  productRequest(ProductRequest: ProductRequestModel): Observable<HttpResponse<ProductRequestResponse>> {
    return this.http.post<ProductRequestResponse>(
      `${environment.baseUrl}product/product-request/`,
      ProductRequest,
      { observe: 'response' }
    );
  }

  generateOrderCaptureLink(orderCaptureTokenBody: OrderCaptureTokenBody): Observable<HttpResponse<OrderCaptureGeneratorResponse>> {
    return this.http.post<OrderCaptureGeneratorResponse>(
      `${environment.baseUrl}product/order-capture-generator/`,
      orderCaptureTokenBody,
      { observe: 'response' }
    );
  }

  getBonuseVariants(filterValues: FilterValue[], header: ListHeader): Observable<HttpResponse<VariantResponse>> {
    let params = listToHTTPParams(header);
    let filters = filterValuesHeaderToFilterURL(filterValues, header);
    return this.http.get<VariantResponse>(
      `${environment.baseUrl}/product/variants/${filters}`,
      { params, observe: 'response' }
    )
  }

  getOrderCapturePages(filterValues: FilterValue[],
    header: ListHeader): Observable<HttpResponse<GetOrderCapturePagesResponse>> {
    let params = listToHTTPParams(header);
    let filters = filterValuesHeaderToFilterURL(filterValues, header);

    return this.http.get<GetOrderCapturePagesResponse>(
      environment.baseUrl + `product/order-capture-generator/${filters}`,
      { params, observe: 'response' }
    );
  }

  updateOrderCapture(orderCaptureTokenBody: OrderCaptureTokenBody, updateType: string): Observable<HttpResponse<OrderCaptureGeneratorResponse>> {
    return this.http.patch<OrderCaptureGeneratorResponse>(
      `${environment.baseUrl}product/order-capture-generator/`,
      {
        orderCaptureTokenBody,
        update_type: updateType
      },
      { observe: 'response' }
    );
  }

  deleteOrderCapture(token_key: string): Observable<HttpResponse<OrderCaptureDeleteResponse>> {
    return this.http.delete<OrderCaptureDeleteResponse>(
      `${environment.baseUrl}product/order-capture-generator/${token_key}`,
      { observe: 'response' }
    );
  }


  getLockedProductsVariants(
      filterValues: FilterValue[],
      header: ListHeader
    ): Observable<HttpResponse<VariantResponse>> {
      let params = listToHTTPParams(header);
      let filters = filterValuesHeaderToFilterURL(filterValues, header);
  
      return this.http.get<VariantResponse>(
        environment.baseUrl + `product/locked-products/${filters}`,
        { params, observe: 'response' }
      );
    }
  
}

