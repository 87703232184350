import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { GetOrderCapturePagesResponse, OrderCapture } from 'src/app/shared/models/product.model';
import { CoreService } from 'src/app/shared/services/core/core.service';
import { ProductsService } from 'src/app/shared/services/products/products.service';
import { GetOrderCapturePages } from './products.actions';
import { catchError, tap } from 'rxjs/operators';

export class OrderCapturePagesStateModel {
  orderCapturePages: OrderCapture[];
}

@State<OrderCapturePagesStateModel>({
  name: 'OrderCapturePages',
  defaults: OrderCapturePagesState.defaultState,
})
@Injectable()
export class OrderCapturePagesState {
  constructor(
    private coreService: CoreService,
    private productService: ProductsService,
  ) {}

  static defaultState: OrderCapturePagesStateModel = {
    orderCapturePages: [],
  };

  @Action(GetOrderCapturePages)
  getOderCapturePages(
    { patchState }: StateContext<OrderCapturePagesStateModel>,
    { header, filterValues }: GetOrderCapturePages
  ) {
    return this.productService.getOrderCapturePages(filterValues, header).pipe(
      tap((res) => {
        const requestBody = res.body as GetOrderCapturePagesResponse;

        if (res.status === 200) {
          patchState({
            orderCapturePages: requestBody.orderCapturePages,
          });
        }
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }
}
