import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { UserInfoModel } from '../../models/user.model';
import { GetPopupsResponse } from '../../models/popups.model';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {
  }

  getUserInfo(): Observable<HttpResponse<UserInfoModel>> {

    return this.http.get<UserInfoModel>(
      environment.baseUrl + `base/seller-info/`,
      { observe: 'response' }
    );
  }

  updateUserInfo(userinfo: UserInfoModel): Observable<HttpResponse<any>> {
    return this.http.patch<any>(
      environment.baseUrl + `base/sellers/${userinfo.seller_id}`,
      userinfo,
      {
        observe: 'response',
      }
    );
  }

  sendBulkOrdersRequest(): Observable<HttpResponse<any>> {
    return this.http.post<any>(
      environment.baseUrl + `base/seller-request-bulk-orders/`,
      { observe: 'response' }
    );
  }

  setOpenedOnboardingCards(): Observable<HttpResponse<UserInfoModel>> {
    return this.http.post<UserInfoModel>(
      environment.baseUrl + `base/onboarding-cards/`,
      {},
      { observe: 'response' }
    );
  }

  getPopups(): Observable<HttpResponse<GetPopupsResponse>> {
    return this.http.get<GetPopupsResponse>(
      environment.baseUrl + `base/popup/`,
      { observe: 'response' }
    );
  }

  updateVisitedPageStatus(page_name: string): Observable<HttpResponse<UserInfoModel>> {
    const params = new HttpParams().set('page_name', page_name)
    return this.http.post<UserInfoModel>(
      environment.baseUrl + `base/visited-page/`,
      {},
      { params, observe: 'response' }
    );
  }
}
