import {
  loginAction,
  LoginRegisteredUser,
  refreshTokenAction,
  LogoutAction,
  AuthorizeOTPSubmissionRequest,
} from './login.action';
import { LoginResponse, TokenBody } from 'src/app/shared/models/login.model';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { LoginService } from 'src/app/shared/services/login/login.service';
import { MarketplaceService } from 'src/app/shared/services/marketplace/marketplace.service';
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import jwt_decode from 'jwt-decode';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { MixpanelService } from 'src/app/mixpanel.service';
export interface LoginStateModel {
  access: string;
  refresh: string;
  tokenBody: TokenBody;
}

@State<LoginStateModel>({
  name: 'auth',
  defaults: {
    access: null,
    refresh: null,
    tokenBody: null,
  },
})
@Injectable()
export class LoginState {
  constructor(
    private loginService: LoginService,
    private router: Router,
    public authService: AuthService,
    private marketplaceService: MarketplaceService,
    private mixpanelService: MixpanelService
  ) {}

  @Selector()
  public static getAccessToken(state: LoginStateModel) {
    return state.access;
  }
  @Selector()
  public static getRefreshToken(state: LoginStateModel) {
    return state.refresh;
  }

  @Selector()
  public static getTokenBody(state: LoginStateModel) {
    return state.tokenBody;
  }

  @Action(loginAction)
  public authenticate(
    ctx: StateContext<LoginStateModel>,
    { payload }: loginAction
  ) {
    return this.loginService.authinticateSeller(payload).pipe(
      tap((res: HttpResponse<LoginResponse>) => {
        if (res.status === 200) {
          this.mixpanelService.track('LOGIN-Login Successfully');
          const tokenBody: TokenBody = jwt_decode(res.body.access) as TokenBody;
          ctx.setState({
            access: res.body.access,
            refresh: res.body.refresh,
            tokenBody: tokenBody,
          });
          if (this.marketplaceService.getSelectedMarketplaceId() === null) {
            this.marketplaceService.setSelectedMarketplaceId(
              tokenBody.selected_marketplace
            );
          }
        }
      }),
      catchError((err) => {
        this.mixpanelService.track('LOGIN-Login Failed');
        throw err;
      })
    );
  }
  @Action(refreshTokenAction)
  public refreshToken(
    ctx: StateContext<LoginStateModel>,
    { access }: LoginStateModel
  ) {
    ctx.patchState({ access });
  }

  @Action(LoginRegisteredUser)
  public loginRegisteredUser(
    ctx: StateContext<LoginStateModel>,
    { access, refresh }: LoginRegisteredUser
  ) {
    const tokenBody: TokenBody = jwt_decode(access) as TokenBody;
    return ctx.patchState({
      access,
      refresh,
      tokenBody,
    });
  }

  @Action(LogoutAction)
  public logoutUser(ctx: StateContext<LoginStateModel>, {}: LogoutAction) {
    sessionStorage.clear();
    ctx.setState({
      access: null,
      refresh: null,
      tokenBody: null,
    });
  }

  @Action(AuthorizeOTPSubmissionRequest)
  public resetPassword(
    ctx: StateContext<LoginStateModel>,
    { code }: AuthorizeOTPSubmissionRequest
  ) {
    return code;
  }
}
