import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MixpanelService } from 'src/app/mixpanel.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  alkasebaVersion = "v1.5.2"
  constructor(
    private mixpanelService: MixpanelService,
  ) {}

  ngOnInit(): void {
    this.subscribeUserInfo()
  }

  @Select((state: any) => state.UserInfo)
  userInfoObservable: Observable<any>;
  customizeAlkaseba = false;

  subscribeUserInfo() {
    this.userInfoObservable.subscribe((userInfo) => {
      if (environment.customizedAlkasebaPhoneNumber.includes(userInfo.phone_number))
      {
        this.customizeAlkaseba = true;
      }

    })
  }

  onClickHelpBook() {
    this.mixpanelService.track('SELLER-Click Help Book', {
      Engagement: true,
    });
  }

  onClickPrivacyPolicy() {
    this.mixpanelService.track('SELLER-Click Privacy Policy Button', {
      Engagement: true,
    });
  }

  onClickInstagramIcon() {
    this.mixpanelService.track('SELLER-Click Instagram Social Media', {
      Engagement: true,
    });
  }

  onClickYoutubeIcon() {
    this.mixpanelService.track('SELLER-Click Youtube Social Media', {
      Engagement: true,
    });
  }

  onClickFacebookIcon() {
    this.mixpanelService.track('SELLER-Click Facebook Social Media', {
      Engagement: true,
    });
  }
}
