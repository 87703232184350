import { Component, NgZone, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { DateHelper } from 'src/app/core/helpers/date.helper';
import { MixpanelService } from 'src/app/mixpanel.service';
import { IntroJSService } from 'src/app/shared/services/introjs/introjs.service';
import {
  GetUserInfo,
  SetOpenedOnboardingCards,
} from 'src/app/store/user-info/user-info.action';

@Component({
  selector: 'app-drop-shipping-card',
  templateUrl: './drop-shipping-card.component.html',
  styleUrls: ['./drop-shipping-card.component.scss'],
})
export class DropShippingCardComponent implements OnInit {
  icons: string[] = ['play'];
  DateHelper = DateHelper; // required for the HTML template
  video: any;
  play_icon: any;
  loading: boolean = true;
  subscriptions: Array<Subscription> = [];
  @Select((state: any) => state.UserInfo)
  userInfoObservable: Observable<any>;
  isPageVisited: boolean = true;

  constructor(
    private sanitizer: DomSanitizer,
    private ngZone: NgZone,
    private store: Store,
    private matIconRegistry: MatIconRegistry,
    private mixpanelService: MixpanelService,
    private dialogRef: MatDialogRef<DropShippingCardComponent>,
    private introJSService: IntroJSService
  ) {
    this.addIcons();
  }

  ngOnInit(): void {
    this.handelCardAnimation();
    this.subscribeUserInfo();
  }

  closeModal() {
    this.mixpanelService.track('OB-Cards-WatchLater', {
      Engagement: true,
    });
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  endModal(): void {
    this.mixpanelService.track('OB-Cards-Finish', {
      Engagement: true,
    });

    this.store.dispatch(new SetOpenedOnboardingCards()).subscribe((res) => {
      this.ngZone.run(() => {
        this.dialogRef.close();
      });
      this.store.dispatch(new GetUserInfo()).subscribe((res) => {
        this.introJSService.homePage(this.isPageVisited);
      });
    });
  }

  addIcons(): void {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `../../../../assets/icons/${icon}.svg`
        )
      );
    }
  }

  play(): void {
    if (this.video.paused) {
      this.video.play();
      this.play_icon.style.visibility = 'hidden';
    } else {
      this.video.pause();
      this.play_icon.style.visibility = 'visible';
    }
  }

  handelCardAnimation(): void {
    this.video = document.getElementById('video');
    this.play_icon = document.getElementById('play-icon');
    const step1 = document.getElementById('step1');
    const step2 = document.getElementById('step2');
    const step3 = document.getElementById('step3');
    const step1_number = step1.getElementsByClassName(
      'step-number'
    ) as HTMLCollectionOf<HTMLElement>;
    const step1_text = step1.getElementsByClassName(
      'step-text'
    ) as HTMLCollectionOf<HTMLElement>;
    const step2_number = step2.getElementsByClassName(
      'step-number'
    ) as HTMLCollectionOf<HTMLElement>;
    const step2_text = step2.getElementsByClassName(
      'step-text'
    ) as HTMLCollectionOf<HTMLElement>;
    const step3_number = step3.getElementsByClassName(
      'step-number'
    ) as HTMLCollectionOf<HTMLElement>;
    const step3_text = step3.getElementsByClassName(
      'step-text'
    ) as HTMLCollectionOf<HTMLElement>;
    this.video.addEventListener('canplay', () => {
      this.loading = false;
      let startPlayPromise = this.video.play();
      if (startPlayPromise !== undefined) {
        startPlayPromise
          .then(() => {
            // Start whatever you need to do only after playback
            // has begun.
          })
          .catch(() => {
            this.play_icon.style.visibility = 'visible';
          });
      }
    });
    this.video.addEventListener('waiting', () => {
      this.loading = true;
    });
    this.video.addEventListener('timeupdate', () => {
      if (this.video.currentTime > 16.825082) {
        step1.style.backgroundColor = '#FFFFFF';
        step1.style.border = '1px solid #D9D9D9';
        step1_number[0].style.borderColor = '#777777';
        step1_number[0].style.color = '#777777';
        step1_text[0].style.color = '#777777';

        step2.style.backgroundColor = '#FFFFFF';
        step2.style.border = '1px solid #D9D9D9';
        step2_number[0].style.borderColor = '#777777';
        step2_number[0].style.color = '#777777';
        step2_text[0].style.color = '#777777';

        //active
        step3.style.backgroundColor = '#F2F2F7';
        step3.style.border = 'none';
        step3_number[0].style.borderColor = '#FB1B1C';
        step3_number[0].style.color = '#FB1B1C';
        step3_number[0].style.borderRadius = '20px';
        step3_text[0].style.color = 'black';
        this.scrollToElement(step3_text[0]);
      } else if (this.video.currentTime > 7.980332) {
        step1.style.backgroundColor = '#FFFFFF';
        step1.style.border = '1px solid #D9D9D9';
        step1_number[0].style.borderColor = '#777777';
        step1_number[0].style.color = '#777777';
        step1_text[0].style.color = '#777777';
        //active
        step2.style.backgroundColor = '#F2F2F7';
        step2.style.border = 'none';
        step2_number[0].style.borderColor = '#FB1B1C';
        step2_number[0].style.color = '#FB1B1C';
        step2_text[0].style.color = 'black';
        this.scrollToElement(step2_text[0]);

        step3.style.backgroundColor = '#FFFFFF';
        step3.style.border = '1px solid #D9D9D9';
        step3_number[0].style.borderColor = '#777777';
        step3_number[0].style.color = '#777777';
        step3_text[0].style.color = '#777777';
      } else if (this.video.currentTime < 7.980332) {
        //active
        step1.style.backgroundColor = '#F2F2F7';
        step1.style.border = 'none';
        step1_number[0].style.borderColor = '#FB1B1C';
        step1_number[0].style.color = '#FB1B1C';
        step1_text[0].style.color = 'black';
        this.scrollToElement(step1_number[0]);

        step2.style.backgroundColor = '#FFFFFF';
        step2.style.border = '1px solid #D9D9D9';
        step2_number[0].style.borderColor = '#777777';
        step2_number[0].style.color = '#777777';
        step2_text[0].style.color = '#777777';

        step3.style.backgroundColor = '#FFFFFF';
        step3.style.border = '1px solid #D9D9D9';
        step3_number[0].style.borderColor = '#777777';
        step3_number[0].style.color = '#777777';
        step3_text[0].style.color = '#777777';
      }
    });
  }

  clickOnstep1(): void {
    this.video.currentTime = 0;
  }

  clickOnstep2(): void {
    this.video.currentTime = 13;
  }

  clickOnstep3(): void {
    this.video.currentTime = 20;
  }

  scrollToElement(element: any): void {
    element.scrollIntoViewIfNeeded({
      behavior: 'smooth',
      block: 'start',
      inline: 'start',
    });
  }

  subscribeUserInfo() {
    this.subscriptions.push(
      this.userInfoObservable.subscribe((userInfo) => {
        if (userInfo.visited_page) {
          this.isPageVisited = userInfo.visited_page?.home_page;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
