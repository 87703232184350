import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as introJs from 'node_modules/intro.js/intro.js';
import { MixpanelService } from 'src/app/mixpanel.service';
import { UpdateVisitedPageStatus } from 'src/app/store/user-info/user-info.action';

@Injectable({ providedIn: 'root' })
export class IntroJSService {
  constructor(
    private router: Router,
    private store: Store,
    private mixpanelService: MixpanelService
  ) {}
  introJS: any = null;
  mixpanelControl(page: string, event: string) {
    if (event == 'backward')
      this.mixpanelService.track(
        'OB- ToolTips- ' +
          page +
          '- ' +
          (this.introJS._currentStep + 2) +
          '- ' +
          event,
        {
          Engagement: true,
        }
      );
    else if (event == 'exit')
      this.mixpanelService.track(
        'OB- ToolTips- ' +
          page +
          '- ' +
          (this.introJS._currentStep + 1) +
          '- ' +
          event,
        {
          Engagement: true,
        }
      );
    else if (this.introJS._currentStep)
      this.mixpanelService.track(
        'OB- ToolTips- ' +
          page +
          '- ' +
          this.introJS._currentStep +
          '- ' +
          event,
        {
          Engagement: true,
        }
      );
  }
  homePage(visited: boolean = true) {
    if (!visited) {
      let isDone: boolean = false;
      this.store.dispatch(new UpdateVisitedPageStatus('home_page')).subscribe();
      this.introJS = introJs();
      this.introJS.setOptions({
        steps: [
          {
            element: '#mat-expansion-panel-header-0',
            intro:
              'علشان تبدأ رحلتك فى الكسيبة هناخدك فى جولة نعرفك فيها كل تفاصيل الموقع.',
            tooltipClass: 'customTooltip',
            position: 'left',
          },
          {
            element: document.querySelectorAll('#variant_0')[0],
            intro:
              'تقدر تضيف المنتج الي المفضلة من خلال الضغط علي ايقونة القلب.',
            tooltipClass: 'customTooltip',
            position: 'left',
          },
          {
            element: document.querySelectorAll('#variant_0')[0],
            intro:
              'بضغطة واحدة على المنتج تقدر تعرف كل تفاصيلة وكمان تضيفه للسلة.',
            tooltipClass: 'customTooltip',
            position: 'bottom',
          },
        ],
        nextLabel: 'التالى',
        prevLabel: 'السابق',
        doneLabel: 'التالى',
        dontShowAgainCookieDays: true,
        hidePrev: true,
        showBullets: false,
      });
      this.introJS.onbeforechange(async () => {
        return new Promise((resolve) => {
          setInterval(resolve, 600);
          if (this.introJS._currentStep === 0) {
            this.introJS.nextLabel = 'ابدأ';
          } else if (this.introJS._currentStep === 1) {
            this.introJS.nextLabel = 'التالى';
            let form = document.getElementById('variant_0');
            form &&
              form.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end',
              });
          }
        });
      });
      this.introJS.onafterchange(async () => {
        this.mixpanelControl('Home', this.introJS._direction);
      });
      this.introJS.oncomplete(async () => {
        let cart = document.getElementById('variant_0');
        let img_div =
          cart &&
          (cart.getElementsByClassName('img-container').item(0) as HTMLElement);
        let img_link = img_div && img_div.getElementsByTagName('a').item(0);
        img_link && img_link.click();
        isDone = true;
        this.mixpanelService.track('OB- ToolTips- Home- Done', {
          Engagement: true,
        });
      });
      this.introJS.onexit(async () => {
        if (!isDone) this.mixpanelControl('Home', 'exit');
      });
      this.introJS.start();
    }
  }

  productPage(
    visited: boolean = false,
    isCartPageVisited: boolean = true,
    resource_element: string = '#product-resource'
  ) {
    if (!visited) {
      let isDone: boolean = false;
      this.store
        .dispatch(new UpdateVisitedPageStatus('products_page'))
        .subscribe();
      this.introJS = introJs();
      this.introJS.setOptions({
        steps: [
          {
            element: document.querySelectorAll(resource_element)[0],
            intro: 'تقدر بضغطة واحدة تنزل صور وفيديوهات المنتج وتبدأ إعلاناتك.',
            tooltipClass: 'customTooltip',
            position: 'bottom',
          },
        ],
        nextLabel: 'التالى',
        doneLabel: 'التالى',
        prevLabel: 'السابق',
        showBullets: false,
        dontShowAgainCookieDays: true,
        hidePrev: true,
      });
      this.introJS.onbeforechange(async () => {
        return new Promise((resolve) => {
          setInterval(resolve, 600);
          if (this.introJS._currentStep == 0) {
            let add_to_cart = document.getElementById('add-to-cart');
            if (add_to_cart && this.introJS._options.steps.length < 2) {
              this.introJS._options.steps.push({
                element: document.querySelectorAll('#add-to-cart')[0],
                intro:
                  'من هنا تقدر تضيف منتجات أوردرك لسلة المشتريات وتروح بعدها للسلة تكمل أوردرك.',
                tooltipClass: 'customTooltip',
                position: 'left',
              });
              this.introJS._introItems.push({
                element: document.querySelectorAll('#add-to-cart')[0],
                intro:
                  'من هنا تقدر تضيف منتجات أوردرك لسلة المشتريات وتروح بعدها للسلة تكمل أوردرك.',
                tooltipClass: 'customTooltip',
                position: 'left',
              });
            }
          }
        });
      });
      this.introJS.onafterchange(async () => {
        this.mixpanelControl('Product', this.introJS._direction);
      });
      this.introJS.oncomplete(async () => {
        if (!isCartPageVisited) {
          let add_to_cart = document.getElementById('add-to-cart');
          add_to_cart && add_to_cart.click();
          this.router.navigate(['/app', 'cart']);
        }
        isDone = true;
        this.mixpanelService.track('OB- ToolTips- Product- Done', {
          Engagement: true,
        });
      });
      this.introJS.onbeforeexit(async () => {
        if (!isDone) this.mixpanelControl('Product', 'exit');
      });
      this.introJS.start();
    }
  }

  cartPage(
    visited: boolean = false,
    cart_element: string = '.cart-row',
    customer_info: string = '#customer-info-part'
  ) {
    if (!visited) {
      let isDone: boolean = false;
      this.store.dispatch(new UpdateVisitedPageStatus('cart_page')).subscribe();
      this.introJS = introJs();
      this.introJS.setOptions({
        steps: [
          {
            element: document.querySelectorAll(cart_element)[0],
            intro:
              'من هنا تقدر تعرف وتعدل فى تفاصيل منتجك زى سعر البيع والكمية وكمان ربحك.',
            tooltipClass: 'customTooltip',
            position: 'bottom',
          },
          {
            element: document.querySelectorAll(customer_info)[0],
            intro:
              'وهنا تقدر تملى كل بيانات عميلك علشان تضمن إن الطلب يوصل للعميل الصح.',
            tooltipClass: 'customTooltip',
            tooltipPosition: 'top',
          },
          {
            element: document.querySelectorAll('#purchase')[0],
            intro:
              'وأخيرا تقدر تكمل عملية الشراء من الزرار دة وأوردرك يوصل لعميلك صاحب البيانات.',
            tooltipClass: 'customTooltip',
            tooltipPosition: 'top',
          },
        ],
        nextLabel: 'التالى',
        prevLabel: 'السابق',
        doneLabel: 'أنهاء',
        scrollTo: 'tooltip',
        showBullets: false,
        hidePrev: true,
      });
      this.introJS.onbeforechange(async () => {
        return new Promise((resolve) => {
          setInterval(resolve, 600);
          if (this.introJS._currentStep === 1 && customer_info === '#NONE') {
            let form = document.getElementById('city');
            form &&
              form.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end',
              });
          }
          if (this.introJS._currentStep === 2) {
            let form = document.querySelectorAll('#purchase')[0];
            form &&
              form.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end',
              });
          }
        });
      });
      this.introJS.onafterchange(async () => {
        this.mixpanelControl('Cart', this.introJS._direction);
      });
      this.introJS.oncomplete(async () => {
        isDone = true;
        this.mixpanelService.track('OB- ToolTips- Product- Done', {
          Engagement: true,
        });
      });
      this.introJS.onexit(async () => {
        if (!isDone) this.mixpanelControl('Cart', 'exit');
      });
      this.introJS.start();
    }
  }
}
