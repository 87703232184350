import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, NgZone, OnInit, ViewChildren } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import { showToast } from 'src/app/core/services/snackbar/show-snackbar';
import { OTPRequest } from 'src/app/shared/models/login.model';
import { ArabicToEnglishNumbersService } from 'src/app/shared/services/arabic-to-english-numbers.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { CoreService } from 'src/app/shared/services/core/core.service';
import { AuthorizeOTPSubmissionRequest } from 'src/app/store/login/login.action';
import { OTPModalProps } from './utils';
import { MixpanelService } from 'src/app/mixpanel.service';

@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss']
})
export class OTPModalComponent implements OnInit {

  icons: string[] = ["otp-illustration"];
  props: OTPModalProps;

  OTP_LENGTH = 5;

  otpCells = ['input1', 'input2', 'input3', 'input4', 'input5'];

  invalidCodeEntered: boolean = false;
  otpFieldMixpanelTracked: boolean = false;
  otpSubmitButtonPressed: boolean = false;

  otpForm: UntypedFormGroup;

  otpCode: string;

  @ViewChildren('formRow') rows: any;

  loadingOTPProcessing: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<OTPModalComponent>,
    private sanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private ngZone: NgZone,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private store: Store,
    private coreService: CoreService,
    private arToEnNumTranslation: ArabicToEnglishNumbersService,
    private mixpanelService: MixpanelService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { 
    this.props = data;
    this.addIcons();
    this.otpForm = this.toFormGroup(this.otpCells);
    this.handleIOSPasteFromMessagesEvent();
  }

  handleIOSPasteFromMessagesEvent() {
    this.otpForm.valueChanges.subscribe(event => {
      const isIOS = navigator.userAgent.match(/ipad|ipod|iphone/i);
      if (isIOS) {
        for(let key of Object.keys(event)) {
          let currentForm = this.otpForm.get(key);
          if(currentForm.value.length > 1 && currentForm.value.length < 6) {
            this.validateAndSubmitOTPAutoInputData(currentForm.value);
            break;
          }
        }
      }
    }) 
  }

  translatePhoneNumberIfPossible(phone_number: string) {
    return this.arToEnNumTranslation.convertArabicNumStrToEnglishNumStr(phone_number);
  }

  onSubmit() {
    this.otpSubmitButtonPressed = true;
    this.mixpanelService.track('OTP - Submit');
    if (this.otpForm.invalid) {
      this.invalidCodeEntered = true;
    } else {
      this.otpCode = this.getOTPFormValue();
      const request: OTPRequest = {
        code: this.otpCode,
        scope: this.props.scope,
        phone_number: this.translatePhoneNumberIfPossible(this.props.phoneNumber)
      }
      this.loadingOTPProcessing = true;
      this.authService.submitOTP(request).subscribe(res => {
        this.loadingOTPProcessing = false;
         this.store.dispatch(new AuthorizeOTPSubmissionRequest(this.otpCode))
         this.mixpanelService.track('OTP - Success');
        this.closeModal();
      }, err => {
        this.mixpanelService.track('OTP - Failed');
        this.loadingOTPProcessing = false;
        if(err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            this.invalidCodeEntered = true;
          } else {
            this.coreService.openAnyErrorMessageModal(err.status, err.error.detail ?? "فشلت المحاولة.. حاول مرة أخري")
          }
        }
      });
    }
  }

  handleOTPInput($event: any) {
    $event.stopImmediatePropagation();
    $event.preventDefault();
    if($event.data?.length == this.OTP_LENGTH) {
      this.validateAndSubmitOTPAutoInputData($event.data);
    }
  }

  resendOTP() {
    this.mixpanelService.track('OTP - Send Again');
    this.loadingOTPProcessing = true;
    this.authService.resendOTP(this.props.phoneNumber, this.props.scope).subscribe(res => {
      showToast(this.snackbar, 'تم ارسال الكود مرة  أخري', true, 2000);
      this.loadingOTPProcessing = false;
    }, err => {
      this.coreService.openAnyErrorMessageModal(err.status, err.error.detail ?? "فشلت المحاولة.. حاول مرة أخري")
      this.loadingOTPProcessing = false;
    })
  }

  getOTPFormValue() {
    let value = ""
    for(let key of this.otpCells) {
      value += this.otpForm.controls[key].value;
    }
    return this.translatePhoneNumberIfPossible(value);
  }

  toFormGroup(elements: string[]) {
    const group: any = {};
    elements.forEach(key => {
      group[key] = new UntypedFormControl('', Validators.required);
    });
    return new UntypedFormGroup(group);
   }

  ngOnInit(): void {
  
  }

  addIcons(): void {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `../../../assets/icons/${icon}.svg`
        )
      );
    }
  }

  closeModal() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  closureListener() {
    this.dialogRef.afterClosed().subscribe((_) => {
      this.dialogRef = null;
    });
  }

  pasteOTP($event: ClipboardEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();
    let clipboardData = $event.clipboardData;
    const data = clipboardData.getData('text');
    this.validateAndSubmitOTPAutoInputData(data);
  }

  validateAndSubmitOTPAutoInputData(data: string) {
    if (this.OTPInputDataIsValid(data)) {
      for(let i = 0; i < data.length; i++) {
        this.otpForm.controls[this.otpCells[i]].setValue(data[i]);
        this.rows._results[i].nativeElement.focus();
      }
      this.onSubmit();
    }
  }

  // Validates that the clipboard data is a valid OTP to enter
  OTPInputDataIsValid(otp: string) {
    if (otp.length == this.OTP_LENGTH) {
      for(let c of otp) {
        if (isNaN(parseInt(c))) return false;
      }
      return true;
    }
    return false;
  }

  /**
   * Accepted `KeyboardEvent.Key` values for the otp form.
   */
  acceptedKeys = [
    // english numbers
    '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',

    // arabic number
    '\u0661', '\u0662', '\u0663', '\u0664', '\u0665', '\u0666', '\u0667', '\u0668', '\u0669', '\u0660',

    // backspace keycode
    'Backspace'
  ]

  /**
   * Accept `KeyboardEvent.Key` values for submitting the form and pasting to it only.
   */
  acceptedControlKeys = [
    'v', 'Control', 'Enter'
  ]

  // Allow pasting and clicking enter only
  otpCellkeyDownEvent($event: KeyboardEvent) {
    if (!this.otpFieldMixpanelTracked)
      {
        this.mixpanelService.track('OTP - Field');
        this.otpFieldMixpanelTracked = true;
      }
    if(!this.acceptedControlKeys.includes($event.key)) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

  keyUpEvent($event: KeyboardEvent, index: number, cell: string) {
    let pos = index;
    if(!this.acceptedKeys.includes($event.key)) return;
    this.invalidCodeEntered = false;
    if ($event.key === 'Backspace') {
      this.otpForm.controls[cell].setValue('');
      pos = index - 1;
    } else {
      this.otpForm.controls[cell].setValue($event.key);
      pos = index + 1;
    }

    if (pos > -1 && pos < this.otpCells.length ) {
      this.rows._results[pos].nativeElement.focus();
    }
   
  }

  ngOnDestroy(){
    if(!this.otpSubmitButtonPressed)
      this.mixpanelService.track('OTP - Outside');
  }
}
