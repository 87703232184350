import { Component, OnInit, Inject, NgZone, ViewChild, HostListener } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { delay } from 'rxjs/operators';
import { Category } from 'src/app/shared/models/product.model';
import { ProductsService } from 'src/app/shared/services/products/products.service';
import { MixpanelService } from 'src/app/mixpanel.service';
import { SetOpenedOnboardingCards } from "../../store/user-info/user-info.action";

@Component({
  selector: 'app-onboarding-cards',
  templateUrl: './onboarding-cards.component.html',
  styleUrls: ['./onboarding-cards.component.scss']
})
export class OnboardingCardsComponent implements OnInit {
  config: any;
  activeSlideIndex: number = 1;
  numberOfSlides: number = 3;
  icons: string[] = ["facebook-logo", "telegram"];
  completedOnboarding: boolean = false;
  viewWidth: number;
  isDesktop: boolean;
  dimensionsDict: any = {
    mobileHeight: '80%',
    mobileWidth: '95%',
    desktopHeight: '85%',
    desktopwidth: '50%'
  };
  categoryTabSelectedIndex: number = 0;
  categories: Category[];
  slidesData: any[] = [
    { imgPath: "../../assets/images/on-boarding-img-1.svg" },
    { imgPath: "../../assets/images/on-boarding-img-2.gif" },
    { imgPath: "../../assets/images/on-boarding-img-3.svg" }
  ];

  @ViewChild('slickModal', { static: false }) slickModal: any;

  constructor(
    private dialogRef: MatDialogRef<OnboardingCardsComponent>,
    private ngZone: NgZone,
    private sanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private store: Store,
    private productsService: ProductsService,
    private router: Router,
    private mixpanelService: MixpanelService,
    @Inject(MAT_DIALOG_DATA) public key: any
  ) {}

  ngOnInit(): void {
    this.mixpanelService.track(`ON-BOARDING-CARDS-Opened`);
    this.getCategories();
    this.addicons();

    this.config = {
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      dots: true,
      adaptiveHeight: true,
      draggable: true,
      pauseOnHover: true,
      pauseOnDotsHover: true,
      swipeToSlide: true,
      touchThreshold: 10,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: false,
          }
        }
      ]
    };

    // Getting view width while init
    this.viewWidth = window.innerWidth;
    this.determineScreenDimensions();
  }

  ngOnDestroy(): void {
    this.mixpanelService.track(`ON-BOARDING-CARDS-Closed on slide number ${this.activeSlideIndex}`);
    this.store.dispatch(new SetOpenedOnboardingCards()).subscribe();
  }

  closeModal() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }

  async onSlideChange(event: any) {
    await delay(100);
    this.activeSlideIndex = event.currentSlide + 1;
    this.mixpanelService.track(`ON-BOARDING-CARDS-Swiped on slide number ${this.activeSlideIndex}`);

    if (this.activeSlideIndex === this.numberOfSlides && !this.completedOnboarding) {
      this.completedOnboarding = true;
      this.mixpanelService.track(`ON-BOARDING-CARDS-Completed`);
    }
  }

  onNextButtonClick() {
    this.slickModal.slickNext();
  }

  onPreviousButtonClick() {
    this.slickModal.slickPrev();
  }

  addicons() {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `../../../assets/logos/${icon}.svg`
        )
      );
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.viewWidth = event.target.innerWidth;
    this.determineScreenDimensions();
  }

  getCategories() {
    this.productsService.getCategories().subscribe((res) => {
      this.categories = res.body.categories;
    });
  }

  categoryTabAction(event: any) {
    let categoryID: number = 0;
    let selectedCategory = this.categories[event.index - 1];
    let categoryTitle = "All";
    
    if (event) {
      categoryID = parseInt(this.categories[event.index - 1].id);
    }

    if (selectedCategory) {
      categoryTitle = selectedCategory.name;
    }

    this.router.navigate([], {
      queryParams: {
        category: categoryID,
      },
      queryParamsHandling: 'merge',
    });
  }

  categoryDropdownAction(value: any) {
    let categoryID: number = 0;

    if (value !== '0') {
      categoryID = parseInt(this.categories[value - 1].id);
    }

    this.router.navigate([], {
      queryParams: {
        category: categoryID,
      },
      queryParamsHandling: 'merge',
    });
  }

  determineScreenDimensions() {
    this.isDesktop = false;
    let width = this.dimensionsDict.mobileWidth;
    let height = this.dimensionsDict.mobileHeight;

    if (this.viewWidth > 770) {
      this.isDesktop = true;
      width = this.dimensionsDict.desktopwidth;
      height = this.dimensionsDict.desktopHeight;
    }

    this.dialogRef.updateSize(width, height);
  }

  onSocialMediaIconClick(socialMediaName: string) {
    this.mixpanelService.track(`ON-BOARDING-CARDS-Clicked on ${socialMediaName} logo`);
  }
}
