import { State, Action, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  GetCartItems,
  DeleteCartItem,
  UpdateCartItem,
  AddCartItem,
  ClearCartState,
} from './cart.actions';
import {
  CartItemModel,
  CartItemResponse,
} from 'src/app/shared/models/cart.model';
import { CartService } from 'src/app/shared/services/cart/cart.service';
export class CartStateModel {
  cartItems: CartItemModel[];
  cartItemsCount: number;
}

@State<CartStateModel>({
  name: 'Cart',
  defaults: CartState.defaultState,
})
@Injectable()
export class CartState {
  constructor(private cartService: CartService) {}

  static defaultState: CartStateModel = {
    cartItems: [],
    cartItemsCount: null,
  };

  @Action(GetCartItems)
  getCart(
    { patchState, getState }: StateContext<CartStateModel>,
    {}: GetCartItems
  ) {
    const stateModel = getState();
    return this.cartService.getCartItems().pipe(
      tap((res) => {
        const requestBody = res.body as CartItemResponse;

        if (res.status === 200) {
          patchState({
            cartItems: requestBody.cart_items,
            cartItemsCount: requestBody.cart_items.length,
          });
        }
      })
    );
  }

  @Action(DeleteCartItem)
  deleteCartItem(
    { patchState, getState }: StateContext<CartStateModel>,
    { cartItemId }: DeleteCartItem
  ) {
    const stateModel = getState();
    return this.cartService.deleteCartItem(cartItemId).pipe(
      tap((res) => {
        const requestBody = res.body as CartItemResponse;

        if (res.status === 200) {
          patchState({
            cartItems: requestBody.cart_items,
            cartItemsCount: requestBody.cart_items.length,
          });
        }
      })
    );
  }

  @Action(UpdateCartItem)
  updateCartItem(
    { patchState, getState }: StateContext<CartStateModel>,
    { cartItemId, body }: UpdateCartItem
  ) {
    const stateModel = getState();
    return this.cartService.updateCartItem(cartItemId, body).pipe(
      tap((res) => {
        const requestBody = res.body as CartItemResponse;

        if (res.status === 200) {
          patchState({
            cartItems: requestBody.cart_items,
            cartItemsCount: requestBody.cart_items.length,
          });
        }
      })
    );
  }

  @Action(AddCartItem)
  addCartItem(
    { patchState }: StateContext<CartStateModel>,
    { variantId, selling_price, quantity }: AddCartItem
  ) {
    return this.cartService
      .addCartItem(variantId, quantity, selling_price)
      .pipe(
        tap((res) => {
          const requestBody = res.body as CartItemResponse;
          patchState({
            cartItems: requestBody.cart_items,
            cartItemsCount: requestBody.cart_items.length,
          });
        })
      );
  }

  @Action(ClearCartState)
  clearCartState({setState }: StateContext<CartStateModel>) {
    setState(CartState.defaultState);
  }
}
