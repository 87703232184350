export enum CartActionTypes {
  GetCartItems = '[Cart] Get Cart Items',
  DeleteCartItem = '[Cart] Delete Cart Item',
  UpdateCartItem = '[Cart] Update Cart Item',
  AddCartItem = '[Cart] Add Cart Item',
  ClearState = "[Cart] Clear State"
}

export class GetCartItems {
  public static readonly type = CartActionTypes.GetCartItems;
  constructor() {}
}

export class DeleteCartItem {
  public static readonly type = CartActionTypes.DeleteCartItem;
  constructor(public cartItemId: string) {}
}

export class UpdateCartItem {
  public static readonly type = CartActionTypes.UpdateCartItem;
  constructor(public cartItemId: any, public body: any) {}
}

export class AddCartItem {
  public static readonly type = CartActionTypes.AddCartItem;
  constructor(
    public variantId: string,
    public quantity?: number,
    public selling_price?: number
  ) {}
}

export class ClearCartState {
  static readonly type = CartActionTypes.ClearState;
}
