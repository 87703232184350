import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { GetReturnOrderData } from './return-order.actions';
import { GetReturnOrdersResponse} from 'src/app/shared/models/orders.model';
import { OrdersService } from 'src/app/shared/services/orders/orders.service';
import { catchError, tap } from 'rxjs/operators';
import { CoreService } from 'src/app/shared/services/core/core.service';


export class ReturnOrderModel {
  returnOrder : GetReturnOrdersResponse
}

@State<ReturnOrderModel>({
  name: 'ReturnOrdersStateModel',
  defaults: ReturnOrderState.defaultState,
})

@Injectable()
export class ReturnOrderState {
    constructor(
        private ordersService: OrdersService,
        private coreService: CoreService  
        ) { }

    static defaultState: ReturnOrderModel ={
       returnOrder : {
         orders: [],
         meta: {
           total_results: 0,
           total_pages: 0,
           page: 0,
           per_page: 0,
         },
         total_outbound_shipment_fees: 0,
         total_return_orders_deduction: 0,
         total_return_shipment_fees: 0
       }
    }
    
    @Action(GetReturnOrderData)
    getReturnOrder(
        { patchState }: StateContext<ReturnOrderModel>,
        { outboundOrderId } : GetReturnOrderData
        ){
            return this.ordersService.getReturnOrdersDetailedData(outboundOrderId).pipe(
                tap((response)=>{
                    patchState(
                        {returnOrder : response.body}
                    )
                },
                catchError((err) => {
                  if (err.status === 0) {
                    this.coreService.openAnyErrorMessageModal();
                  } else {
                    this.coreService.openAnyErrorMessageModal(
                      err.status,
                      err.error.detail
                    );
                  }
                  throw err;
                })
                )
               
            )
        
    }
  @Selector()
  static getData(state: ReturnOrderModel) {
    return state.returnOrder;
  }
}

