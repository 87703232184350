// abstract classes cannot be instantiated
export abstract class DateHelper {
  public static monthsEn = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  public static monthsAr = [
    'يناير',
    'فبراير',
    'مارس',
    'أبريل',
    'مايو',
    'يونيو',
    'يوليو',
    'أغسطس',
    'سبتمبر',
    'أكتوبر',
    'نوفمبر',
    'ديسمبر',
  ];

  private constructor() {}

  public static _getCurrentMonthIndex(): number {
    const d = new Date();
    return d.getMonth();
  }

  public static getMonthArabic(): string {
    return this.monthsAr[this._getCurrentMonthIndex()];
  }

  public static getMonthEnglish() {
    return this.monthsEn[this._getCurrentMonthIndex()];
  }
}
