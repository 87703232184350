import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CartItemResponse } from '../../models/cart.model';
@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private http: HttpClient) {}

  getCartItems(): Observable<HttpResponse<CartItemResponse>> {
    return this.http.get<CartItemResponse>(
      environment.baseUrl + `product/cartitem/`,
      { observe: 'response' }
    );
  }

  deleteCartItem(
    cartItemId: string
  ): Observable<HttpResponse<CartItemResponse>> {
    return this.http.delete<CartItemResponse>(
      environment.baseUrl + `product/cartitem/${cartItemId}`,
      { observe: 'response' }
    );
  }

  updateCartItem(
    cartItemId: string,
    body = {}
  ): Observable<HttpResponse<CartItemResponse>> {
    return this.http.patch<CartItemResponse>(
      environment.baseUrl + `product/cartitem/${cartItemId}`,
      body,
      { observe: 'response' }
    );
  }

  addCartItem(variantId: string, quantity?: number, selling_price?: number) {
    let body = {
      variant: variantId,
      selling_price,
      quantity,
    };
    return this.http.post<CartItemResponse>(
      environment.baseUrl + `product/cartitem/`,
      body,
      { observe: 'response' }
    );
  }


}
