import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { ConstantsFactory, MarketplaceConstants } from 'src/app/common/global-constants';
import { MixpanelService } from 'src/app/mixpanel.service';
import { FilterField } from 'src/app/shared/helpers/filter.field.model';
import { ExportFilterValueWithType } from 'src/app/shared/models/orders.model';
import { FilterValue } from 'src/app/shared/service-helpers/filter-helper';
import { CoreService } from 'src/app/shared/services/core/core.service';
import { MarketplaceService } from 'src/app/shared/services/marketplace/marketplace.service';
import { OrdersService } from 'src/app/shared/services/orders/orders.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent implements OnInit {
  @Output() tabEmitter = new EventEmitter<number>();
  @Output() filterClickEmitter = new EventEmitter<Map<string, any>>();
  @Output() dropDownEmitter = new EventEmitter<any>();
  @Output() filterValuesEmitter = new EventEmitter<any>();
  @Input() tabList: string[];
  @Input() filterFields: FilterField[];
  @Input() enableSortLabel: boolean = false;
  @Input() disabled: boolean = false;
  @Input() selectedTab: number = 0;
  @Input() caller: string = '';

  clearClicked: Subject<void> = new Subject<void>();
  outputMap = new Map<string, any>();
  //boolean variables for clear button
  hasValues = false;
  isCleared: boolean = true;
  marketplaceConstants: MarketplaceConstants;
  marketplaceId: string;
  exportFilter: ExportFilterValueWithType;
  loading = false;

  constructor(    
    private marketplaceService: MarketplaceService,
    private ordersService: OrdersService,
    private coreService: CoreService,
    private mixpanelService: MixpanelService,
    ) {}

  ngOnInit(): void {
    this.marketplaceId = this.marketplaceService.getSelectedMarketplaceId();
    this.marketplaceConstants = ConstantsFactory.createConstants(
      this.marketplaceId.toString()
    );
  }

  // A tab is changed
  tabAction(tabIndex: number) {
    this.tabEmitter.emit(tabIndex);
  }

  // A filter field is changed
  filterFieldListener(filterValue: FilterValue) {
    if (!filterValue.value) this.outputMap.delete(filterValue.attribute);
    else
    {
      if (filterValue.attribute.includes('created_at.gte'))
      {
        let fromDate = this.convertDateTime(
          filterValue.value,
          '00',
          '00',
          '00'
        );
        this.outputMap.set(filterValue.attribute, fromDate);

      }
      else if (filterValue.attribute.includes('created_at.lte'))
      {
        let toDate = this.convertDateTime(
          filterValue.value,
          '23',
          '59',
          '59'
        );
        this.outputMap.set(filterValue.attribute, toDate);

      }
      else this.outputMap.set(filterValue.attribute, filterValue.value);
    }
    if (this.outputMap.size) this.hasValues = true;
    else this.hasValues = false;
    this.filterValuesEmitter.emit(filterValue);
  }

  // Filter button clicked
  onFilterClick() {
    this.filterClickEmitter.emit(this.outputMap);
    this.isCleared = false;
  }

  // Clear button clicked
  onClearClick() {
    if (!this.isCleared) {
      this.hasValues = false;
      this.outputMap = new Map<string, any>();
      this.clearClicked.next(); // emits clear to all filter fields
      this.filterClickEmitter.emit(this.outputMap);
      this.isCleared = true;
    } else {
      this.clearClicked.next();
      this.hasValues = false;
    }
  }
  //listiner on dropdown selection
  dropDownListener($event: any) {
    this.dropDownEmitter.emit($event);
  }

  convertDateTime(str: string, hour: any, time: string, sec: string) {
    /* handle time zone whereas the database time zone is GMT+0
    time zone and time zone come from filter is based on current marketplace */
    let date_ob = new Date(str);
    let string_hour = ''
    let CostumeTimeZone = +this.marketplaceConstants.timeOffset;
    if (hour === '00') {
      date_ob.setHours(date_ob.getHours() - CostumeTimeZone);
      hour = date_ob.getHours();
      if (hour.toString() === '0'){
        string_hour = hour.toString()
        hour = '0'+ string_hour 
      }
    } else {
      hour = +hour - CostumeTimeZone;
    }
    let date = ('0' + date_ob.getDate()).slice(-2);
    let month = ('0' + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();
    let all_dateTime =
      year + '-' + month + '-' + date + ' ' + hour + ':' + time + ':' + sec;
    return all_dateTime;
  }

  exportOrdersCSV()
  {
    this.mixpanelService.track('Export Order Click', {
      Engagement: true,
    });
    this.loading = true;
    let filters: FilterValue[] = [];
    const mapIter = this.outputMap.keys();

    for(let i=0;i<this.outputMap.size;i++ )
    {
      let attribute =  mapIter.next().value;
      let new_attribute = attribute;
      if (attribute === 'created_at.gte') new_attribute='from_date'
      else if (attribute === 'created_at.lte') new_attribute='to_date'
      else if (attribute === 'order_code.icontains') new_attribute = 'order_code'
      

      let filter: FilterValue= {
        attribute:new_attribute,
        value: this.outputMap.get(attribute),
      };
      
      filters.push(filter)
    }
    this.exportFilter = {
      table: 'order_seller',
      filters: filters,
    };

    this.ordersService
      .exportFilterOrdersCSV(
        'order_seller',
        this.exportFilter,
        this.marketplaceId
      )
      .subscribe(
        (blob) => {
          this.ordersService.createFakeAnchorForDownload(blob, 'order_seller');
          this.loading = false;
        },
        (error) => {
          this.coreService.openAnyErrorMessageModal(
            error.status,
            error.error.detail
          );
          this.loading = false;
        }
      );
  }
}
