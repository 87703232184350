<app-loading [loading]="loading"></app-loading>
<section class="carousel-container" *ngIf="variants.length > 0">
  <div class="headline-container">
    <h2 class="carousel-headline">{{ carouselHeadline }}</h2>
    <div *ngIf="seeAllUrl !== ''" class="flex">
      <button class="see-more-button" (click)="seeMore()">مشاهدة الكل</button>
    </div>
  </div>
  <div class="products-container">
    <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig"  dir="rtl" 
    [ngClass]="{ grab: variants.length > 1 }">
      <div ngxSlickItem *ngFor="let variant of variants" class="carousel-cell slick-slide-div">
        <app-product-card [variant]="variant"></app-product-card>
      </div>
    </ngx-slick-carousel>

    <div class="slick-buttons">
      <button (click)="slickModal.slickPrev()" class="button-arrow mr-1">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
      <button (click)="slickModal.slickNext()" class="button-arrow ml-1">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
    </div>
  </div>
</section>
