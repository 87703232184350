<div class="otp-main-div">
  <div class="logo-div">
    <img src="../../../assets/images/el-kasseba-logo.png" alt="el-kasseba-logo" class="logo-class" />
  </div>
  <div style="display: flex;
  flex-direction: column;
  padding-bottom: 25vh;
  justify-content: space-around;">
    <div class="otp-content-div">
      <div style="display: flex; justify-content: center">
        <div class="grid items-center justify-center" style="width: 80%">
          <p style="color: black;     text-align: center;">
            قم بادخال الكود المكون من 5 أرقام الذي تم ارساله الي الرقم
            {{ props?.phoneNumber }}
          </p>
        </div>
      </div>
      <div class="mx-2 grid items-center justify-center">
        <mat-icon class="illustration-icon" svgIcon="otp-illustration">
          otp-illustration
        </mat-icon>
      </div>

      <div class="grid items-center justify-center">
        <form (ngSubmit)="onSubmit()" [formGroup]="otpForm">
          <div class="flex" style="direction: ltr !important">
            <div *ngFor="let cell of otpCells; let i = index">
              <input autocomplete="one-time-code" autofocus maxlength="1" [ngStyle]="{
                    border: invalidCodeEntered
                      ? '1px solid #FB1B1C'
                      : '1px solid #777777'
                  }"  class="otp-cell" #formRow type="text" inputmode="numeric" formControlName="{{ cell }}"
                (input)="handleOTPInput($event)" (keyup)="keyUpEvent($event, i, cell)"
                (keydown)="otpCellkeyDownEvent($event)" pattern="[0-9]|[\u0660-\u0669]" (paste)="pasteOTP($event)" />
            </div>
          </div>
          <div style="height: 26px" class="flex justify-center">
            <p style="font-size: 11px; color: #fb1b1c; margin: 0" *ngIf="invalidCodeEntered">
              الكود الذي تم ادخاله غير صحيح
            </p>
          </div>
          <div>
            <div class="flex justify-center items-center">
              <div *ngIf="loadingOTPProcessing" style="height: 102px;">
                <mat-spinner diameter="30"></mat-spinner>
              </div>
            </div>
            <div *ngIf="!loadingOTPProcessing" class="grid items-center justify-center mb-2">
              <button type="submit" style="
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    background-color: white;
                    color: #FB1B1C;
                    padding: 10px 20px;
                    border-radius: 10px;
                    text-transform: capitalize;
                    border-radius: 10px;
                    border: 1px solid var(--primary, #FB1B1C);
                  ">
                تأكيد
              </button>
              <p (click)="resendOTP()" class="resend-otp-btn">
                ارسال الكود مرة أخري
              </p>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="back-button-div">
      <button (click)="goToRegisterPage()" class="back-button">
        الرجوع للصفحه السابقه
        <mat-icon svgIcon="arrow-left" style="width: 36px;
        height: 40px;">
          arrow-left
        </mat-icon>
      </button>
    </div>
  </div>
</div>