import {
  MobileWallet,
  BankAccount,
  OnlinePayment,
  MobileWalletsResponse,
  BankAccountsResponse,
  OnlinePaymentsResponse,
  CreateBankAccountResponse,
  CreateMobileWalletResponse,
  CreateOnlinePaymentsResponse,
} from './../../shared/models/profile.model';
import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { tap, catchError } from 'rxjs/operators';
import { ProfileActions } from './profile.actions';
import { ProfileService } from 'src/app/shared/services/profile/profile.service';
import { insertItem, patch } from '@ngxs/store/operators';
import { CoreService } from 'src/app/shared/services/core/core.service';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { showToast } from 'src/app/core/services/snackbar/show-snackbar';

export interface ProfileStateModel {
  bankAccounts: BankAccount[];
  mobileWallets: MobileWallet[];
  onlinePayments: OnlinePayment[];
}
@State<ProfileStateModel>({
  name: 'Profile',
  defaults: ProfileState.defaultState,
})
@Injectable()
export class ProfileState {
  constructor(
    private coreService: CoreService,
    private profileService: ProfileService,
    private snackbar: MatSnackBar
  ) {}

  static defaultState: ProfileStateModel = {
    bankAccounts: [],
    mobileWallets: [],
    onlinePayments: [],
  };

  @Action(ProfileActions.AddBankAccount)
  createBankAccount(
    { setState }: StateContext<ProfileStateModel>,
    { bankAccountRequest }: ProfileActions.AddBankAccount
  ) {
    return this.profileService.createBankAccount(bankAccountRequest).pipe(
      tap((res) => {
        showToast(this.snackbar, 'تم انشاء بيانات الدفع بنجاح', true, 6000);
        const response: CreateBankAccountResponse = res.body;
        setState(
          patch({
            bankAccounts: insertItem(response.bank_account),
          })
        );
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(ProfileActions.AddMobileWallet)
  createMobileWallet(
    { setState }: StateContext<ProfileStateModel>,
    { mobileWallet }: ProfileActions.AddMobileWallet
  ) {
    return this.profileService.createMobileWallet(mobileWallet).pipe(
      tap((res) => {
        showToast(this.snackbar, 'تم انشاء بيانات الدفع بنجاح', true, 6000);
        const response: CreateMobileWalletResponse = res.body;
        setState(
          patch({
            mobileWallets: insertItem(response.mobile_wallet),
          })
        );
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(ProfileActions.AddOnlinePayment)
  createOnlinePayment(
    { setState }: StateContext<ProfileStateModel>,
    { onlinePayment }: ProfileActions.AddOnlinePayment
  ) {
    return this.profileService.createOnlinePayment(onlinePayment).pipe(
      tap((res) => {
        showToast(this.snackbar, 'تم انشاء بيانات الدفع بنجاح', true, 6000);
        const response: CreateOnlinePaymentsResponse = res.body;
        setState(
          patch({
            onlinePayments: insertItem(response.online_payment),
          })
        );
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(ProfileActions.GetBankAccounts)
  getBankAccounts(
    { patchState }: StateContext<ProfileStateModel>,
    { header }: ProfileActions.GetBankAccounts
  ) {
    return this.profileService.getBankAccounts(header).pipe(
      tap((res) => {
        const bankAccountsRes: BankAccountsResponse = res.body;
        patchState({
          bankAccounts: bankAccountsRes.bank_accounts,
        });
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(ProfileActions.GetMobileWallets)
  getMobileWallets(
    { patchState }: StateContext<ProfileStateModel>,
    { header }: ProfileActions.GetMobileWallets
  ) {
    return this.profileService.getMobileWallets(header).pipe(
      tap((res) => {
        const response: MobileWalletsResponse = res.body;
        patchState({
          mobileWallets: response.mobile_wallets,
        });
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(ProfileActions.GetOnlinePayments)
  getOnlinePayments(
    { patchState }: StateContext<ProfileStateModel>,
    { header }: ProfileActions.GetOnlinePayments
  ) {
    return this.profileService.getOnlinePayments(header).pipe(
      tap((res) => {
        const response: OnlinePaymentsResponse = res.body;
        patchState({
          onlinePayments: response.online_payments,
        });
      }),
      catchError((err) => {
        if (err.status === 0) {
          this.coreService.openAnyErrorMessageModal();
        } else {
          this.coreService.openAnyErrorMessageModal(
            err.status,
            err.error.detail
          );
        }
        throw err;
      })
    );
  }

  @Action(ProfileActions.ClearProfileState)
  clearProfileState({ setState }: StateContext<ProfileStateModel>) {
    setState(ProfileState.defaultState);
  }
}
