import {
  BankAccount,
  CreateBankAccountResponse,
  CreateMobileWalletResponse,
  MobileWallet,
  MobileWalletsResponse,
  OnlinePayment,
  CreateOnlinePaymentsResponse,
  OnlinePaymentsResponse
} from './../../models/profile.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BankAccountsResponse } from '../../models/profile.model';
import {
  ListHeader,
  listToHTTPParams,
} from '../../service-helpers/filter-helper';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) { }

  createBankAccount(
    bankAccountRequest: BankAccount
  ): Observable<HttpResponse<CreateBankAccountResponse>> {
    return this.http.post<CreateBankAccountResponse>(
      environment.baseUrl + `finance/bankaccount/`,
      bankAccountRequest,
      { observe: 'response' }
    );
  }

  createMobileWallet(
    MobileWalletRequest: MobileWallet
  ): Observable<HttpResponse<CreateMobileWalletResponse>> {
    return this.http.post<CreateMobileWalletResponse>(
      environment.baseUrl + `finance/mobilewallet/`,
      MobileWalletRequest,
      { observe: 'response' }
    );
  }

  createOnlinePayment(
    OnlinePaymentAccRequest: OnlinePayment
  ): Observable<HttpResponse<CreateOnlinePaymentsResponse>> {
    return this.http.post<CreateOnlinePaymentsResponse>(
      environment.baseUrl + `finance/onlinepayment/`,
      OnlinePaymentAccRequest,
      { observe: 'response' }
    );
  }

  getBankAccounts(
    header: ListHeader
  ): Observable<HttpResponse<BankAccountsResponse>> {
    let params = listToHTTPParams(header);
    return this.http.get<BankAccountsResponse>(
      environment.baseUrl + `finance/bankaccount/`,
      { params, observe: 'response' }
    );
  }

  getMobileWallets(
    header: ListHeader
  ): Observable<HttpResponse<MobileWalletsResponse>> {
    let params = listToHTTPParams(header);
    return this.http.get<MobileWalletsResponse>(
      environment.baseUrl + `finance/mobilewallet/`,
      { params, observe: 'response' }
    );
  }

  getOnlinePayments(
    header: ListHeader
  ): Observable<HttpResponse<OnlinePaymentsResponse>> {
    let params = listToHTTPParams(header);
    return this.http.get<OnlinePaymentsResponse>(
      environment.baseUrl + `finance/onlinepayment/`,
      { params, observe: 'response' }
    );
  }
}
