import { LoginState } from './../../store/login/login.state';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { LoginService } from 'src/app/shared/services/login/login.service';
import { MarketplaceService } from 'src/app/shared/services/marketplace/marketplace.service';
import {
  LogoutAction,
  refreshTokenAction,
} from 'src/app/store/login/login.action';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(
    private store: Store,
    private loginService: LoginService,
    private router: Router,
    private marketplaceService: MarketplaceService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.get('noauth')) {
      request = request.clone({
        headers: request.headers.delete('noauth'),
      });
      request = request.clone({
        headers: request.headers.delete('Authorization').delete('Marketplace'),
      });
    } else {
      var token : string = this.store.selectSnapshot(LoginState.getAccessToken)
      var marketplaceId : string = this.marketplaceService.getSelectedMarketplaceId()
      if(token != null) {
        request = request.clone({
          headers: request.headers
            .set(
              'Authorization',
              `Bearer ${token}`
            )
        });
      }
      if(marketplaceId != null) {
        request = request.clone({
          headers: request.headers
            .set(
              'Marketplace',
              marketplaceId.toString()
            ),
        });
      }
      
    }
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return throwError(err);
          } else {
            return this.handle401Error(request, next);
          }
        }
        return next.handle(request);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.includes('/base/auth/refresh/')) {
      this.isRefreshing = false;
      this.store.dispatch(new LogoutAction());
      return throwError('failed to refresh auth');
    }

    if (
      request.url.includes('base/auth/') ||
      request.url.includes('base/signup-signin/') ||
      request.url.includes("submit-otp/")
    ) {
      let error = new HttpErrorResponse({
        error: 'Failed to authenticate',
        status: 401
      });
      
      return throwError(error);
    }

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this.loginService.refreshAccess(this.getRefreshToken()).pipe(
        switchMap((res) => {
          let token = res.body.access;
          this.isRefreshing = false;
          this.store.dispatch(new refreshTokenAction(token));
          this.refreshTokenSubject.next(token);
          return next.handle(this.addAuthenticationToken(request, token));
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((token) =>
          next.handle(this.addAuthenticationToken(request, token))
        )
      );
    }
  }
  getRefreshToken(): string {
    return this.store.selectSnapshot(LoginState.getRefreshToken);
  }

  getToken(): string {
    return this.store.selectSnapshot(LoginState.getAccessToken);
  }
  addAuthenticationToken(request: HttpRequest<unknown>, token: string) {
    // We clone the request, because the original request is immutable
    return request.clone({
      headers: request.headers
        .set('Authorization', `Bearer ${token}`)
    });
  }
}
