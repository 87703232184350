<mat-dialog-content class="main-div">
  <header>
    <div class="title-div">
      <button (click)="closeDialog()">
        <img src="../../../assets/images/close_product_request.svg" />
      </button>
      <p class="suggest-new-product-text">اقتراح منتج جديد</p>
    </div>
  </header>

  <form [formGroup]="productRequestForm">
    <div class="all-fields-div">
      <div class="left-main-div">
        <div class="row-div">
          <div class="sub-div-right">
            <label class="all-labels" for="categories">اختار القسم</label>
            <select
              class="form-right-component"
              formControlName="category_id"
              (change)="categoryFieldChanged()"
            >
              <option value=""></option>
              <option
                *ngFor="let category of categories"
                value="{{ category?.id }}"
              >
                {{ category.name }}
              </option>
            </select>
            <mat-error
              *ngIf="productRequestForm.get('category_id').hasError('required') && productRequestForm.get('category_id').touched"
              >يجب ادخال هذه الخانة
            </mat-error>
          </div>

          <div class="sub-div-left">
            <label class="all-labels">اسم المنتج</label>
            <input
              class="form-left-component"
              formControlName="name"
              (change)="productNameChanged()"
            />
            <mat-error
              *ngIf="productRequestForm.get('name').hasError('required') && productRequestForm.get('name').touched"
              >يجب ادخال هذه الخانة
            </mat-error>
          </div>
        </div>

        <div class="row-div">
          <div class="sub-div-right">
            <label class="all-labels">لينك لصفحة المنتج</label>
            <input
              class="form-right-component"
              formControlName="link"
              (change)="linkChanged()"
            />
            <mat-error
              *ngIf="productRequestForm.get('link').hasError('required') && productRequestForm.get('link').touched"
              >يجب ادخال هذه الخانة
            </mat-error>
          </div>

          <div class="sub-div-left">
            <label class="all-labels"
              >الكميه المتوقع بيعها
              <span style="color: #777777">(ارقام فقط)</span></label
            >
            <input
              class="form-left-component"
              formControlName="expectedVolume"
              type="number"
              (change)="expectedVolumeChanged()"
            />
            <mat-error
              *ngIf="productRequestForm.get('expectedVolume').hasError('required') && productRequestForm.get('expectedVolume').touched"
              >يجب ادخال هذه الخانة
            </mat-error>
          </div>
        </div>

        <div class="notes-div">
          <label class="all-labels">ملاحظات</label>
          <input
            class="notes-component"
            formControlName="notes"
            (change)="notesChanged()"
          />
        </div>
      </div>
      <div class="right-main-div">
        <div class="flex justify-center">
          <div class="upload-section">
            <button class="upload-btn">
              <input
                type="file"
                id="fileUpload"
                accept="image/*"
                (change)="filesHandler($event)"
              />
              <div class="image-upload-text-div">
                <img
                  src="../../../assets/icons/Upload.svg"
                  alt=""
                  class="upload-image"
                  *ngIf="!productImageSrc"
                />

                <img [src]="productImageSrc" alt="" *ngIf="productImageSrc" />
                <p class="image-upload-text" *ngIf="!productImageSrc">
                  صورة المنتج
                </p>
                <p class="image-upload-extension" *ngIf="!productImageSrc">
                  JPG,PNG
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="sub-div-right">
    <button
      class="submit-button"
      [disabled]="requestProductButtonClicked"
      (click)="
        requestProduct();
      "
      [ngStyle]="{ 'opacity' : requestProductButtonClicked ? 0.3 : 1  }"
    >
      ارسال الاقتراح
    </button>
  </div>
</mat-dialog-content>
