import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SectionsService } from './../../shared/services/sections/sections.service';
import { Variant } from 'src/app/shared/models/product.model';
import { BannerMedia, MediaTypeEnum } from 'src/app/shared/models/banner.model';

@Component({
  selector: 'app-section-page',
  templateUrl: './section-page.component.html',
  styleUrls: ['./section-page.component.scss'],
})
export class SectionPageComponent implements OnInit {
  loading: boolean = false;
  sectionId: string;
  sectionVariants: Variant[] = [];
  sectionHeadline: string = '';
  sliderMedia: BannerMedia[] = [];
  componentName: string = 'section-page';
  constructor(
    private route: ActivatedRoute,
    private sectionsService: SectionsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.sectionId = params['sectionId'];
      this.getSectionData(this.sectionId);
    });
  }

  getSectionData(sectionID: string) {
    this.loading = true;
    this.sectionsService.getSectionData(this.sectionId).subscribe(
      (res) => {
        if (res.body.section) {
          res.body.section.section_products.forEach((Product) => {
            if (Product.product.default_variant.id) {
              this.sectionVariants.push(Product.product.default_variant);
            }
            this.sectionHeadline = res.body.section.headline;
          });

          if (res.body.section.section_banners) {
            res.body.section.section_banners.forEach((banner) => {
              let bannerMedia: BannerMedia = {
                type: MediaTypeEnum.image,
                image_file: banner.image,
                url: banner.url,
              };
              this.sliderMedia.push(bannerMedia);
            });
          }
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.router.navigate(['/app', 'home']);
      }
    );
  }
}
