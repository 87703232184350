<div class="flex flex-col justify-between">
  <div class="error-logo items-center flex justify-center">
    <mat-icon>warning</mat-icon>
  </div>
  <textarea

    readonly
    style="cursor: default; margin-top : 10px ;font-weight: 500;"
    [value]="errorMessage"
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMinRows="1"
    class="text-center"
    [disabled]="true"
  ></textarea>
</div>
<div class="flex review">
  <button mat-stroked-button color="warn" (click)="closeModal()">CLOSE</button>
</div>
