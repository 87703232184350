import { Router, RoutesRecognized } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';

export class URLTracker {

    private static _instance: URLTracker;

    public current_url: string = ""
    public previous_url: string = ""
    private alreadyTracking: boolean = false
    private subscription: Subscription = null

    private constructor() {

    }

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }
    public subscribeUrl(router: Router) {

        if (!this.alreadyTracking) {
            this.alreadyTracking = true
            this.subscription = router.events
                .pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
                .subscribe((events: RoutesRecognized[]) => {
                    this.previous_url = events[0].urlAfterRedirects
                    this.current_url = events[1].urlAfterRedirects
                });

        }
        else {
            throw Error(" URLTracker.subscribeUrl() was already called")
        }

    }
    public unsubscribe() {
        if (this.subscription) {
            this.subscription.unsubscribe()
        }
    }
}
