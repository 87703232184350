import { Component, OnInit } from '@angular/core';
import { ProductRequestDialogComponent } from './product-request-dialog/product-request-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MixpanelService } from 'src/app/mixpanel.service';


@Component({
  selector: 'app-product-request',
  templateUrl: './product-request.component.html',
  styleUrls: ['./product-request.component.scss']
})
export class ProductRequestComponent implements OnInit {
  productName: string;
  productLink: string;
  productPrice: number;
  productExpectedVolume: number;
  productCategory: string;
  productPhoto: string;
  notes: string;
  
 
  width = screen.width;


  constructor(
    public dialog: MatDialog,
    private mixpanelService: MixpanelService,
    
  ) { }

  ngOnInit(): void {
  }

 

  openDialog()
  {
    this.mixpanelService.track('Product Request - Button', {
      "Engagement": true
    });

    if (this.width < 700) {
      this.dialog.open(ProductRequestDialogComponent, {
        width: ' 100%',
      });
    } else {
      this.dialog.open(ProductRequestDialogComponent, {
        width: '100%',
      });
    }

  }

  
}
