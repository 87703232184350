import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArabicToEnglishNumbersService {
  private arToEnMapping: Map<string, string> = new Map<string, string>([
    ['١', '1'],
    ['٢', '2'],
    ['٣', '3'],
    ['٤', '4'],
    ['٥', '5'],
    ['٦', '6'],
    ['٧', '7'],
    ['٨', '8'],
    ['٩', '9'],
    ['٠', '0'],
  ]);

  constructor() { }

  convertArabicNumStrToEnglishNumStr(numStr: string): string {
    if (numStr == null || numStr == undefined) {
      return '';
    }

    let convertedString = '';
    for (let i = 0; i < numStr.length; i++) {
      const mappedChar = this.arToEnMapping.get(numStr[i]);
      if (mappedChar != undefined) {
        convertedString += mappedChar;
      } else {
        convertedString += numStr[i];
      }
    }

    return convertedString;
  }
}
