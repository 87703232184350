import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BillingStats,
  CreatePaymentRequestHttpRequest,
  CreatePaymentRequestHttpResponse,
  GetVouchersResponse,
  PaymentRequestResponse,
} from '../../models/billing.model';
import { environment } from 'src/environments/environment';
import {
  FilterValue,
  ListHeader,
  filterValuesHeaderToFilterURL,
  listToHTTPParams,
} from '../../service-helpers/filter-helper';
import { ConversionRateHttpResponse } from './models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  constructor(private http: HttpClient) { }

  getPayments(
    header: ListHeader,
    filterValues: FilterValue[]
  ): Observable<HttpResponse<PaymentRequestResponse>> {
    let params = listToHTTPParams(header);
    let filters = filterValuesHeaderToFilterURL(filterValues, header);
    return this.http.get<PaymentRequestResponse>(
      environment.baseUrl + `finance/payment_request/${filters}`,
      { params, observe: 'response' }
    );
  }

  createPaymentRequest(
    paymentRequest: CreatePaymentRequestHttpRequest
  ): Observable<HttpResponse<CreatePaymentRequestHttpResponse>> {
    return this.http.post<CreatePaymentRequestHttpResponse>(
      environment.baseUrl + `finance/payment_request/`,
      paymentRequest,
      { observe: 'response' }
    );
  }

  getVouchers(
    header: ListHeader,
    filterValues: FilterValue[]
  ): Observable<HttpResponse<GetVouchersResponse>> {
    let params = listToHTTPParams(header);
    let filters = filterValuesHeaderToFilterURL(filterValues, header);
    return this.http.get<GetVouchersResponse>(
      environment.baseUrl + `finance/voucher/${filters}`,
      { params, observe: 'response' }
    );
  }

  getDeductions(
    header: ListHeader,
    filterValues: FilterValue[]
  ): Observable<HttpResponse<GetVouchersResponse>> {
    let params = listToHTTPParams(header);
    let filters = filterValuesHeaderToFilterURL(filterValues, header);
    return this.http.get<GetVouchersResponse>(
      environment.baseUrl + `finance/deduction/${filters}`,
      { params, observe: 'response' }
    );
  }


  getBillingStats(): Observable<HttpResponse<BillingStats>> {
    return this.http.get<BillingStats>(environment.baseUrl + `finance/stats/`, {
      observe: 'response',
    });
  }

  deletePaymentRequest(id: string | number): Observable<HttpResponse<any>> {
    return this.http.delete<any>(
      environment.baseUrl + `finance/payment_request/${id}`,
      {
        observe: 'response',
      }
    );
  }

  getConversionRate(fromCurrency: string, toCurrency?: string): Observable<ConversionRateHttpResponse> {
    let queryParams = `from_currency=${fromCurrency}`;
    if (toCurrency) {
      queryParams += `&to_currency=${toCurrency}`;
    }
    return this.http.get<ConversionRateHttpResponse>(
      `${environment.baseUrl}finance/convert-currency/?${queryParams}`,
      { observe: 'response' }
    ).pipe(map(item => item.body));
  }
}
