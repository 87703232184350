import { Component, HostListener, OnInit } from '@angular/core';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { PwaService } from '../core/services/pwa/pwa.service';
import { TokenBody } from '../shared/models/login.model';
import { environment } from 'src/environments/environment';
import { LogoutAction } from '../store/login/login.action';
import { Router } from '@angular/router';
import { StateClearerService } from '../shared/services/state-clearer.service';
import { URLTracker } from '../core/helpers/url.tracker';
import { GetUserInfo } from '../store/user-info/user-info.action';
import { ScreenSizeTracker } from '../core/helpers/screen-size.tracker';
import { MixpanelService } from '../mixpanel.service';
import {TranslateService} from "@ngx-translate/core";
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'seller-portal-ui';

  @Select((state: any) => state.auth.tokenBody)
  tokenObservable: Observable<TokenBody>;


  subscriptions: Array<Subscription> = [];

  logoutSuccessfulSubscription: Subscription;
  urlTracker:URLTracker

  screenSizeTracker = ScreenSizeTracker.Instance
  constructor(
    private pwaService: PwaService,
    private actions: Actions,
    private router: Router,
    private stateClearerService: StateClearerService,
    private mixpanelService: MixpanelService,
    private store:Store,
    private translate: TranslateService,
    private gtmService: GoogleTagManagerService,
  ) {

    this.gtmService.addGtmToDom();
    document.dir = 'rtl';
    this.pwaService.applyUpdate();
    this.mixpanelService.init(environment.mixpanelKey)
    this.urlTracker = URLTracker.Instance
    this.urlTracker.subscribeUrl(this.router)
    this.translate.addLangs(['ar-EG','ar-SA', 'ar-UAE'])
    this.translate.setDefaultLang('ar-EG');
  }

  ngOnInit() {
    this.getUserInfo()
    this.subscribeUserInfo()
    this.subscribeLogout();
    this.screenSizeTracker.updateScreenParams(window.innerWidth);
    this.getLocale()
    this.pwaService.applyUpdate();
  }

  customizeAlkaseba = false;
  @Select((state: any) => state.UserInfo)
  userInfoObservable: Observable<any>;

  subscribeUserInfo() {
    this.subscriptions.push(
      this.userInfoObservable.subscribe((userInfo) => {
          if (environment.customizedAlkasebaPhoneNumber.includes(userInfo.phone_number))
          {
            sessionStorage.removeItem('locale')
            sessionStorage.setItem("locale", 'ar-SA');
            this.customizeAlkaseba = true;
          }
      })
    );
  }

  async getLocale(){

    if (!sessionStorage.getItem("locale")) {
      await fetch("https://ipapi.co/json/")
        .then((response)=> {
          return response.json();
        })
        .then((data)=> {
          const lang = this.getLanguage(data)
          this.translate.use(lang)
          sessionStorage.setItem("locale", lang);
          if(this.customizeAlkaseba){
            sessionStorage.setItem("locale", 'ar-SA');
          }
        })
    } else {
      this.translate.use(sessionStorage.getItem("locale"))
      if(this.customizeAlkaseba){
        sessionStorage.setItem("locale", 'ar-SA');
      }
    }
  }

  getLanguage(data: any){
    const lang = data.languages.split(",")[0]
    const langs = this.translate.getLangs()
    return langs.includes(lang) ? lang : this.translate.getDefaultLang()
  }
  subscribeLogout() {
    this.logoutSuccessfulSubscription = this.actions.pipe(ofActionSuccessful(LogoutAction)).subscribe(_ => {
      this.router.navigate(['/login']).then(_ => {
        this.stateClearerService.clearAllState();
      });
    })
  }


  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    this.logoutSuccessfulSubscription.unsubscribe();
    this.urlTracker.unsubscribe()
  }


  getUserInfo(){
    this.store.dispatch (new GetUserInfo).subscribe()
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.screenSizeTracker.updateScreenParams(event.target.innerWidth);
  }

}
