import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Select } from '@ngxs/store';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Variant } from 'src/app/shared/models/product.model';
import {
  FilterValue,
  ListHeader,
} from 'src/app/shared/service-helpers/filter-helper';
import { ProductsService } from 'src/app/shared/services/products/products.service';
import { Observable, Subscription } from 'rxjs';
import { MarketplaceConstants, ConstantsFactory } from 'src/app/common/global-constants';
import { MarketplaceModel } from 'src/app/shared/models/marketplace.models';
@Component({
  selector: 'app-product-dropdown-search',
  templateUrl: './product-dropdown-search.component.html',
  styleUrls: ['./product-dropdown-search.component.scss'],
})
export class ProductDropdownSearchComponent implements OnInit {
  queryFormControl = new UntypedFormControl('');
  loading: boolean;
  variants: Variant[];
  marketplaceConstants: MarketplaceConstants;
  showDropdown: boolean = false;
  @Select((state: any) => state.MarketplaceState.selectedMarketplace)
  selectedMarketplaceObservable: Observable<MarketplaceModel>;
  subscriptionArray: Subscription[] = []
  @Output() selectedVariant = new EventEmitter<any>();
  constructor(
    private productsService: ProductsService,
    private _eref: ElementRef,
  ) {}

  ngOnInit(): void {
    this.subscribeObservables()
  }
  ngAfterViewInit() {
    this.queryFormControl.valueChanges
      .pipe(debounceTime(750), distinctUntilChanged())
      .subscribe((val) => {
        if (this.queryFormControl.value) {
          this.variants = [];
          this.showDropdownMenu();
          this.getProducts(val);
        }
      });
  }
  ngOnDestroy(): void {
    this.subscriptionArray.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  
  }

  subscribeObservables() {
    this.subscriptionArray.push(this.selectedMarketplaceObservable.subscribe(
      (marketplace) => (
        marketplace.id !== ''
          ? (this.marketplaceConstants = ConstantsFactory.createConstants(
              marketplace.id.toString()
            ))
          : (this.marketplaceConstants = null)
      )
    ))
  }
  getProducts(name: string) {
    this.loading = true;
    let filterValues: FilterValue[] = [
      {
        attribute: 'product.name.icontains',
        value: name,
      },
      {
        attribute: 'is_active',
        value: true,
      },
      {
        attribute: 'is_private',
        value: false,
      },
    ];
    let listHeader: ListHeader = {
      per_page: 15,
      page: 1,
      ordering: 'id',
      direction: 'asc',
    };

    this.productsService
      .getProductsVariants(filterValues, listHeader)
      .subscribe((res) => {
        this.variants = res.body.variants;
        this.loading = false;
      });
  }
  showDropdownMenu() {
    this.showDropdown = true;
  }
  hideDropdownMenu() {
    this.showDropdown = false;
    this.queryFormControl.reset();
    this.variants = [];
  }
  @HostListener('document:click', ['$event'])
  clickOutside(event: any) {
    if (!this._eref.nativeElement.contains(event.target)) {
      this.hideDropdownMenu();
    }
  }

  onProductRowClick(variant: Variant) {
    this.selectedVariant.emit(variant);
  }
}
