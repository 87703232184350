import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

//import { auth } from 'firebase';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResetPasswordPayload } from '../../models/auth.models';
import { OTPRequest } from '../../models/login.model';
import { getAuth, getRedirectResult, GoogleAuthProvider } from "firebase/auth";
import { OTPProps } from 'src/app/common-components-module/otp/utils';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public props: OTPProps;

  constructor(
    public auth: AngularFireAuth, // Inject Firebase auth service
    public http: HttpClient
  ) {
  }

  // Sign in with Google popup
  GoogleAuthPopUp() : Promise<firebase.auth.UserCredential> {
    let provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    provider.addScope('https://www.googleapis.com/auth/user.phonenumbers.read');
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

    return this.auth.signInWithPopup(provider);
  }

  // Sign in with Facebook popup
  FacebookAuthPopUp() {
    let provider = new firebase.auth.FacebookAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  }

  GoogleAuthWithRedirect() {
    let provider = new firebase.auth.GoogleAuthProvider();
    return from(firebase.auth().signInWithRedirect(provider));
  }

  FacebookAuthWithRedirect() {
    let provider = new firebase.auth.FacebookAuthProvider();
    return from(firebase.auth().signInWithRedirect(provider));
  }

  getFirebaseRedirectResult() {
    const auth = getAuth();
    return from(getRedirectResult(auth));
  }
  fetchSignInMethodsForEmail(email: string) {
    return firebase.auth().fetchSignInMethodsForEmail(email);
  }


  registerFirebaseAuthedUser(registerRequest: any): Observable<HttpResponse<any>> {
    let url = `${environment.baseUrl}base/signup-signin/`;
    let headers: HttpHeaders = new HttpHeaders().append("noauth", "true");
    return this.http.post<any>(url, registerRequest, {observe: 'response', headers: headers});
  }

  submitOTP(otpRequest: OTPRequest): Observable<HttpResponse<any>> {
    let url = `${environment.baseUrl}base/submit-otp/`;
    let headers: HttpHeaders = new HttpHeaders().append("noauth", "true");
    return this.http.post<any>(url, otpRequest, {observe: 'response', headers: headers });
  }

  resendOTP(phoneNumber: string, scope: string): Observable<HttpResponse<any>> {
    let url = `${environment.baseUrl}base/resend-otp/`;
    let headers: HttpHeaders = new HttpHeaders().append("noauth", "true");
    const requestBody = {
      phone_number: phoneNumber,
      scope: scope
    };
    return this.http.post<any>(url, requestBody, { observe: 'response', headers });
  }

  resetPassword(resetPasswordPayload: ResetPasswordPayload): Observable<HttpResponse<any>> {
    let url = `${environment.baseUrl}base/reset-password/`;
    let headers: HttpHeaders = new HttpHeaders().append("noauth", "true");
    return this.http.post<any>(url, resetPasswordPayload, {observe: 'response', headers });
  }
}
