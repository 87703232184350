import { HttpParams } from '@angular/common/http';

export interface FilterValue {
  attribute: string;
  value: any;
}

export interface ListHeader {
  ordering: string;
  page: number;
  per_page: number;
  direction: string;
}

export interface Meta {
  total_results: number;
  total_pages: number;
  page: number;
  per_page: number;
}

export function listToHTTPParams(header: ListHeader): HttpParams {
  const params = new HttpParams()
    .set('page', header.page.toString())
    .set('per_page', header.per_page.toString());
  return params;
}

export function filterValuesHeaderToFilterURL(
  filterValues: FilterValue[],
  header: ListHeader
): string {
  let filters: string = `?sort[]=${header.direction === 'asc' ? '' : '-'}${
    header.ordering
  }`;
  if (filterValues)
  {
    for (let value of filterValues) {
      if (Array.isArray(value.value)) {
        value.value.forEach((v) => {
          filters += `&filter{${value.attribute}.in}=${encodeURIComponent(v)}`;
        });
      } else {
        filters += `&filter{${value.attribute}}=${encodeURIComponent(value.value)}`;
      }
    }
  }
  return filters;
}
export function filterToFilterURL(
  filterValues: FilterValue[]
): string {
  let filters: string = `?`;
  for (let value of filterValues) {
    if (Array.isArray(value.value)) {
      value.value.forEach((v) => {
        filters += `&filter{${value.attribute}.in}=${encodeURIComponent(v)}`;
      });
    } else {
      filters += `&filter{${value.attribute}}=${encodeURIComponent(value.value)}`;
    }
  }
  return filters;
}