import { ListHeader } from 'src/app/shared/service-helpers/filter-helper';
import {
  BankAccount,
  MobileWallet,
  OnlinePayment,
} from '../../shared/models/profile.model';

export enum ProfileActionTypes {
  AddBankAccount = '[Profile] Create Bank Account',
  AddMobileWallet = '[Profile] Create Mobile Wallet',
  AddOnlinePayment = '[Profile] Create Online Payment Account',
  GetBankAccounts = '[Profile] Get Bank Accounts',
  GetMobileWallets = '[Profile] Get Mobile Wallets',
  GetOnlinePayments = '[Profile] Get Online Payment Accounts',
  UpdateSeller = '[Profile] Update Seller',
  ClearProfileState = '[Profile] Clear State',
}
export namespace ProfileActions {
  export class AddBankAccount {
    static readonly type = ProfileActionTypes.AddBankAccount;
    constructor(public bankAccountRequest: BankAccount) {}
  }

  export class AddMobileWallet {
    static readonly type = ProfileActionTypes.AddMobileWallet;
    constructor(public mobileWallet: MobileWallet) {}
  }

  export class AddOnlinePayment {
    static readonly type = ProfileActionTypes.AddOnlinePayment;
    constructor(public onlinePayment: OnlinePayment) {}
  }

  export class GetBankAccounts {
    static readonly type = ProfileActionTypes.GetBankAccounts;
    constructor(public header: ListHeader) {}
  }

  export class GetMobileWallets {
    static readonly type = ProfileActionTypes.GetMobileWallets;
    constructor(public header: ListHeader) {}
  }

  export class GetOnlinePayments {
    static readonly type = ProfileActionTypes.GetOnlinePayments;
    constructor(public header: ListHeader) {}
  }
  export class ClearProfileState {
    static readonly type = ProfileActionTypes.ClearProfileState;
  }
}
