import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Variant } from 'src/app/shared/models/product.model';
import { SlickCarouselComponent } from 'ngx-slick-carousel';

@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss'],
})
export class ProductCarouselComponent implements OnInit {
  @Input() carouselHeadline: string;
  @Input() variants: Variant[] = [];
  @Input() seeAllUrl: string = '';
  loading = false;
  slideConfig = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: false,
    rtl: true,
    arrows: true,
    dots: false,
    pauseOnHover: true,
    draggable: true,
    responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1240,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 1450,
            settings: {
              slidesToShow: 5,
            },
          },
        ],
  };

  @ViewChild(SlickCarouselComponent) slickCarousel: SlickCarouselComponent;
  @ViewChild('prevBtn') prevBtn: ElementRef;
  @ViewChild('nextBtn') nextBtn: ElementRef;

  constructor(private router: Router) {}

  ngOnInit(): void {
  }

  swipePrev() {
    this.slickCarousel.slickPrev();
  }

  swipeNext() {
    this.slickCarousel.slickNext();
  }

  seeMore() {
    this.router.navigateByUrl(this.seeAllUrl);
  }
}
