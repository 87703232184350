<form>
  <mat-form-field
    *ngIf="fieldType != 'date' && fieldType != 'dropdown'"
    appearance="outline"
    class="custom-date-picker"
  >
    <input
      matInput
      type="{{ fieldType }}"
      autocomplete="off"
      [formControl]="queryFormControl"
      placeholder="{{ placeholder }}"
    />
    <div *ngIf="enableAutoComplete">
      <input
        matInput
        type="{{ fieldType }}"
        [formControl]="autoCompleteControl"
        [matAutocomplete]="auto"
      />
      <div class="loading--overlay" *ngIf="autoCompleteLoading">
        <div class="spinner">
          <mat-spinner color="primary" diameter="30"></mat-spinner>
        </div>
      </div>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </mat-form-field>

  <mat-form-field
    appearance="fill"
    float-label="always"
    (click)="picker.open()"
    *ngIf="fieldType == 'date'"
    class="custom-date-picker"
  >
    <input
      matInput
      [matDatepicker]="picker"
      [formControl]="queryFormControl"
      autocomplete="off"
      placeholder="{{ placeholder }}"
    />
    <mat-datepicker-toggle
      matPrefix
      [for]="picker"
      style="position: relative; bottom: -3px"
    ></mat-datepicker-toggle>
    <mat-datepicker-toggle matSuffix [for]="picker">
      <mat-icon
        class="text-gray-400"
        style="position: relative; bottom: -3px"
        matDatepickerToggleIcon
        >keyboard_arrow_down</mat-icon
      >
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <div class="dropdown">
    <mat-form-field
      class="example-full-width"
      appearance="fill"
      *ngIf="fieldType == 'dropdown'"
    >
      <mat-select
        [formControl]="dropDownFormControl"
        placeholder="{{ placeholder }}"
        disableOptionCentering
      >
        <mat-option
          *ngFor="let dropdown of dropDownArray"
          [value]="dropdown[fieldDropDownName]"
          >{{ dropdown[fieldDropDownName] }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</form>
