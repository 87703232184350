<section>
  <app-loading [loading]="loading"></app-loading>
  <section class ='product-grid-section'>
    <div class="headline-container">
      <h2 class="headline">{{ headline }}</h2>
      <div *ngIf="navUrl != '' && navTitle" class="flex"><button class="nav-button" (click)="navigate()">{{navTitle}}</button> </div>
    </div>
    <div class="grid-container" *ngIf="variants.length > 0">
      <div *ngFor="let variant of variants; let i = index">
        <app-product-card
          [variant]="variant"
          id = "{{variantIdPrefix + i}}" 
        ></app-product-card>
      </div>
    </div>
    <div class="empty-search" *ngIf="variants.length === 0" >
      <img class="m-auto p-8" src="../../assets/images/empty-search.svg" (load)="onShowEmptyStateImage()" alt="empty-search" />
      <div style="text-align: center;" *ngIf="headline ==='كل المنتجات'">
        <h2>نأسف لك لم نجد ما تريد البحث عنه</h2>
        <h4>برجاء تجربة البحث عن شيء أخر</h4>
      </div>
      <div style="text-align: center;" *ngIf="headline ==='المنتجات المفضلة'">
        <h2>لا يوجد منتجات مفضلة حتى الأن</h2>
      </div>
      <div class="Go-to-products">
        <button mat-flat-button [routerLink]="['/app','home']" [queryParams]="{pageSec: 'products'}" (click)="onClickEmptyStateProductsButton()">الرجوع الى المنتجات</button>
      </div>
    </div>
  </section>
</section>
