<header class="header-row">
  <mat-toolbar>
    <div class="announcement" *ngIf="announcementBanner">
      {{ announcementBannerText }}
    </div>
    <mat-toolbar-row class="flex">
      <div class="first-section">
        <!-- SideNav toggle button -->
        <div class="flex">
          <button
            mat-icon-button
            class="nav-header--burger-icon"
            aria-label="Toggle side menu"
            role="button"
            (click)="sideNav.toggle(); toggle()"
          >
            <mat-icon>menu</mat-icon>
          </button>

          <!-- Logo Image -->
          <a [routerLink]="['/app', 'home']">
            <img
              class="mt-1 logo-class"
              style=" max-width: '175px;"
              src="../../../../assets/images/el-kasseba-logo.png"
              alt="el-kasseba-logo"
            />
          </a>

          <div *ngIf="isDesktop" class="flex product-search-bar">
            <mat-form-field appearance="fill">
              <input
                [formControl]="productNameControl"
                placeholder="بحث باسم المنتج"
                matInput
                (keyup.enter)="onSearchProductsButtonClick($event)"
              />
              <mat-icon matPrefix>search</mat-icon>
            </mat-form-field>
            <button
              mat-flat-button
              style="margin-left: 8px; margin-right: 8px"
              class="primary-application-background-color secondary-application-color search-products-button"
              (click)="onSearchProductsButtonClick($event)"
            >
              <div *ngIf="isDesktop">بحث</div>
              <mat-icon *ngIf="!isDesktop" style="color: #b5b5b5"
                >search</mat-icon
              >
            </button>
          </div>
        </div>

        <!-- Cart button -->
        <div class="flex items-center d-flex justify-content-end" dir="rtl">
          <button
            mat-icon-button
            class="relative"
            aria-label="notifications"
            [matMenuTriggerFor]="cartQuickView"
          >
            <div class="cart-basket header-cart-items-position">
              <div class="m-auto header-cart-count-text">
                {{ cartItemsCount }}
              </div>
            </div>
            <mat-icon
              aria-hidden="false"
              class="primary-application-color"
              aria-label="Example home icon"
              >shopping_cart</mat-icon
            >
          </button>

          <!-- User Menu button -->
          <button
            mat-button
            [matMenuTriggerFor]="userMenu"
            class="nav-header--user-button"
            *ngIf="isDesktop"
          >
            <img
              class="h-8 w-8 object-contain object-center rounded-full mx-1 user-image"
              src="{{ image }}"
              alt="Profile picture"
            />
            <div class="nav-header--user-details sm:hidden hidden lg:block">
              <span class="block text-sm font-semibold">{{
                firstName + " " + lastName
              }}</span>
            </div>

            <div class="hidden lg:block">
              <mat-icon class="align-middle">keyboard_arrow_down </mat-icon>
            </div>
          </button>
        </div>
      </div>

      <div class="w-full" *ngIf="!isDesktop">
        <div class="flex product-search-bar pt-3">
          <mat-form-field appearance="fill">
            <input
              [formControl]="productNameControl"
              placeholder="بحث باسم المنتج"
              matInput
              (keyup.enter)="onSearchProductsButtonClick($event)"
            />
            <mat-icon matPrefix style="color: #b5b5b5">search</mat-icon>
          </mat-form-field>
          <button
            mat-flat-button
            style="margin-left: 8px; margin-right: 8px"
            class="primary-application-background-color secondary-application-color search-products-button px-10"
            (click)="onSearchProductsButtonClick($event)"
          >
            بحث
          </button>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</header>

<!-- User Menu -->
<mat-menu
  #userMenu
  class="nav-header--menu nav-header--user-menu"
  xPosition="before"
>
  <button
    mat-menu-item
    class="font-semibold text-black grid p-4"
    (click)="logout()"
  >
    <mat-icon>computer</mat-icon>
    <span>تسجيل الخروج</span>
  </button>
</mat-menu>

<!-- User Menu -->

<mat-menu #cartQuickView class="cart-quick-view" xPosition="after" dir="rtl">
  <div *ngIf="cartItems.length === 0">
    <img
      class="m-auto p-8 cart-img"
      src="../../assets/images/empty-cart.svg"
      alt="empty-cart"
    />
    <h3 class="p-8 empty-cart-text">سلة المشتريات فارغة</h3>
  </div>
  <div *ngIf="cartItems.length > 0">
    <div class="header-row cart-grid">
      <h5 class="px-5">المنتج</h5>
      <h5>سعر البيع للعميل</h5>
      <h5>الكمية</h5>
    </div>
    <hr />
    <div
      *ngFor="let row of cartItems"
      (click)="$event.stopPropagation()"
      class="product-row cart-grid"
    >
      <a
        [routerLink]="[
          '/app',
          'home',
          'product-details',
          row.variant.product.id
        ]"
        [queryParams]="getQueryParams(row.variant)"
        class="flex"
      >
        <img
          *ngIf="isDesktop"
          alt="image"
          src="{{
            row.variant.resources.length > 0
              ? row.variant.resources[0].image
              : '../../../assets/images/no-image.png'
          }}"
        />
        <span class="product-name text-overflow px-2"
          >{{ row.variant.product.name + " - " + row.variant.name }}
        </span>
      </a>

      <div>
        <span
          class="inline-block h-auto px-3 w-auto mr-1 blue font-semibold product-info-wrapper-style"
        >
          <div class="mt-2px">
            {{ row.selling_price }}
            {{
              marketplaceConstants ? marketplaceConstants.currencyInArabic : ""
            }}
          </div>
        </span>
      </div>

      <div>
        <span class="inline-block h-auto w-auto mr-1 font-semibold">
          <h3 class="mt-2px mb-0-important">
            <app-quantity-incrementor
              [variantID]="row?.variant?.id"
              (emittedQuantity)="quantityChangeListener($event)"
            ></app-quantity-incrementor>
          </h3>
        </span>
      </div>
    </div>
    <a [routerLink]="['/app', 'cart']">
      <button class="flex items-center cart-button py-2 px-4">
        <span class="font-semibold">الذهاب الى سلة المشتريات </span>
      </button>
    </a>
  </div>
</mat-menu>

<!-- Side Nav -->
<mat-sidenav-container
  class="sidenav-container"
  [hasBackdrop]="isDesktop ? false : true"
>
  <mat-sidenav
    #sideNav
    opened="true"
    [opened]="isDesktop ? true : false"
    [mode]="isDesktop ? 'side' : 'over'"
  >
    <!-- Logo Image -->

    <img
      src="../../../../assets/images/el-kasseba-logo.png"
      alt="el-kasseba-logo"
      class="logo-class"
    />
    <mat-nav-list>
      <mat-list class="flex flex-col justify-between h-full p-0">
        <div class="flex-col">
          <mat-expansion-panel
            class="sidenav-expansion--menu shadow-none mat-elevation-z0"
            #paymentsPanel
          >
            <mat-expansion-panel-header
              class="flex items-center"
              [routerLinkActive]="['is-active']"
              (click)="onNavHome()"
            >
              <mat-panel-title>
                <!-- Indicator -->
                <div class="flex items-center my-2">
                  <a
                    class="flex items-center my-2"
                    [routerLink]="['/app', 'home']"
                  >
                    <mat-icon aria-hidden="false" aria-label="Example home icon"
                      >home</mat-icon
                    >
                    <span class="mx-4 font-semibold">{{
                      "sidebar.main" | translate
                    }}</span>
                  </a>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="order-category-group">
              <!-- orders -->
              <mat-radio-group
                aria-label="Select an order"
                [value]="sortTabSelectedIndex"
              >
                <mat-radio-button
                  class="order-radio"
                  *ngFor="let option of sortingTabs; let i = index"
                  [attr.data-index]="i"
                  [value]="i"
                  (change)="tabListener($event)"
                >
                  {{ option }}</mat-radio-button
                >
              </mat-radio-group>
              <hr class="line" />
              <!-- categories item -->
              <mat-radio-group
                aria-label="Select a category"
                [value]="categoryTabSelectedIndex"
              >
                <mat-radio-button
                  class="category-radio"
                  [attr.data-index]=""
                  [value]="0"
                  (change)="categoryTabAction($event)"
                  >كل المنتجات</mat-radio-button
                >
                <mat-radio-button
                  class="category-radio"
                  *ngFor="let category of categories; let i = index"
                  [attr.data-index]="i"
                  [value]="i + 1"
                  (change)="categoryTabAction($event)"
                  >{{ category?.name }}</mat-radio-button
                >
              </mat-radio-group>
            </div>
          </mat-expansion-panel>

          <!--My Products Item-->
          <a
            [routerLink]="['/app', 'my-products']"
            *ngIf="sellerHasLockedVariants"
          >
            <mat-list-item
              class="flex items-center"
              [routerLinkActive]="['is-active']"
            >
              <mat-icon aria-hidden="false" aria-label="Example favorite icon"
                >redeem</mat-icon
              >
              <span class="mx-4 font-semibold">{{
                "sidebar.myProducts" | translate
              }}</span>
            </mat-list-item>
          </a>

          <!-- Favorite Products item -->
          <a [routerLink]="['/app', 'favourited-products']">
            <mat-list-item
              class="flex items-center"
              [routerLinkActive]="['is-active']"
            >
              <mat-icon aria-hidden="false" aria-label="Example favorite icon"
                >favorite</mat-icon
              >
              <span class="mx-4 font-semibold">{{
                "sidebar.favorites" | translate
              }}</span>
            </mat-list-item>
          </a>

          <!-- Shopping Cart item -->
          <a [routerLink]="['/app', 'cart']">
            <mat-list-item
              class="flex items-center"
              [routerLinkActive]="['is-active']"
              (click)="onNavMyCart()"
            >
              <mat-icon aria-hidden="false" aria-label="Example home icon"
                >shopping_cart</mat-icon
              >
              <div class="cart-basket side-cart-count-postion">
                <div class="m-auto cart-count-text">{{ cartItemsCount }}</div>
              </div>
              <span class="mx-4 font-semibold"
                >{{ "sidebar.cart" | translate }}
              </span>
            </mat-list-item>
          </a>

          <!-- Orders item -->
          <a [routerLink]="['/app', 'orders']">
            <mat-list-item
              class="flex items-center"
              [routerLinkActive]="['is-active']"
              (click)="onNavMyOrders()"
            >
              <mat-icon aria-hidden="false" aria-label="Example home icon"
                >date_range</mat-icon
              >
              <span class="mx-4 font-semibold" *ngIf="customizeAlkaseba"
                >الطلبات</span
              >
              <span class="mx-4 font-semibold" *ngIf="!customizeAlkaseba">{{
                "common.theOrders" | translate
              }}</span>
            </mat-list-item>
          </a>
          <!-- orderCaptureManagement Tab -->
          <a [routerLink]="['/app', 'order-capture-management']">
            <mat-list-item
              class="flex items-center"
              [routerLinkActive]="['is-active']"
            >
              <mat-icon aria-hidden="false" aria-label="Example home icon"
                >settings
              </mat-icon>
              <span
                class="mx-4 font-semibold"
                style="margin-left: 0 !important"
                >{{ "sidebar.orderCaptureManagement" | translate }}</span
              >
            </mat-list-item>
          </a>

          <!-- My Money item -->
          <a [routerLink]="['/app', 'billing']">
            <mat-list-item
              class="flex items-center"
              [routerLinkActive]="['is-active']"
              (click)="onNavMyMoney()"
            >
              <mat-icon aria-hidden="false" aria-label="Example home icon"
                >attach_money</mat-icon
              >
              <span class="mx-4 font-semibold" *ngIf="customizeAlkaseba"
                >المحفظه</span
              >

              <span class="mx-4 font-semibold" *ngIf="!customizeAlkaseba">{{
                "sidebar.myMoney" | translate
              }}</span>
            </mat-list-item>
          </a>

          <!-- My Profile item -->
          <a [routerLink]="['/app', 'profile']">
            <mat-list-item
              class="flex items-center"
              [routerLinkActive]="['is-active']"
              (click)="onNavMyProfile()"
            >
              <mat-icon aria-hidden="false" aria-label="Example home icon"
                >person_outline</mat-icon
              >
              <span class="mx-4 font-semibold">{{
                "sidebar.profile" | translate
              }}</span>
            </mat-list-item>
          </a>
          <!-- help section -->
          <a [routerLink]="['/app', 'help']" *ngIf="!customizeAlkaseba">
            <mat-list-item
              class="flex items-center"
              style="color: #fb1b1c"
              [routerLinkActive]="['is-active']"
            >
              <mat-icon aria-hidden="false" aria-label="Example home icon"
                >help</mat-icon
              >
              <span class="mx-4 font-semibold">{{
                "sidebar.help" | translate
              }}</span>
            </mat-list-item>
          </a>

          <!-- Log out -->
          <mat-list-item
            class="flex items-center"
            style="color: #868686"
            [routerLinkActive]="['is-active']"
            (click)="logout()"
            *ngIf="!isDesktop"
          >
            <mat-icon aria-hidden="false" aria-label="Example home icon"
              >logout</mat-icon
            >
            <span class="mx-4 font-semibold">تسجيل خروج</span>
          </mat-list-item>
        </div>
      </mat-list>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content
    class="page-content"
    id="page-container"
    [ngClass]="{ 'rtl-margin': active }"
  >
    <router-outlet (activate)="resetPosition()"></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
