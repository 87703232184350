import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { ProductsService } from 'src/app/shared/services/products/products.service';
import {
  Product,
  Variant,
  VariantResource,
} from 'src/app/shared/models/product.model';
import { Select, Store } from '@ngxs/store';
import { AddCartItem } from 'src/app/store/cart/cart.actions';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CoreService } from 'src/app/shared/services/core/core.service';
import { MediaTypeEnum } from 'src/app/shared/models/banner.model';
import { Observable, Subscription } from 'rxjs';
import { CartItemModel } from 'src/app/shared/models/cart.model';
import { MarketplaceConstants, ConstantsFactory } from 'src/app/common/global-constants';
import { MarketplaceModel } from 'src/app/shared/models/marketplace.models';
import { MixpanelService } from 'src/app/mixpanel.service';
import { IntroJSService } from 'src/app/shared/services/introjs/introjs.service';
import { ScreenSizeTracker } from 'src/app/core/helpers/screen-size.tracker';

interface GalleryItem {
  path: string;
  type: MediaTypeEnum;
  mainResource: boolean;
}
declare var require: any
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  icons: string[] = ['download', "play"];
  loading: boolean = true;
  orderCaptureAccess : boolean = false;
  video: any
  width: number = window.innerWidth
  product: Product;
  variant: Variant = {
    id:"",
    name:"",
    created_at: new Date(),
    modified_at: new Date(),
    description:"",
    allow_out_of_stock_actions:false,
  };
  description: string = ""; 
  att1_current_value = '';
  att2_current_value = '';
  att3_current_value = '';
  tooltipMsg = '';
  combinations: any[] = [];
  NO_IMAGE: GalleryItem = {
    path: 'assets/images/no-image.png',
    type: MediaTypeEnum.image,
    mainResource: true,
  };
  resources: GalleryItem[] = [];
  selectedResources: number;
  cartVariantIds: string[] = [];

  @Select((state: any) => state.Cart.cartItems)
  cartObservable: Observable<CartItemModel[]>;

  sellingPriceFormControl: UntypedFormControl;

  productId: string;
  labels: string[] = [];

  cartQuantity : number = 1;
  //bool to determine if app is in mobile view
  mobile: boolean;
  marketplaceConstants: MarketplaceConstants;
  marketplaceID : string;
  @Select((state: any) => state.MarketplaceState.selectedMarketplace)
  selectedMarketplaceObservable: Observable<MarketplaceModel>;

  subscriptions: Array<Subscription> = [];
  @Select((state: any) => state.UserInfo)
  userInfoObservable: Observable<any>;
  isPageVisited: boolean = true;
  isCartPageVisited: boolean = true;
  screenSizeTracker = ScreenSizeTracker.Instance
  orderCaptureLink : string = ""
  isShowOrderCapture : boolean = false;
  showBanner: boolean = false;
  viewWidth: any;
  isDesktop: boolean;
  eventNameToMixpanel : string [] = [
    'OC-Desktop click on order capture banner',
    'OC-Mobile click on order capture banner',
    'OC-Mobile close and try it on desktop',
    'OC-Mobile continue landing page creation',
  ]
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private productService: ProductsService,
    private store: Store,
    private router: Router,
    private coreService: CoreService,
    private mixpanelService: MixpanelService,
    private introJSService: IntroJSService,
  ) { 

   
  }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.productId = params['productid'];
      this.getProduct();
      this.subscribeObservables();
    });

    this.addIcons();
    if (window.innerWidth === 700) {
      this.mobile = true;

    }
    document.querySelector('#description')
  }

  ngAfterViewInit() {
    this.video = document.getElementById('video');
  }

  openLandingPageGenerator(){
    const stateData_isShowOrderCapture = history.state.isShowOrderCapture;
      const stateData_showBanner = history.state.showBanner;
  
      if (stateData_isShowOrderCapture) {
        this.isShowOrderCapture = stateData_isShowOrderCapture;
      }
      if (stateData_showBanner) {
        this.showBanner = stateData_showBanner;
      }
  }

  showOrderCapture(){
    if(this.screenSizeTracker.isTablete){
      this.notifyMixPanel(0);
    }
    else{
      this.notifyMixPanel(3);
    }
      this.isShowOrderCapture = true;
      const element = document.getElementById("orderCapture");
      if(element){
        element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
      }
  }

  notifyMixPanel(eventId : number){
    this.mixpanelService.track(this.eventNameToMixpanel[eventId], {
      "Engagement": true
    });
  }

  addIcons() {
    for (const icon of this.icons) {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.sanitizer.bypassSecurityTrustResourceUrl(
          `../../../assets/icons/${icon}.svg`
        )
      );
    }
  }

  getSanitizedHtml(value: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  getFileExtension(url: string): string {
    return url.split('.').pop();
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  extractBucketAndResource(url: string, storageUrl: string): string[] {
    return url
      .split(`https://${storageUrl}`)[1]
      .split(/\/(.+)/)
      .slice(0, 2);
  }

  formatUrl(url: string): string {
    const storageUrl = 'storage.googleapis.com/';
    const extractedData = this.extractBucketAndResource(url, storageUrl);
    const bucket = extractedData[0];
    const resource = extractedData[1];
    const newUrl = `https://${bucket}.${storageUrl}${resource}`;
    return newUrl;
  }

  async onDownloadClick() {
    this.mixpanelService.track('download button', {
      "SKU Code": this.variant.sku_code,
      "Engagement": true
    });
    for (let i = 0; i < this.resources.length; i++) {
      this.downloadResource(this.resources[i].path, i);
      await this.sleep(1500);
    }
  }

  async downloadResource(resourceUrl: string, id: number = 0) {
    if (resourceUrl == this.NO_IMAGE.path) return;
    resourceUrl = this.formatUrl(resourceUrl);
    let img = await axios({
      method: 'GET',
      url: resourceUrl,
      responseType: 'blob',
    });
    let extension = this.getFileExtension(resourceUrl);
    let name = `${this.product?.name} ${this.variant?.name}`;
    saveAs(img.data, `${name}-${id + 1}.${extension}`);
  }


  async getProduct() {
    this.loading = true;
    this.productService.getProduct(this.productId).subscribe(
      (res) => {
        this.product = res.body.product;
        this.route.queryParams.subscribe(this.getVariant);
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.router.navigate(['/app', 'home']);
      }
    );
  }

  getVariant = (queryParams: Params) => {
    let att1 = queryParams['att1'];
    let att2 = queryParams['att2'];
    let att3 = queryParams['att3'];

    let fetchedVariant;
    for (const variant of this.product.variants) {
      if (
        (!variant.attribute1_value || variant.attribute1_value == att1) &&
        (!variant.attribute2_value || variant.attribute2_value == att2) &&
        (!variant.attribute3_value || variant.attribute3_value == att3)
      ) {
        fetchedVariant = variant;
      }
    }

    if (!fetchedVariant) {
      this.router.navigate(['/app', 'home']);
      return;
    }

    this.setVariant(fetchedVariant);
    this.openLandingPageGenerator()
  };

  setVariant = (variant: Variant) => {
    this.variant = variant;
    this.att1_current_value = this.variant.attribute1_value;
    this.att2_current_value = this.variant.attribute2_value;
    this.att3_current_value = this.variant.attribute3_value;
    this.description = variant.description
    this.initializeFormField();
    this.setResources(this.variant.resources);
  };

  changeQueryParams(variant: Variant) {
    const queryParams: Params = {};

    if (variant.attribute1_value) {
      queryParams['att1'] = variant.attribute1_value;
    }
    if (variant.attribute2_value) {
      queryParams['att2'] = variant.attribute2_value;
    }
    if (variant.attribute3_value) {
      queryParams['att3'] = variant.attribute3_value;
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
    });
  }

  onGoToCart() {
    this.mixpanelService.track('PRODUCT-Click Go to Cart', {
      "Engagement": true
    });
  }

  addToCart() {
    this.mixpanelService.track('PRODUCT-Click Add to Cart', {
      "SKU Code": this.variant.sku_code,
      "Engagement": true
    });
    this.loading = true;
    this.store
      .dispatch(
        new AddCartItem(
          this.variant.id,
          this.cartQuantity
        )
      )
      .subscribe(
        () => {
          this.loading = false;
        },
        (error) => {
          this.loading = false;
          this.coreService.openAnyErrorMessageModal(
            error.status,
            error.error.detail
          );
        }
      );
  }

  initializeFormField() {
    this.sellingPriceFormControl = new UntypedFormControl(
      this.variant.minimum_selling_price,
      [Validators.required, Validators.min(this.variant.minimum_selling_price)]
    );

    this.sellingPriceFormControl = new UntypedFormControl(
      this.variant.maximum_selling_price,
      []
    );
  }

  /**
   * Updates the resources array instance variable using the input parameter `resources`
   * @param resources
   */
  setResources(resources: VariantResource[]) {
    if (resources.length) {
      this.resources = resources.map((resource) => {
        if (resource.image) {
          return {
            path: resource.image,
            type: MediaTypeEnum.image,
            mainResource: false,
          };
        } else {
          return {
            path: resource.video,
            type: MediaTypeEnum.video,
            mainResource: false,
          };
        }
      });
      for (let i = 0; i < this.resources.length; i++) {
        if (this.resources[i].type == MediaTypeEnum.video) {
          this.resources[i].mainResource = true;
          this.selectedResources = i;
          break;
        }
        if (i == this.resources.length - 1) {
          this.resources[0].mainResource = true;
          this.selectedResources = 0;
        }
      }
    } else {
      this.resources = [this.NO_IMAGE];
    }
  }

  changeMainResource(index: number) {
    for (const resource of this.resources) {
      resource.mainResource = false;
    }
    this.resources[index].mainResource = true;
    this.selectedResources = index
  }

  subscribeObservables() {
    this.cartObservable.subscribe((cartItems) => {
      this.cartVariantIds = cartItems.map((item) => item.variant.id.toString());
    });
    this.selectedMarketplaceObservable.subscribe(
      (marketplace) => {
        this.marketplaceID =  marketplace.id.toString();
        if (marketplace.id !== '') {
          this.marketplaceConstants = ConstantsFactory.createConstants(
            marketplace.id.toString()
          );
        } else {
          this.marketplaceConstants = null;
        }
      }

    )
    this.subscribeUserInfo();
  }



  att_1_exists(item: string): boolean {
    for (const variant of this.product.variants) {
      if (
        variant.attribute1_value == item &&
        variant.attribute2_value == this.att2_current_value &&
        variant.attribute3_value == this.att3_current_value
      ) {
        return true;
      }
    }
    return false;
  }

  att_2_exists(item: string): boolean {
    for (const variant of this.product.variants) {
      if (
        variant.attribute1_value == this.att1_current_value &&
        variant.attribute2_value == item &&
        variant.attribute3_value == this.att3_current_value
      ) {
        return true;
      }
    }
    return false;
  }

  att_3_exists(item: string): boolean {
    for (const variant of this.product.variants) {
      if (
        variant.attribute1_value == this.att1_current_value &&
        variant.attribute2_value == this.att2_current_value &&
        variant.attribute3_value == item
      ) {
        return true;
      }
    }
    return false;
  }

  att1_changed(item: string) {
    let exactVariant;
    let nearestVariant;
    for (const variant of this.product.variants) {
      if (
        variant.attribute1_value == item &&
        variant.attribute2_value == this.att2_current_value &&
        variant.attribute3_value == this.att3_current_value
      ) {
        exactVariant = variant;
        break;
      }
    
      if (!nearestVariant && variant.attribute1_value == item) {
        nearestVariant = variant;
      }
    }

    if (!exactVariant) {
      exactVariant = nearestVariant;
    }

    this.changeQueryParams(exactVariant);
    this.mixpanelService.track('Variant - Click', {
      'SKU Code': exactVariant.sku_code,
      "Engagement": true
    });
  }

  att2_changed(item: string) {
    let exactVariant;
    let nearestVariant;

    for (const variant of this.product.variants) {
      if (
        variant.attribute1_value == this.att1_current_value &&
        variant.attribute2_value == item &&
        variant.attribute3_value == this.att3_current_value
      ) {
        exactVariant = variant;
        break;
      }
    
      if (!nearestVariant && variant.attribute2_value == item) {
        nearestVariant = variant;
      }
    }

    if (!exactVariant) {
      exactVariant = nearestVariant;
    }
    this.changeQueryParams(exactVariant);
    this.mixpanelService.track('Variant - Click', {
      'SKU Code': exactVariant.sku_code,
      "Engagement": true
    });
  }

  att3_changed(item: string) {
    let exactVariant;
    let nearestVariant;

    for (const variant of this.product.variants) {
      if (
        variant.attribute1_value == this.att1_current_value &&
        variant.attribute2_value == this.att2_current_value &&
        variant.attribute3_value == item
      ) {
        exactVariant = variant;
        break;
      }
    
      if (!nearestVariant && variant.attribute3_value == item) {
        nearestVariant = variant;
      }
    }

    if (!exactVariant) {
      exactVariant = nearestVariant;
    }
    this.changeQueryParams(exactVariant);
    this.mixpanelService.track('Variant - Click', {
      'SKU Code': exactVariant.sku_code,
      "Engagement": true
    });
  }

  att_1_hover(item: string) {
    if (this.att_1_exists(item)) this.tooltipMsg = '';
    else {
      let nearest_variant;
      for (const variant of this.product.variants) {
        if (variant.attribute1_value == item) {
          nearest_variant = variant;
          break;
        }
      }

      let helperMsg = 'Click to select and change';
      let attrs: string[] = [];

      if (this.product.sub_category.attribute2_name) {
        attrs.push(this.product.sub_category.attribute2_name);
        helperMsg += ` ${this.product.sub_category.attribute2_name} to ${nearest_variant.attribute2_value}`;
      }
      if (this.product.sub_category.attribute3_name) {
        attrs.push(this.product.sub_category.attribute3_name);
        helperMsg += ` ${this.product.sub_category.attribute3_name} to ${nearest_variant.attribute3_value}`;
      }

      this.tooltipMsg =
        `Not available in selected ${attrs.join(', ')}\n` + helperMsg;
    }
  }

  att_2_hover(item: string) {
    if (this.att_2_exists(item)) this.tooltipMsg = '';
    else {
      let nearest_variant;
      for (const variant of this.product.variants) {
        if (variant.attribute2_value == item) {
          nearest_variant = variant;
          break;
        }
      }

      let helperMsg = 'Click to select and change';
      let attrs: string[] = [];

      if (this.product.sub_category.attribute1_name) {
        attrs.push(this.product.sub_category.attribute1_name);
        helperMsg += ` ${this.product.sub_category.attribute1_name} to ${nearest_variant.attribute1_value}`;
      }
      if (this.product.sub_category.attribute3_name) {
        attrs.push(this.product.sub_category.attribute3_name);
        helperMsg += ` ${this.product.sub_category.attribute3_name} to ${nearest_variant.attribute3_value}`;
      }

      this.tooltipMsg =
        `Not available in selected ${attrs.join(', ')}\n` + helperMsg;
    }
  }

  att_3_hover(item: string) {
    if (this.att_3_exists(item)) this.tooltipMsg = '';
    else {
      let nearest_variant;
      for (const variant of this.product.variants) {
        if (variant.attribute2_value == item) {
          nearest_variant = variant;
          break;
        }
      }

      let helperMsg = 'Click to select and change';
      let attrs: string[] = [];

      if (this.product.sub_category.attribute1_name) {
        attrs.push(this.product.sub_category.attribute1_name);
        helperMsg += ` ${this.product.sub_category.attribute1_name} to ${nearest_variant.attribute1_value}`;
      }
      if (this.product.sub_category.attribute2_name) {
        attrs.push(this.product.sub_category.attribute2_name);
        helperMsg += ` ${this.product.sub_category.attribute2_name} to ${nearest_variant.attribute2_value}`;
      }

      this.tooltipMsg =
        `Not available in selected ${attrs.join(', ')}\n` + helperMsg;
    }
  }
  quantityChangeListener($event:any){
    this.cartQuantity= $event;
  }

  onMainSourceLoad() {
    this.introJSService.productPage(this.isPageVisited, this.isCartPageVisited);
  }

  subscribeUserInfo() {
    this.subscriptions.push(
      this.userInfoObservable.subscribe((userInfo) => {
        if (userInfo.visited_page) {
          this.isPageVisited = userInfo.visited_page?.products_page;
          this.isCartPageVisited = userInfo.visited_page?.cart_page;
        }
          this.orderCaptureAccess = true;
      })
    );
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.screenSizeTracker.updateScreenParams(event.target.innerWidth);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }


}
