<div class="help-container">
  <button mat-icon-button class="close-button" (click)="closeDialog()">
    <mat-icon class="close-icon">close</mat-icon>
  </button>

  <mat-icon class="display-image" svgIcon="making-progress"></mat-icon>

  <div class="bottom-text medium-text">
    متنساش! ناقصلك {{ data.remaining_next_milestone }}
    {{ data.remaining_next_milestone == 1 ? confirmedOrderText : confirmedOrdersText }}
    علشان بونص ربحك يبقي {{ data.amount_per_order }} {{currencyInArabic}} علي ال{{'common.order' | translate}} المؤكد
  </div>

  <div class="bottom-text large-text">
    <span class="whitespace-nowrap">
      <mat-icon svgIcon="smile"></mat-icon>
    </span>
    خليك كسيب مع الكسيبة
  </div>
</div>
