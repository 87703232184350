import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClient, HttpResponse } from '@angular/common/http';
import { LoginRequest,LoginResponse } from '../../models/login.model';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient)
  {

  }
  authinticateSeller( payload: LoginRequest): Observable<HttpResponse<LoginResponse>>{
    return this.http.post<LoginResponse>(
      environment.baseUrl + 'base/auth/',
      payload,
      { observe: 'response' }
    );
  }
  refreshAccess(refreshToken: string): Observable<HttpResponse<any>> {
    return this.http.post(
      environment.baseUrl + 'base/auth/refresh/',
      { 'refresh': refreshToken },
      { observe: 'response' }
    );
  }
}
