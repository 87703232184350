<app-loading [loading]="loading"></app-loading>

<div>
  <section
    *ngIf="screenSizeTracker.isTablete"
    class="w-auto p-8 relative"
    style="display: flex; justify-content: space-evenly"
  >
    <div class="product-container p-4">
      <div style="display: flex; flex-direction: column">
        <div class="capture-page-background" style="width: 100%">
          <div class="lower-section">
            <div class="help-Cash child-comp">
              <img
                src="../../../assets/icons/help-circle.svg"
                alt="Help Circle"
                style="
                  width: 32px;
                  height: 32px;
                  flex-shrink: 0;
                  margin-top: 122px;
                  margin-left: 16px;
                "
              />

              <div class="child-comp flex justify-center">
                <div
                  class="p-in-invisable-section"
                  style="display: flex; margin-top: 12px"
                >
                  <img
                    src="../../../assets/icons/Money.svg"
                    style="width: 24px; height: 20px"
                    alt=""
                  />
                  لو محتاج تبيع كتير اتبع الخطوات دي
                </div>
                <div>
                  <ul
                    style="
                      list-style-image: url('../../../assets/icons/Ellipse\ 457.svg');
                    "
                  >
                    <li class="p2-in-invisable-section">
                      اعمل صفحة هبوط للمنتج اللي حابب تبيعه من هنا
                    </li>
                    <li class="p2-in-invisable-section">
                      اعمل اعلانات باستخدام صفحة الهبوط اللي انت عملتها
                    </li>
                    <li class="p2-in-invisable-section">
                      استلم اوردراتك علي الكسيبه وابدء استلم فلوسك
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <button
              mat-flat-button
              (click)="showOrderCapture()"
              id="showOrderCapture"
              class="button-in-order-capture"
            >
              <div style="display: flex; gap: 1%; align-items: center">
                <img
                  src="../../../assets/icons/Rocket.svg"
                  style="width: 20px; height: 20px"
                  alt=""
                />
                <img
                  src="../../../assets/icons/Rocket.svg"
                  style="width: 20px; height: 20px"
                  alt=""
                />
                بناء صفحة هبوط للمنتج ده
              </div>
            </button>
          </div>
        </div>

        <div class="discount-section" *ngIf="variant?.prepaid_discount">
          <div class="discount-background">
            <div class="discount-content w-full flex">
              <div class="flex flex-col items-center justify-center w-11/12">
                <span class="flex-wrap flex w-11/12 items-center">
                  سيتم تطبيق خصم
                  {{ variant?.prepaid_discount }}
                  {{
                    marketplaceConstants
                      ? marketplaceConstants.currencyInArabic
                      : ""
                  }}
                  علي الطلب الذي قام العميل بدفعه الكترونيا من خلال صفحة الهبوط
                </span>
                <span class="seller-discount items-center justify-center"
                  >(مع العلم بان هذا الخصم لن يتم اقتطاعه من ارباحكم)</span
                >
              </div>
              <div class="w-2/12 flex items-center">
                <img
                  src="../../../assets/images/discount.svg"
                  alt="Debit_Card"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="grid-container">
          <div style="width: 100%">
            <div class="flex">
              <div *ngIf="!loading" class="text-3xl dark-font-style ml-4">
                {{ variant?.product?.name + " " + variant?.name }}
              </div>

              <div
                class="inline-block h-auto w-auto font-semibold"
                [ngClass]="{
                  red: !variant.in_stock,
                  green: variant.in_stock
                }"
              >
                <div class="mx-2 mt-2">
                  {{ variant.in_stock ? "متاح" : "غير متاح" }}
                </div>
              </div>

              <button
                *ngIf="false"
                class="favourite-product-button red"
                mat-icon-button
              >
                <mat-icon class="pl-2">favorite_border</mat-icon>
              </button>
            </div>
            <div class="product-info-container mt-4">
              <div class="right-side">
                <div class="product-prices">
                  <div class="ml-4">
                    <div class="flex items-center">
                      <h4 style="margin: 0">أدني سعر بيع:</h4>
                      <div
                        class="inline-block h-auto px-3 w-auto mr-1 blue product-info-wrapper-style"
                      >
                        <div class="flex items-center">
                          {{ variant?.minimum_selling_price + " " }}
                          {{
                            marketplaceConstants
                              ? marketplaceConstants.currencyInArabic
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ml-4">
                    <div class="flex items-center">
                      <h4 style="margin: 0">ادني ربح:</h4>
                      <div
                        class="inline-block h-auto px-3 w-auto mr-1 green product-info-wrapper-style"
                      >
                        <div class="flex items-center">
                          {{ variant?.min_profit + " " }}
                          {{
                            marketplaceConstants
                              ? marketplaceConstants.currencyInArabic
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="ml-4" *ngIf="variant?.maximum_selling_price">
                    <div class="flex items-center">
                      <h4 style="margin: 0">سعر البيع المقترح:</h4>
                      <div
                        class="inline-block h-auto px-3 w-auto mr-1 green product-info-wrapper-style"
                      >
                        <div class="flex items-center">
                          {{ variant?.maximum_selling_price + " " }}
                          {{
                            marketplaceConstants
                              ? marketplaceConstants.currencyInArabic
                              : ""
                          }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div class="flex items-center">
                      <p style="margin: 0">كود المنتج:</p>
                      <div
                        class="inline-block h-auto px-3 w-auto mr-1 blue product-info-wrapper-style"
                      >
                        <div class="flex items-center">
                          {{ variant?.sku_code }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="product-description mt-5 py-3">
                  <div [innerHTML]="getSanitizedHtml(description)"></div>
                </div>
                <form>
                  <div class="mw" *ngIf="false">
                    <label> برجاء ادخال سعر البيع الخاص بك </label>
                    <mat-form-field appearance="fill">
                      <input
                        style="font-weight: 700"
                        matInput
                        type="number"
                        autocomplete="off"
                        [formControl]="sellingPriceFormControl"
                      />
                    </mat-form-field>
                    <mat-error
                      *ngIf="sellingPriceFormControl.hasError('required')"
                    >
                      يجب ادخال هذه الخانة
                    </mat-error>
                    <mat-error
                      *ngIf="
                        sellingPriceFormControl.hasError('min') &&
                        !sellingPriceFormControl.hasError('required')
                      "
                    >
                      لا يمكن إدخال سعر أقل من أدني سعر بيع
                    </mat-error>
                  </div>
                </form>

                <div class="select-variant">
                  <div
                    *ngIf="product?.sub_category?.attribute1_name"
                    class="select-attribute"
                  >
                    <p class="attribute-name">
                      {{ product?.sub_category?.attribute1_name }}
                    </p>
                    <mat-form-field appearance="fill">
                      <mat-select
                        (selectionChange)="att1_changed($event.value)"
                        [(value)]="att1_current_value"
                        disableOptionCentering
                      >
                        <mat-option
                          *ngFor="let item of product?.att1_values"
                          [value]="item"
                          (mouseover)="att_1_hover(item)"
                          [ngClass]="{ 'option-disabled': !att_1_exists(item) }"
                          matTooltip="{{ tooltipMsg }}"
                          matTooltipClass="option-tooltip"
                          >{{
                            att_1_exists(item) ? item : item + " (غير متاح)"
                          }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    *ngIf="product?.sub_category?.attribute2_name"
                    class="select-attribute"
                  >
                    <p class="attribute-name">
                      {{ product?.sub_category?.attribute2_name }}
                    </p>
                    <mat-form-field appearance="fill">
                      <mat-select
                        (selectionChange)="att2_changed($event.value)"
                        [(value)]="att2_current_value"
                        disableOptionCentering
                      >
                        <mat-option
                          *ngFor="let item of product?.att2_values"
                          [value]="item"
                          (mouseover)="att_2_hover(item)"
                          [ngClass]="{ 'option-disabled': !att_2_exists(item) }"
                          matTooltip="{{ tooltipMsg }}"
                          matTooltipClass="option-tooltip"
                          >{{
                            att_2_exists(item) ? item : item + " (غير متاح)"
                          }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div
                    *ngIf="product?.sub_category?.attribute3_name"
                    class="select-attribute"
                  >
                    <p class="attribute-name">
                      {{ product?.sub_category?.attribute3_name }}
                    </p>
                    <mat-form-field appearance="fill">
                      <mat-select
                        (selectionChange)="att3_changed($event.value)"
                        [(value)]="att3_current_value"
                        disableOptionCentering
                      >
                        <mat-option
                          *ngFor="let item of product?.att3_values"
                          [value]="item"
                          (mouseover)="att_3_hover(item)"
                          [ngClass]="{ 'option-disabled': !att_3_exists(item) }"
                          matTooltip="{{ tooltipMsg }}"
                          matTooltipClass="option-tooltip"
                          >{{
                            att_3_exists(item) ? item : item + " (غير متاح)"
                          }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div
                  class="buttons-container"
                  *ngIf="
                    !cartVariantIds.includes(variant?.id.toString()) && !loading
                  "
                >
                  <div class="mb-4">
                    <p class="text-lg">الكمية</p>
                    <app-quantity-incrementor
                      [variantID]="variant?.id.toString()"
                      (quantityUpdated)="quantityChangeListener($event)"
                    ></app-quantity-incrementor>
                  </div>

                  <div
                    class="items-center flex"
                    style="display: flex; justify-content: center"
                  >
                    <button
                      id="add-to-cart"
                      mat-flat-button
                      class="primary-application-background-color secondary-application-color add-product-class-button font-semibold items-start"
                      (click)="addToCart()"
                    >
                      إضافة إلى سلة المشتريات
                    </button>
                  </div>
                </div>
                <div
                  class="already-added-container"
                  *ngIf="cartVariantIds.includes(variant?.id.toString())"
                >
                  <div class="mt-4">
                    <p class="already-added">تم الاضافة الي سلة المشتريات</p>
                  </div>
                  <div class="items-center flex" *ngIf="!mobile">
                    <button
                      id="go-to-cart"
                      mat-flat-button
                      class="primary-application-background-color secondary-application-color add-product-class-button font-semibold items-start cart-large-button"
                      [routerLink]="['/app', 'cart']"
                      (click)="onGoToCart()"
                    >
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        >shopping_cart</mat-icon
                      >
                      الذهاب إلى سلة المشتريات
                    </button>
                    <button
                      mat-flat-button
                      class="primary-application-background-color secondary-application-color font-semibold items-start cart-small-button"
                      [routerLink]="['/app', 'cart']"
                    >
                      <mat-icon
                        aria-hidden="false"
                        aria-label="Example home icon"
                        >shopping_cart</mat-icon
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Resources Section -->
          <div class="product-resource">
            <!-- Displaying the current main resource -->
            <div id="product-resource">
              <ng-container *ngFor="let resource of resources">
                <div
                  class="main-resource-style mb-2"
                  *ngIf="resource.mainResource"
                >
                  <img
                    (load)="onMainSourceLoad()"
                    *ngIf="resource.type == 'image'"
                    src="{{ resource.path }}"
                    alt="image"
                  />
                  <video
                    (load)="onMainSourceLoad()"
                    *ngIf="resource.type == 'video'"
                    playsinline
                    controls
                    id="video"
                  >
                    <source src="{{ resource.path }}" />
                  </video>
                </div>
              </ng-container>
              <div
                *ngIf="resources.length > 1"
                class="flex sub-resources-container"
              >
                <ng-container *ngFor="let resource of resources; let i = index">
                  <div
                    class="sub-resource-style"
                    [ngStyle]="{
                      border:
                        selectedResources === i
                          ? '2px solid #FB1B1C'
                          : '1px solid #D0D0E2'
                    }"
                  >
                    <img
                      *ngIf="resource.type == 'image'"
                      (click)="changeMainResource(i)"
                      src="{{ resource.path }}"
                      alt="image"
                    />
                    <video
                      *ngIf="resource.type == 'video'"
                      (click)="changeMainResource(i)"
                      [muted]="'muted'"
                      playsinline
                    >
                      <source src="{{ resource.path }}" />
                    </video>
                    <mat-icon
                      *ngIf="resource.type == 'video'"
                      id="play-icon"
                      svgIcon="play"
                      (click)="changeMainResource(i)"
                    ></mat-icon>
                  </div>
                </ng-container>
              </div>
              <div
                *ngIf="resources.length && resources[0].path != NO_IMAGE.path"
                class="download-text"
                (click)="onDownloadClick()"
              >
                <p class="font-bold">
                  لتحميل جميع الصور والفيديوهات برجاء الضغط هنا
                </p>
                <mat-icon class="mr-2">file_download</mat-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section
    *ngIf="!screenSizeTracker.isTablete"
    class="py-5"
    style="display: flex; justify-content: space-evenly"
  >
    <app-loading [loading]="loading"></app-loading>
    <div class="container product-details-container-MV p-3">
      <div>
        <div class="product-details-container-MV">
          <div class="capture-page-background">
            <div class="lower-section">
              <div class="help-Cash child-comp">
                <img
                  src="../../../assets/icons/help-circle.svg"
                  style="
                    width: 32px;
                    height: 32px;
                    flex-shrink: 0;
                    flex-shrink: 0;
                    margin-top: 140px;
                    margin-left: 2px;
                  "
                />

                <div class="child-comp flex justify-center">
                  <div
                    class="p-in-invisable-section"
                    style="display: flex; margin-top: 12px"
                  >
                    <img
                      src="../../../assets/icons/Money.svg"
                      style="width: 24px; height: 20px"
                    />
                    لو محتاج تبيع كتير اتبع الخطوات دي
                  </div>
                  <div>
                    <ul
                      style="
                        list-style-image: url('../../../assets/icons/Ellipse\ 457.svg');
                      "
                    >
                      <li class="p2-in-invisable-section">
                        اعمل صفحة هبوط للمنتج اللي حابب تبيعه من هنا
                      </li>
                      <li class="p2-in-invisable-section">
                        اعمل اعلانات باستخدام صفحة الهبوط اللي انت عملتها
                      </li>
                      <li class="p2-in-invisable-section">
                        استلم اوردراتك علي الكسيبه وابدء استلم فلوسك
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <button
                mat-flat-button
                id="showOrderCaptureMobile"
                (click)="notifyMixPanel(1)"
                class="button-in-order-capture"
                onclick="window.dialog.showModal();"
              >
                <div style="display: flex; gap: 1%; align-items: center">
                  <img
                    src="../../../assets/icons/Rocket.svg"
                    style="width: 20px; height: 20px"
                    alt=""
                  />
                  بناء صفحة هبوط للمنتج ده
                </div>
              </button>

              <dialog id="dialog" class="dialogBackGround">
                <div class="dialogFont">
                  <span style="color: #3dcc79; font-weight: 700">مبروووك </span>
                  انت علي بعد خطوه واحده لانشاء صفحة هبوط ولكن انشائها من اللاب
                  توب او الكمبيوتر الخاص بك سيكون افضل
                </div>
                <div>
                  <form>
                    <button
                      class="button-in-dialog"
                      onclick="window.dialog.close();"
                      (click)="notifyMixPanel(2)"
                    >
                      سوف اقوم بانشائها من الكمبيوتر
                    </button>
                    <button
                      class="second-button-in-dialog"
                      onclick="window.dialog.close();"
                      (click)="showOrderCapture()"
                    >
                      استكمال وانشائها من الموبايل
                    </button>
                  </form>
                </div>
              </dialog>
            </div>
          </div>

          <div class="discount-section" *ngIf="variant?.prepaid_discount">
            <div class="discount-background">
              <div class="discount-content w-full flex">
                <div class="flex flex-col items-center justify-center w-11/12">
                  <span class="flex-wrap flex w-11/12 items-center">
                    سيتم تطبيق خصم
                    {{ variant?.prepaid_discount }}
                    {{
                      marketplaceConstants
                        ? marketplaceConstants.currencyInArabic
                        : ""
                    }}
                    علي الطلب الذي قام العميل بدفعه الكترونيا من خلال صفحة
                    الهبوط
                  </span>
                  <span class="seller-discount items-center w-10/12"
                    >(مع العلم بان هذا الخصم لن يتم اقتطاعه من ارباحكم)</span
                  >
                </div>
                <div class="w-2/12 flex items-center">
                  <img
                    src="../../../assets/images/discount.svg"
                    alt="Debit_Card"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="text-lg dark-font-style">
            {{ variant?.product?.name + " " + variant?.name }}
          </div>

          <div class="product-prices pt-2">
            <div class="w-full flex">
              <div class="flex w-full">
                <div class="w-1/2 flex flex-row content-center">
                  <div class="grid content-center">
                    <p>أدني سعر بيع :</p>
                  </div>
                  <div
                    class="py-1 px-2 blue product-info-wrapper-style grid content-center text-xs"
                  >
                    {{ variant?.minimum_selling_price + " " }}
                    {{
                      marketplaceConstants
                        ? marketplaceConstants.currencyInArabic
                        : ""
                    }}
                  </div>
                </div>
                <div class="w-1/2 flex flex-row justify-center">
                  <div class="grid content-center">
                    <p>ادني ربح:</p>
                  </div>
                  <div
                    class="py-1 px-2 green product-info-wrapper-style grid content-center text-xs"
                  >
                    <div class="flex items-center">
                      {{ variant?.min_profit + " " }}
                      {{
                        marketplaceConstants
                          ? marketplaceConstants.currencyInArabic
                          : ""
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <div class="flex items-center"></div>

            <div>
              <div class="flex items-center">
                <p style="margin: 0">كود المنتج:</p>
                <div
                  class="w-auto py-1 px-2 blue product-info-wrapper-style grid content-center text-xs"
                >
                  {{ variant?.sku_code }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="resource-container mt-5" id="product-resource">
          <!-- Displaying the current main resource -->
          <ng-container *ngFor="let resource of resources">
            <div class="main-resource-style mb-2" *ngIf="resource.mainResource">
              <img
                (load)="onMainSourceLoad()"
                *ngIf="resource.type == 'image'"
                src="{{ resource.path }}"
                alt="image"
                onload="onMainSourceLoad()"
              />
              <video
                (load)="onMainSourceLoad()"
                *ngIf="resource.type == 'video'"
                playsinline
                controls
              >
                <source src="{{ resource.path }}" />
              </video>
            </div>
          </ng-container>
          <div
            *ngIf="resources.length > 1"
            class="flex sub-resources-container"
          >
            <ng-container *ngFor="let resource of resources; let i = index">
              <div
                class="sub-resource-style"
                [ngStyle]="{
                  border:
                    selectedResources === i
                      ? '2px solid #FB1B1C'
                      : '1px solid #D0D0E2'
                }"
              >
                <img
                  *ngIf="resource.type == 'image'"
                  (click)="changeMainResource(i)"
                  src="{{ resource.path }}"
                  alt="image"
                />
                <video
                  *ngIf="resource.type == 'video'"
                  (click)="changeMainResource(i)"
                  [muted]="'muted'"
                  playsinline
                >
                  <source src="{{ resource.path }}" />
                </video>
                <mat-icon
                  *ngIf="resource.type == 'video'"
                  id="play-icon"
                  svgIcon="play"
                  (click)="changeMainResource(i)"
                ></mat-icon>
              </div>
            </ng-container>
          </div>
          <div
            *ngIf="resources.length && resources[0].path != NO_IMAGE.path"
            class="download-text"
            style="justify-content: center"
            (click)="onDownloadClick()"
          >
            <p class="font-bold text-xs">
              لتحميل جميع الصور والفيديوهات برجاء الضغط هنا
            </p>
            <mat-icon class="mr-2">file_download</mat-icon>
          </div>
        </div>

        <!--description-->
        <div class="product-description mt-5">
          <div [innerHTML]="getSanitizedHtml(description)"></div>
        </div>

        <!-- form -->
        <form>
          <div class="mw" *ngIf="false">
            <label> برجاء ادخال سعر البيع الخاص بك </label>
            <mat-form-field appearance="fill">
              <input
                style="font-weight: 700"
                matInput
                type="number"
                autocomplete="off"
                [formControl]="sellingPriceFormControl"
              />
            </mat-form-field>
            <mat-error *ngIf="sellingPriceFormControl.hasError('required')">
              يجب ادخال هذه الخانة
            </mat-error>
            <mat-error
              *ngIf="
                sellingPriceFormControl.hasError('min') &&
                !sellingPriceFormControl.hasError('required')
              "
            >
              لا يمكن إدخال سعر أقل من أدني سعر بيع
            </mat-error>
          </div>
        </form>

        <!-- select-variant -->
        <div class="select-variant mt-5">
          <div
            *ngIf="product?.sub_category?.attribute1_name"
            class="select-attribute"
          >
            <p class="attribute-name">
              {{ product?.sub_category?.attribute1_name }}
            </p>
            <mat-form-field appearance="fill">
              <mat-select
                (selectionChange)="att1_changed($event.value)"
                [(value)]="att1_current_value"
                disableOptionCentering
              >
                <mat-option
                  *ngFor="let item of product?.att1_values"
                  [value]="item"
                  (mouseover)="att_1_hover(item)"
                  [ngClass]="{ 'option-disabled': !att_1_exists(item) }"
                  matTooltip="{{ tooltipMsg }}"
                  matTooltipClass="option-tooltip"
                  >{{ item }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            *ngIf="product?.sub_category?.attribute2_name"
            class="select-attribute"
          >
            <p class="attribute-name">
              {{ product?.sub_category?.attribute2_name }}
            </p>
            <mat-form-field appearance="fill">
              <mat-select
                (selectionChange)="att2_changed($event.value)"
                [(value)]="att2_current_value"
                disableOptionCentering
              >
                <mat-option
                  *ngFor="let item of product?.att2_values"
                  [value]="item"
                  (mouseover)="att_2_hover(item)"
                  [ngClass]="{ 'option-disabled': !att_2_exists(item) }"
                  matTooltip="{{ tooltipMsg }}"
                  matTooltipClass="option-tooltip"
                  >{{ item }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div
            *ngIf="product?.sub_category?.attribute3_name"
            class="select-attribute"
          >
            <p class="attribute-name">
              {{ product?.sub_category?.attribute3_name }}
            </p>
            <mat-form-field appearance="fill">
              <mat-select
                (selectionChange)="att3_changed($event.value)"
                [(value)]="att3_current_value"
                disableOptionCentering
              >
                <mat-option
                  *ngFor="let item of product?.att3_values"
                  [value]="item"
                  (mouseover)="att_3_hover(item)"
                  [ngClass]="{ 'option-disabled': !att_3_exists(item) }"
                  matTooltip="{{ tooltipMsg }}"
                  matTooltipClass="option-tooltip"
                  >{{ item }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- add-product -->
        <div
          class="flex flex-row mt-5"
          *ngIf="!cartVariantIds.includes(variant?.id.toString()) && !loading"
        >
          <div class="w-2/5 grid content-center">
            <app-quantity-incrementor
              [variantID]="variant?.id.toString()"
              (emittedQuantity)="quantityChangeListener($event)"
            ></app-quantity-incrementor>
          </div>

          <div class="grid content-center">
            <button
              id="add-to-cart"
              mat-flat-button
              class="primary-application-background-color secondary-application-color add-product-class-button font-semibold"
              (click)="addToCart()"
            >
              إضافة إلى سلة المشتريات
            </button>
          </div>
        </div>

        <!-- alread in the cart -->

        <div
          class="flex flex-col items-center mt-5"
          *ngIf="cartVariantIds.includes(variant?.id.toString())"
        >
          <div class="">
            <p class="already-added">تم الاضافة الي سلة المشتريات</p>
          </div>
          <div class="items-center flex">
            <button
              id="go-to-cart"
              mat-flat-button
              class="primary-application-background-color secondary-application-color add-product-class-button font-semibold items-start cart-large-button"
              [routerLink]="['/app', 'cart']"
            >
              <mat-icon aria-hidden="false" aria-label="Example home icon"
                >shopping_cart</mat-icon
              >
              الذهاب إلى سلة المشتريات
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<app-landing-page-generator
  [variant]="variant"
  [marketplaceID]="marketplaceID"
  [showBanner]="showBanner"
  *ngIf="isShowOrderCapture"
  id="orderCapture"
></app-landing-page-generator>

<app-footer></app-footer>
