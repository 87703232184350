import { Component, Input, OnInit } from '@angular/core';
import { Observable, merge, fromEvent } from 'rxjs';
import { Variant } from 'src/app/shared/models/product.model';
import { Router } from '@angular/router';
import { MixpanelService } from 'src/app/mixpanel.service';

@Component({
  selector: 'app-products-grid',
  templateUrl: './products-grid.component.html',
  styleUrls: ['./products-grid.component.scss'],
})
export class ProductsGridComponent implements OnInit {
  @Input() variants: Variant[] = [];
  @Input() headline: string = ""
  @Input() navTitle: string = "";
  @Input() navUrl: string = "";
  @Input() callingComponentName: string = "";
  loading = false;
  
  
  variantIdPrefix = "variant_"
  scroll$: Observable<unknown>;

  constructor(private router: Router,
    private mixpanelService: MixpanelService
    ) {
    this.scroll$ = merge(fromEvent(window, 'scroll'));
  }

  ngOnInit(): void {
  }

  navigate() {
    this.router.navigateByUrl(this.navUrl);
  }


  ngOnDestroy(): void {
  }

  onShowEmptyStateImage(): void {
    if(this.callingComponentName == 'favourite-products')
      {
        this.mixpanelService.track('ES- Favorites opened');
      }
    else if (this.callingComponentName == 'my-products')
    {
      this.mixpanelService.track('ES- My Products opened');
    }
    else
      {
        this.mixpanelService.track('ES- Search - Opened');
      }
  }

  onClickEmptyStateProductsButton(): void {
    if(this.callingComponentName == 'favourite-products')
    {
      this.mixpanelService.track('ES - Favorites - Products');
    }
    else if (this.callingComponentName == 'my-products')
    {
      this.mixpanelService.track('ES- My Products - Products');
    }
    else
    {
      this.mixpanelService.track('ES- Search - Products');
    }
  }
}