import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  ListHeader,
  FilterValue,
  filterValuesHeaderToFilterURL,
  listToHTTPParams,
} from '../../service-helpers/filter-helper';
import { environment } from '../../../../environments/environment';
import {
  ExportFilterValueWithType,
  GetOrdersResponse,
  GetReturnOrdersResponse,
  Order,
  OrderCreationPayload,
  OrderCreationResponse,
  OrdersStats,
  OrderUpdatePayload,
  SellerProgress,
} from '../../models/orders.model';
import { formatDate } from '@angular/common';
import * as fileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  constructor(private http: HttpClient) { }

  getOrders(
    filterValues: FilterValue[],
    header: ListHeader
  ): Observable<HttpResponse<GetOrdersResponse>> {
    let params = listToHTTPParams(header);
    let filters = filterValuesHeaderToFilterURL(filterValues, header);

    return this.http.get<GetOrdersResponse>(
      environment.baseUrl + `order/order/${filters}`,
      { params, observe: 'response' }
    );
  }

  createOrder(
    payload: OrderCreationPayload
  ): Observable<HttpResponse<OrderCreationResponse>> {
    return this.http.post<OrderCreationResponse>(
      environment.baseUrl + `order/order/`,
      payload,
      { observe: 'response' }
    );
  }

  getOrdersStats(): Observable<HttpResponse<OrdersStats>> {
    return this.http.get<OrdersStats>(
      environment.baseUrl + `order/order_stats/`,
      { observe: 'response' }
    );
  }

  updateOrder(
    payload: OrderUpdatePayload,
    order_id: string
  ): Observable<HttpResponse<Order>> {
    return this.http.patch<Order>(
      environment.baseUrl + `order/order/${order_id}`,
      payload,
      { observe: 'response' }
    );
  }

  getSellerProgress(): Observable<HttpResponse<SellerProgress>> {
    return this.http.get<SellerProgress>(
      environment.baseUrl + `order/seller-progress/`,
      { observe: 'response' }
    );
  }

  getOrderDetailedData(orderId: string): Observable<HttpResponse<Order>> {
    return this.http.get<Order>(
      environment.baseUrl + `order/order-detailed-data/${orderId}`,
      { observe: 'response' }
    );
  }

  getReturnOrdersDetailedData(outboundOrderId: string): Observable<HttpResponse<GetReturnOrdersResponse>> {
    const params = new HttpParams().set('order_pk', outboundOrderId)
    return this.http.get<GetReturnOrdersResponse>(
      environment.baseUrl + `order/return-orders-detailed-data/`,
      { params, observe: 'response' }
    );
  }

  exportFilterOrdersCSV(table: string, exportFilterValueWithType: ExportFilterValueWithType, marketplace: string): Observable<any> {
    let params = new HttpParams().set('table', table).set('marketplace_id', marketplace);
    return this.http.post(environment.baseUrl + `base/reports/`, exportFilterValueWithType, {
      responseType: 'blob',
      params: params
    });
  }

  createFakeAnchorForDownload(response: any, fileName: string) {

    let now = new Date();
    let date = formatDate(now, 'yyyy/MM/dd', 'en');
    fileSaver.saveAs(response, fileName + "_" + date + ".csv");
  }
}
