import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
} from 'rxjs/operators';
import {
  dateToString,
  FilterType,
} from 'src/app/shared/helpers/filter.field.model';
import { FilterValue } from 'src/app/shared/service-helpers/filter-helper';
interface dropDownEmission {
  value: string;
  field: string;
}
@Component({
  selector: 'app-filter-field',
  templateUrl: './filter-field.component.html',
  styleUrls: ['./filter-field.component.scss'],
})
export class FilterFieldComponent implements OnInit {
  queryFormControl = new UntypedFormControl('');
  dropDownFormControl = new UntypedFormControl('');
  @Input() fieldType: FilterType;
  @Input() placeholder: string;
  @Input() fieldName: string;
  @Input() clearEvent: Observable<void>;
  @Input() dropDownArray: any[];
  @Input() fieldDropDownName: string;
  @Input() enableAutoComplete: boolean = false;
  @Input() suggestions: any[] = [];
  @Input() autoCompleteLoading: boolean;
  @Output() filterFieldChange = new EventEmitter<any>();
  @Output() dropDownchange = new EventEmitter<dropDownEmission>();

  autoCompleteControl = new UntypedFormControl();
  filteredOptions: Observable<string[]>;
  constructor() {}

  ngOnInit(): void {
    this.clearEvent.subscribe(() => {
      this.queryFormControl.setValue('');
      this.dropDownFormControl.setValue('');
      this.suggestions = [];
      this.autoCompleteControl.setValue('');
    });

    this.filteredOptions = this.autoCompleteControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filter(value))
    );
  }

  ngAfterViewInit() {
    this.queryFormControl.valueChanges.subscribe((val) => {
      let filterValue: FilterValue = {
        attribute: this.fieldName,
        value: val,
      };
      if (this.fieldType == FilterType.date)
        filterValue.value = dateToString(val);

      this.filterFieldChange.emit(filterValue);
    });

    this.dropDownFormControl.valueChanges.subscribe((val) => {
      this.dropDownchange.emit({ value: val, field: this.fieldName });

      let filterValue: FilterValue = {
        attribute: this.fieldName,
        value: val,
      };
      this.filterFieldChange.emit(filterValue);
    });
    this.autoCompleteControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((val) => {
        let filterValue: FilterValue = {
          attribute: this.fieldName,
          value: val,
        };
        this.filterFieldChange.emit(filterValue);
      });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.suggestions.filter((option) =>
      option.toLowerCase().includes(filterValue)
    );
  }
}
