import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { SectionResponse, SectionsResponse } from '../../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class SectionsService {
  constructor(private http: HttpClient) {}

  getSectionsData() : Observable<HttpResponse<SectionsResponse>> {
    return this.http.get<SectionsResponse>(
      environment.baseUrl + `product/section-products/`,
      { observe: 'response' }
    );
  }

  getSectionData(sectionId : string) : Observable<HttpResponse<SectionResponse>> {
    return this.http.get<SectionResponse>(
      environment.baseUrl + `product/section-products/${sectionId}`,
      { observe: 'response' }
    );
  }

}
