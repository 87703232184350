<section [class.disabled]="disabled" [class.not-disabled]="!disabled">
  <app-loading [loading]="loading"> </app-loading>
  <div class="filter-component">
    <div class="filter-container mb-4" *ngIf="filterFields?.length > 0">
      <!-- Filter Fields -->
      <div class="filter-field m-2" *ngFor="let field of filterFields">
        <app-filter-field
          [placeholder]="field.placeholder"
          [fieldName]="field.name"
          [fieldType]="field.type"
          [clearEvent]="clearClicked.asObservable()"
          (filterFieldChange)="filterFieldListener($event)"
          (dropDownchange)="dropDownListener($event)"
          [dropDownArray]="field.dropDownArray"
          [fieldDropDownName]="field.fieldDropDownName"
          [enableAutoComplete]="field.enableAutoComplete"
          [suggestions]="field.suggestions"
          [autoCompleteLoading]="field.autoCompleteLoading"
        ></app-filter-field>
      </div>

      <!-- Action Buttons for Orders only-->
      <div
        class="mt-auto mb-auto flex flex-column justify-flex-start"
        *ngIf="caller == 'orders'"
      >
        <div
          class="mt-auto mb-auto flex flex-row justify-center"
          *ngIf="filterFields?.length > 0"
        >
          <button
            mat-flat-button
            style="margin-left: 8px; margin-right: 8px"
            class="primary-application-background-color secondary-application-color filter-button"
            (click)="onFilterClick()"
          >
            بحث
          </button>
          <button
            mat-flat-button
            class="filter-button clear-button"
            (click)="onClearClick()"
            disabled="{{ isCleared && !hasValues }}"
          >
            مسح
          </button>
        </div>
        <div class="mt-auto mb-auto flex flex-row justify-flex-start">
          <button
            mat-flat-button
            style="margin-left: 8px; margin-right: 8px"
            class="primary-application-background-color secondary-application-color export-button"
            (click)="exportOrdersCSV()"
            download
          >
            تحميل الطلبات
          </button>
        </div>
      </div>

      <!-- Action Buttons  -->
      <div
        class="action-buttons-div mt-auto mb-auto flex flex-column justify-center"
        *ngIf="caller != 'orders'"
      >
        <div
          class="mt-auto mb-auto flex flex-row justify-center"
          *ngIf="filterFields?.length > 0"
        >
          <button
            mat-flat-button
            style="margin-left: 8px; margin-right: 8px"
            class="primary-application-background-color secondary-application-color filter-button"
            (click)="onFilterClick()"
          >
            بحث
          </button>
          <button
            mat-flat-button
            class="filter-button clear-button"
            (click)="onClearClick()"
            disabled="{{ isCleared && !hasValues }}"
          >
            مسح
          </button>
        </div>
        <div class="mt-auto mb-auto flex flex-row justify-flex-start"></div>
      </div>
    </div>
    <div dir="ltr">
      <!-- Tabs -->
      <mat-tab-group
        class="remove-border-bottom mr-2"
        mat-align-tabs="center"
        (selectedIndexChange)="tabAction($event)"
        dir="rtl"
        [selectedIndex]="selectedTab"
      >
        <ng-container *ngFor="let label of tabList">
          <mat-tab label="{{ label }}"></mat-tab>
        </ng-container>
      </mat-tab-group>
    </div>
  </div>
</section>
