<!-- footer -->

<footer>
  <section class="logo-section">
    <div class="logo-version">
      <h2 class="footer-logo">الكسيبة</h2>
    </div>
    <div>
      <a
        href="https://alkaseba.com/PrivacyPolicy.html"
        class="privacy-policy"
        (click)="onClickPrivacyPolicy()"
        >سياسة الخصوصية</a
      >
    </div>
  </section>
  <div class="div-social" *ngIf="!customizeAlkaseba">
    <a
      href="https://www.instagram.com/alkaseba/"
      class="social"
      (click)="onClickInstagramIcon()"
      ><img src="../../../assets/logos/logos_instagram.svg" alt=""
    /></a>
    <a
      href="https://www.youtube.com/channel/UC9MSkyaE5xASpQ8GbdehqRg"
      class="social"
      (click)="onClickYoutubeIcon()"
      ><img src="/../../assets/logos/logos_youtube-icon.svg" alt=""
    /></a>
    <a
      href="https://www.facebook.com/Alkaseba"
      class="social"
      (click)="onClickFacebookIcon()"
      ><img src="../../../assets/logos/facebook-logo.svg" alt=""
    /></a>
  </div>
</footer>
