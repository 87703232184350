import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NavigationComponent } from './components/navigation/navigation.component';
import { AuthGuard } from './core/guards/auth.gaurd';
import { UnAuthedGuard } from './core/guards/unauthed.guard';
import { OtpComponent } from './common-components-module/otp/otp.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'app',
    component: NavigationComponent,
    children: [
      {
        path:'',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadChildren: () =>
          import('./home/home.module').then((m) => m.HomeModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'category',
        loadChildren: () =>
          import('./category/category.module').then((m) => m.CategoryModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'section',
        loadChildren: () =>
          import('./section-page/section/section.module').then((m) => m.SectionModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'cart',
        loadChildren: () =>
          import('./cart/cart.module').then((m) => m.CartModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('./orders/orders.module').then((m) => m.OrdersModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'billing',
        loadChildren: () =>
          import('./billing/billing.module').then((m) => m.BillingModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        loadChildren: () =>
          import('./profile/profile.module').then((m) => m.ProfileModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'favourited-products',
        loadChildren: () =>
          import('./favourited-products/favourited-products.module').then(
            (m) => m.FavouritedProductsModule
          ),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'help',
        loadChildren: () =>
          import('./help/help.module').then((m) => m.HelpModule),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'order-capture-management',
        loadChildren: () =>
          import('./order-capture-management/order-capture-management.module').then(
            (m) => m.OrderCaptureManagementModule
          ),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'edit-order-capture',
        loadChildren: () =>
          import('./edit-order-capture/edit-order-capture.module').then(
            (m) => m.EditOrderCaptureModule
          ),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
      {
        path: 'my-products',
        loadChildren: () =>
          import('./my-products/my-products.module').then(
            (m) => m.MyProductsModule
          ),
        canLoad: [AuthGuard],
        canActivate: [AuthGuard]
      },
    ],
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./entry/entry.module').then((m) => m.EntryModule),
      canLoad: [UnAuthedGuard],
      canActivate: [UnAuthedGuard]
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
      canLoad: [UnAuthedGuard],
      canActivate: [UnAuthedGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },
  {
    path: 'welcome-questions',
    loadChildren: () =>
      import('./welcome-questions/welcome-questions.module').then((m) => m.WelcomeQuestionsModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path:'otp',
    component: OtpComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
