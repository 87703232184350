import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterComponent } from './filter/filter.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { QuantityIncrementorComponent } from './buttons/quantity-incrementor/quantity-incrementor.component';
import { FilterFieldComponent } from './filter/filter-field/filter-field.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ProductsGridComponent } from './products-grid/products-grid.component';
import { LoadingComponent } from './loading/loading.component';
import { RouterModule } from '@angular/router';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { BannerComponent } from './banner/banner.component';
import { ProductDropdownSearchComponent } from './product-dropdown-search/product-dropdown-search.component';
import { OTPModalComponent } from './otp-modal/otp-modal.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FooterComponent } from './footer/footer.component';
import { OnboardingCardsComponent } from './onboarding-cards/onboarding-cards.component';
import { ProgressPopupComponent } from '../popup/progress-popup/progress-popup.component';
import { AchievementPopupComponent } from '../popup/achievement-popup/achievement-popup.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule} from 'ng-lazyload-image';
import { ProductCarouselComponent } from './product-carousel/product-carousel.component';
import { ProductCardComponent } from './product-card/product-card.component';
import { ProductRequestComponent } from './product-request/product-request.component';
import { ProductRequestDialogComponent } from './product-request/product-request-dialog/product-request-dialog.component';
import { LandingPageGeneratorComponent } from './landing-page-generator/landing-page-generator.component';
import {TextFieldModule} from '@angular/cdk/text-field';
import { OtpComponent } from './otp/otp.component';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { SlickCarouselModule } from 'ngx-slick-carousel';


@NgModule({
  declarations: [
    FilterComponent,
    QuantityIncrementorComponent,
    FilterFieldComponent,
    ProductDetailsComponent,
    ProductsGridComponent,
    LoadingComponent,
    BannerComponent,
    ProductDropdownSearchComponent,
    OTPModalComponent,
    FooterComponent,
    OnboardingCardsComponent,
    ProgressPopupComponent,
    AchievementPopupComponent,
    ConfirmationDialogComponent,
    ProductCarouselComponent,
    ProductCardComponent,
    ProductRequestComponent,
    ProductRequestDialogComponent,
    LandingPageGeneratorComponent,
    OtpComponent,

  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatButtonModule,
    MatIconModule,
    RouterModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTooltipModule,
    TranslateModule,
    LazyLoadImageModule,
    SlickCarouselModule,
    TextFieldModule,
    AngularFireModule.initializeApp(environment.orderCaptureFirebaseConfig, 'Order-Capture'),
  ],
  exports: [
    FilterComponent,
    QuantityIncrementorComponent,
    ProductDetailsComponent,
    ProductsGridComponent,
    LoadingComponent,
    BannerComponent,
    ProductDropdownSearchComponent,
    FooterComponent,
    ConfirmationDialogComponent,
    ProductCarouselComponent,
    ProductCardComponent,
    ProductRequestComponent,
    OtpComponent,
    LandingPageGeneratorComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CommonComponentsModule {}
