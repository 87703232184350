<app-loading [loading]="loading"></app-loading>
<div class="product-card" *ngIf="variant">
  <div class="img-container">
    <a
      [routerLink]="['/app', 'home', 'product-details', variant.product.id]"
      [queryParams]="getQueryParams(variant)"
      (click)="onClickProduct(variant)"
    >
      <div class="favorite-icon-container">
        <div
          (click)="onFavIconClick($event, variant)"
          *ngIf="variant.is_favorited"
          class="fav-icon-container"
        >
          <mat-icon svgIcon="filled-heart"></mat-icon>
        </div>
        <div
          (click)="onFavIconClick($event, variant)"
          *ngIf="!variant.is_favorited"
          class="fav-icon-container"
        >
          <mat-icon svgIcon="empty-heart"></mat-icon>
        </div>
      </div>
      <img
        *ngIf="variant.resources.length == 0"
        class="rounded product-image"
        src="{{ 'assets/images/no-image.png' }}"
        alt="product image"
      />
      <img
        *ngIf="variant.resources.length > 0 && variant.resources[0].image"
        class="rounded product-image"
        [defaultImage]="'assets/images/loader.webp'"
        [lazyLoad]="getVariantImageThumbnailUrl(variant.resources[0].image)"
        [offset]="0"
        alt="variant image"
      />
      <video
        *ngIf="variant.resources.length > 0 && variant.resources[0].video"
        [muted]="'muted'"
        playsinline
      >
        <source src="{{ variant.resources[0].video }}" />
      </video>
    </a>
  </div>
  <div class="product-name">
    {{ variant.product.name + " " + variant.name }}
  </div>
  <div class="product-details">
    <div class="product-details-element">
      <span class="light-font-style" *ngIf="customizeAlkaseba"> سعر البيع</span>
      <span class="light-font-style" *ngIf="!customizeAlkaseba"
        >ادنى سعر بيع</span
      >

      <div
        class="inline-block w-auto black font-semibold items-center justify-center flex"
        style="height: fit-content"
      >
        {{ variant.minimum_selling_price }}
        {{ marketplaceConstants ? marketplaceConstants.currencyInArabic : "" }}
      </div>
    </div>
    <div class="product-details-element">
      <span class="light-font-style" *ngIf="customizeAlkaseba"> الربح</span>
      <span class="light-font-style" *ngIf="!customizeAlkaseba">ادنى ربح</span>
      <div class="profit-style">
        {{ variant.min_profit }}
        {{ marketplaceConstants ? marketplaceConstants.currencyInArabic : "" }}
      </div>
    </div>
  </div>
  <div class="actions">
    <div class="incrementor-row">
      <div class="quantity-incrementor">
        <app-quantity-incrementor
          [variantID]="variant.id.toString()"
          (quantityUpdated)="quantityChangeListener($event)"
        >
        </app-quantity-incrementor>
      </div>
    </div>
  </div>
  <div class="actions">
    <div class="product-buttons">
      <div class="w-full">
        <button
          mat-flat-button
          class="button-dimensions font-semibold primary-application-color add-product-class"
          (click)="addToCart(variant.id)"
          *ngIf="!cartVariantIds.includes(variant.id)"
        >
          اضافة الي السلة
        </button>
        <div
          *ngIf="cartVariantIds.includes(variant.id)"
          class="button-dimensions text-container"
        >
          <p class="already-added">تم الاضافة الي السلة</p>
        </div>
      </div>
    </div>
  </div>
  <div class="actions" *ngIf="width > 600">
    <div class="product-buttons">
      <div class="w-full">
        <button
          mat-flat-button
          class="primary-application-background-color secondary-application-color add-product-class button-dimensions font-semibold"
          (click)="checkout(variant.id)"
        >
          اشتري الان
        </button>
      </div>
    </div>
  </div>
</div>
