<div class="confirm-dialog-container">
<h1 class="dialog-title" mat-dialog-title>{{data.title}}</h1>
<div class="dialog-text" mat-dialog-content>
{{data.message}}
    
</div>
<div class="dialog-text mt-4" > 
    <span>اضغط </span>
    <span class="font-bold">الغاء </span>
    <span> لالغاء العملية أو اضغط </span>
    <span class="font-bold">تأكيد </span>
    <span> لتأكيد العملية </span>
</div>
</div>
<div mat-dialog-actions style="margin-top: 40px; margin-left: auto; width: fit-content;">
  <button class="confirm-btn" mat-button mat-dialog-close cdkFocusInitial (click)="onConfirmClick()">تأكيد</button>
  <button class="cancel-btn" mat-button mat-dialog-close (click)="onCancelClick()">الغاء</button>
</div>
