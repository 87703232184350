<div class="help-container">
  <button mat-icon-button class="close-button" (click)="closeDialog()">
    <mat-icon class="close-icon">close</mat-icon>
  </button>

  <mat-icon class="display-image" svgIcon="gift"></mat-icon>

  <div class="bottom-text medium-text">
    مبروك, دلوقتي قدرت تكسب معانا في مرحلة جديدة من بونص الكسيبة ربح
    {{ data.amount_per_order }} {{currencyInArabic}}
  </div>
</div>
