import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app-component/app.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

/* Angular Material*/
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatBadgeModule } from '@angular/material/badge';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* translate */
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { CommonComponentsModule } from './common-components-module/common-components-module.module';
import { SuccessMessageComponent } from './messages/success-message/success-message.component';
import { ErrorMessageComponent } from './messages/error-message/error-message.component';

/* Ngxs */
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';

// states
import { CartState } from './store/cart/cart.state';
import { LoginState } from './store/login/login.state';
import { MarketplaceState } from './store/marketplace/marketplace.state';
import { ProductState } from './store/products/products.state';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ProfileState } from './store/profile/profile.state';
import { BillingState } from './store/billing/billing.state';
import { OrderState } from './store/orders/orders.state';
import { UserInfoState} from './store/user-info/user-info.state'
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PwaService } from './core/services/pwa/pwa.service';
import { SuccessSnackbarComponent } from './common-components-module/success-snackbar/success-snackbar.component';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { UpdateModalComponent } from './components/update-modal/update-modal.component';
import { CustomPaginator } from './common-components-module/custom-paginator/custom-paginator';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { FileState } from './store/file-state/file.state';
import mixpanel from 'mixpanel-browser';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { SectionPageComponent } from './section-page/section-page/section-page.component';
import { ReturnOrderState } from './store/return-order/return-order.state';
import { OrderCapturePagesState } from './store/products/ordercapture.state';
// import { ReturnOrderState } from './store/return-order/return-order.state';
const providers: any = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  { provide: MatPaginatorIntl, useValue: CustomPaginator() },
  PwaService,
  { provide: 'googleTagManagerId', useValue: environment.googleTagID }
];

mixpanel.init(environment.mixpanelKey)
if (environment.name != 'Local') {
  providers.push(
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    }
  );
  Sentry.init({
    dsn: 'https://f69094d08f3e4224ad789739dae68f5e@o1112281.ingest.sentry.io/6143472',
    environment: environment.name,
  });
}
const angularMaterialImports = [
  MatDialogModule,
  MatDividerModule,
  MatTabsModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatInputModule,
  MatSidenavModule,
  MatToolbarModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatButtonModule,
  MatBadgeModule,
  MatListModule,
  MatSidenavModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSelectModule,
  FormsModule,
  ReactiveFormsModule,
  MatRadioModule,
];

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    SuccessSnackbarComponent,
    SuccessMessageComponent,
    ErrorMessageComponent,
    UpdateModalComponent,
    SectionPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ...angularMaterialImports,
    NgxsModule.forRoot(
      [
        CartState,
        LoginState,
        MarketplaceState,
        ProductState,
        ProfileState,
        BillingState,
        OrderState,
        UserInfoState,
        FileState,
        ReturnOrderState,
        OrderCapturePagesState
      ],
      { developmentMode: !environment.production }
    ),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.access', 'auth.refresh', 'auth.tokenBody'],
    }),
    TranslateModule.forRoot({
      isolate: false,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CommonComponentsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers,
  bootstrap: [AppComponent],
})
export class AppModule {}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(httpClient);
}
