export const environment = {
  name: 'Staging',
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyCKNgZkZQgIiteir-WONr2KCJ-ki1AFI-8',
    authDomain: 'seller-portal-e8cce.firebaseapp.com',
    projectId: 'seller-portal-e8cce',
    storageBucket: 'seller-portal-e8cce.appspot.com',
    messagingSenderId: '566278784248',
    appId: '1:566278784248:web:4922c499ea4b1850d7270d',
    measurementId: 'G-53X8ZECQHP',
  },
  orderCaptureFirebaseConfig: {
    apiKey: "AIzaSyCgZTg7kOJ7oe5ygK2BJF1IUYCTu4ue2Y0",
    authDomain: "order-capture-dev.firebaseapp.com",
    projectId: "order-capture-dev",
    storageBucket: "order-capture-dev.appspot.com",
    messagingSenderId: "299494464180",
    appId: "1:299494464180:web:8f1c39fc4f64823d0bd5e1",
    measurementId: "G-TSMPMC7PPG"
  },
  freshChatConfig: {
    host: 'https://wchat.freshchat.com',
    token: 'e99abffa-bb67-4979-b964-f66c3facca17',
  },
  baseUrl: 'https://seller-portal-core-stg-rkcv4cncjq-uc.a.run.app/',
  baseOrderCaptureUrl: 'https://order-capture-dev.web.app/',
  amplitudeApiKey: '38dee95d9a9eec4a1418ac26c5829c29',
  mixpanelKey: 'xxxxxxxxxxxxxxxxxxxxxxxxxxx' ,
  googleTagID:"GTM-KCT7RN4",
  customizedAlkasebaPhoneNumber : ['01100515599','01100515599'],
   // the value of limit used to display 500 city in the dropdown of city
  numberOfCities: 500,
   // the value of limit used to search 50 city using the search in the dropdown of city 
  limitSearchedCities:50,
};
