<form class="product-search-container" style="position: relative">
  <mat-form-field class="add-new-product-button m-auto" appearance="fill">
    <input
      matInput
      type="text"
      [formControl]="queryFormControl"
      autocomplete="off"
    />
    <mat-label style="margin: auto">
      <mat-icon>search</mat-icon>اضافة منتجات اخري</mat-label
    >
  </mat-form-field>
  <div
    class="search-results-container"
    [ngClass]="{
      'open-results-container': showDropdown
    }"
    *ngIf="showDropdown"
  >
    <div class="products-container">
      <div class="loading--overlay" *ngIf="loading">
        <div class="spinner">
          <mat-spinner color="primary" diameter="40"></mat-spinner>
        </div>
      </div>
      <div *ngIf="variants.length === 0 && !loading">
        <img
          class="m-auto"
          src="../../../assets/images/empty-product-search.png"
          alt="image"
        />
        <h4 class="no-products-text">لا يوجد هذا المنتج</h4>
      </div>
      <div
        class="product-row"
        *ngFor="let row of variants"
        (click)="onProductRowClick(row)"
      >
        <img
          alt="image"
          src="{{
            row.resources.length > 0
              ? row.resources[0].image
              : '../../../assets/images/no-image.png'
          }}"
        />
        <span class="product-name"
          >{{ row.product.name + " - " + row.name }}
        </span>

        <div>
          <span>أدني سعر بيع :</span>
          <span
            class="
              inline-block
              h-auto
              px-3
              w-auto
              mr-1
              blue
              font-semibold
              product-info-wrapper-style
            "
            ><div class="mt-2px">
              {{ row.minimum_selling_price }}
              {{ marketplaceConstants? marketplaceConstants.currencyInArabic : ''}}
            </div></span
          >
        </div>

        <div>
          <span>أدني ربح:</span>
          <span
            class="
              inline-block
              h-auto
              px-3
              w-auto
              mr-1
              green
              font-semibold
              product-info-wrapper-style
            "
            ><div class="mt-2px">
              {{ row.min_profit }}
              {{ marketplaceConstants? marketplaceConstants.currencyInArabic : ''}}
            </div></span
          >
        </div>
      </div>
    </div>
  </div>
</form>
