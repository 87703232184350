import { Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { BannerMedia } from 'src/app/shared/models/banner.model';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BannerComponent implements OnInit {
  @Input() sliderMedia: BannerMedia[];
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    infinite: false,
    rtl: true,
    arrows: false,
    dots: false,
    pauseOnHover: false,
    draggable: true
  };

  constructor() {}

  ngafterViewInit(): void {
  }

  ngOnInit(): void {
  }
}
