import { IAddressTemplateValidator } from "./interfaces/address-template-validator.interface";



export abstract class AddressTemplateValidatorAbstract implements IAddressTemplateValidator{
  addressCategory: string;
  addressLine: string;

  setAddressCategory(addressCategory: string): void {
    this.addressCategory = addressCategory;
  }
  setAddressLine(addressLine: string): void {
    this.addressLine = addressLine;
  }
  protected getAddressContentInArr():any{
    // the address should separated by - or , or \ or / or | or ،
    return (this.addressLine|| '').replace(/\s/g, "").split(/-|،|\/|,|\\|\|/).filter(function(n: any) { return n != ''}).filter(function(n: any) { return n != ' '});
  }
  abstract isValid(): boolean;
}
