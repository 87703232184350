import { Meta } from '../service-helpers/filter-helper';

export enum MediaTypeEnum {
  video = 'video',
  image = 'image',
}

export interface BannerMedia {
  type: MediaTypeEnum;
  image_file?: string;
  video_file?: string;
  url?: string;
}

export interface GetBannerResponse {
  meta: Meta;
  banners: BannerMedia[];
}
